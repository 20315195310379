import React, { useEffect, useState, useRef, useCallback } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Cookies from "js-cookie";
import { axiosService } from "axios/axiosInstance";
import { Tooltip } from '@material-ui/core';
import { useDispatch, useMappedState } from "redux-react-hook";
import { ACTIONTYPE_MESSAGE_CONFIG, ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_HOSPITALSTAFFLIST } from "../../redux/actionTypes"
import { useIntl, FormattedMessage } from 'react-intl';
import { MenuProps } from "views/PatientDetail/PatientDetail.jsx";
import sha256 from "js-sha256";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import moment from 'moment-timezone';
import useAxiosHandler from "../../axios/axiosHandler";
import { isDate } from "date-fns";
import { isPowerUser } from "components/Azure/Utilities";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFns from '@date-io/date-fns';
import AirDatepickerReact from "components/AirDatePickerReact/AirDatePickerReact";
import localeEn from 'air-datepicker/locale/en';
import localeZh from 'air-datepicker/locale/zh';
import { parseGenderToString } from "views/PatientDetail/PatientDetail.jsx";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

function UserProfile(props) {
  const { classes, newUser, onClose } = props;
  const menuRef = useRef(null);
  const profileBirthDpRef = useRef(null);
  const [menuRefO, setMenuRefO] = useState(menuRef);
  const role = useMappedState(useCallback(state => state.setting.role));
  const locationList = useMappedState(useCallback(state => state.initialData.locationList));
  const lang = useMappedState(useCallback(state => state.setting.language));
  const intl = useIntl();
  const dispatch = useDispatch();
  const [mailLength, setMailLength] = useState(0);
  const [successHandler, errorHandler] = useAxiosHandler();
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    userId: '',
    oldPassword: null,
    newPassword: null,
    newPassword_confirmation: null,
    age: 0,
    gender: 0,
    birth: null,
    email: [],
    identifier: '',
    location: {name: '', uuid: ''},
    status: 0,
    role: 7,
    webview: false,
    phone: '',
    address: ''
  });
  const [modifiedProfile, setModifiedProfile] = useState({});

  const fillRange = (start, end) => {
    return Array(end - start + 1).fill().map((item, index) => start + index);
  };

  const genders = [
    intl.formatMessage({ id: 'patientDetail.info.female' }),
    intl.formatMessage({ id: 'patientDetail.info.male' }),
    intl.formatMessage({ id: 'patientList.unknown' }),
    intl.formatMessage({ id: 'patientList.others' }),
  ];

  const parseGender = (gender) => {
    return gender === intl.formatMessage({ id: 'patientDetail.info.male' }) ? 0 :
      (gender === intl.formatMessage({ id: 'patientDetail.info.female' }) ? 1 : 2);
  };

  const statusString = [
    intl.formatMessage({ id: 'userProfile.status.disabled'}),
    intl.formatMessage({ id: 'userProfile.status.enabled' }),
    intl.formatMessage({ id: 'userProfile.status.deleted' }),
  ];

  const parseStatus = (status) => {
    if(status === intl.formatMessage({ id: 'userProfile.status.disabled'}))
      return 0;
    if(status === intl.formatMessage({ id: 'userProfile.status.enabled'}))
      return 1;
    return 2;
  }

  const parseStatusToString = (status) => {
    if(status === 0)
      return intl.formatMessage({id: 'userProfile.status.disabled'});
    if(status === 1)
      return intl.formatMessage({id: 'userProfile.status.enabled'});
    return intl.formatMessage({ id: 'userProfile.status.deleted'});
  }

  const roleString = [
    intl.formatMessage({ id: 'userProfile.role.analyst'}),
    intl.formatMessage({ id: 'userProfile.role.hospitalStaff'}),
    intl.formatMessage({ id: 'userProfile.role.sales'}),
  ];

  const parseRole = (role) => {
    if (role === intl.formatMessage({ id: 'userProfile.role.analyst' }))
      return 4;
    if (role === intl.formatMessage({ id: 'userProfile.role.hospitalStaff' }))
      return 7;
    return 9;
  };

  const parseRoleToString = (role) => {
    if(role === 4)
      return intl.formatMessage({ id: 'userProfile.role.analyst' });
    if(role === 7)
      return intl.formatMessage({ id: 'userProfile.role.hospitalStaff' });
    return intl.formatMessage({ id: 'userProfile.role.sales' });
  };

  const handleChange = (key, value) => {
    setProfile(prevState => {
      console.log(JSON.stringify(prevState[key]));
      console.log(JSON.stringify(value));
      return { ...prevState, [key]: value };
    });
    setModifiedProfile(prevState => {
      return { ...prevState, [key]: value };
    });
  };

  const passwordHash = (value) => {
    if(value && value !== ''){
      const loginUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/webanalyzer/v1/login";
      return sha256(loginUrl + value);
    } else {
      return null;
    }
  }

  const verifyData = () => {
    const breakLoop = false;
    do{
      if(profile.userId === '') {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.userId' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
        break;
      }
      if(profile.firstName === '') {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.firstName' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
        break;
      }
      if(profile.lastName === '') {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.lastName' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
        break;
      }
      if(profile.email === '') {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.email' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
        break;
      }
      if(newUser){
        if (profile.newPassword === '' || profile.newPassword_confirmation === ''){
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.newPassword' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
          break;
        }
      }

      return true;
    }while(breakLoop);
    return false;
  }

  const checkPassword = (password, newPassword) => {
    if(!newPassword || newPassword === ''){
      return true;
    }
    if (newPassword.length < 6) {
      dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.passwordDoesNotMatchTheFormat' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'}}});
      return false;
    }
    if (password === newPassword) {
      dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.theNewPasswordCannotBeTheSameAsTheOldPassword' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'}}});
      return false;
    }
    return true;
  };

  const onHandleClick = () => {
    if(!verifyData()){
      return;
    }
    if(newUser){
      if(!checkPassword(profile.oldPassword, profile.newPassword)){
        return;
      }
      axiosService.post('/user/profile',
        {
          ...profile,
          oldPassword: passwordHash(profile.oldPassword),
          newPassword: passwordHash(profile.newPassword),
          newPassword_confirmation: passwordHash(profile.newPassword_confirmation),
        },
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }).then(response => {
          console.log(response.data);
          function onSuccess(response) {
            if (response.status === 200 && (response.data.error === 0 || response.data.error === 3)) {
              onClose();
            }
          }
          successHandler(response, onSuccess);
        }).catch(error => {
          errorHandler(error);
        });
    } else {
      if(!checkPassword(modifiedProfile.oldPassword, modifiedProfile.newPassword)){
        return;
      }
      let data = new FormData();
      data.append('profile', JSON.stringify(
        { ...modifiedProfile,
          oldPassword: passwordHash(modifiedProfile.oldPassword),
          newPassword: passwordHash(modifiedProfile.newPassword),
          newPassword_confirmation: passwordHash(modifiedProfile.newPassword_confirmation),}));
      data.append('_method', 'PUT');
      axiosService.post('/user/profile/' + profile.userId,
        data,
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }).then(response => {
          console.log(response.data);
          function onSuccess(response) {
            if (response.status === 200 && (response.data.error === 0 || response.data.error === 3)) {
              onClose();
            }
          }
          successHandler(response, onSuccess);
        }).catch(error => {
          errorHandler(error);
        });
    }
  };

  function dispatchInitData(message) {
    if (message.initialData !== undefined) {
      if (message.initialData.analystList !== undefined) {
        dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: message.initialData.analystList } });
      }
      if (message.initialData.physicianList !== undefined) {
        dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: message.initialData.physicianList } });
      }
      if (message.initialData.locations !== undefined) {
        dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: message.initialData.locations } });
        handleChange('location', message.initialData.locations[0]);
      }
      if (message.initialData.hospitalStaff !== undefined) {
        dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: message.initialData.hospitalStaff } });
      }
    }
  }

  useEffect(()=>{
    if(!newUser) {
      axiosService.get(
        '/user/profile?username='+ Cookies.get('username'),
        {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('apiKey'),
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }).then(response => {
          console.log(response.data);
          function onSuccess(response) {
            if(response.status === 200) {
              if (response.data.error === 0 && response.data.message !== undefined) {
                let userProfile = response.data.message;
                userProfile.phone = userProfile.phone ? userProfile.phone : "";
                userProfile.address = userProfile.address ? userProfile.address : "";
                setProfile(userProfile);
                setMailLength(response.data.message.email.join(';').length);
              } else if (response.data.error === 3) {
                onClose();
              }
            }
          }
          successHandler(response, onSuccess);
        }).catch(error => {
          errorHandler(error);
        });
    } else {
      // if (locationList.length === 0) {
        axiosService.get(
          '/initdata',
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('apiKey'),
              'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
            }
          }).then(response => {
            console.log(response.data);
            function onSuccess(response) {
              if (response.status === 200 && response.data.error === 0
                && response.data.message !== undefined) {
                dispatchInitData(response.data.message);
              }
            }
            successHandler(response, onSuccess);
          }).catch(error => {
            errorHandler(error);
          });
      // } else {
      //   handleChange('location', locationList[0]);
      // }
    }
  }, []);
  return (
    <div onScroll={(e) => {
      profileBirthDpRef.current.onScroll(e);
    }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                <FormattedMessage id={newUser ? "userProfile.addProfile" : "userProfile.editProfile"}></FormattedMessage>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <Tooltip title={profile.userId ? profile.userId.length + '/40' : '0/40'} enterDelay={500}>
                    <TextField
                      id="profile-userId"
                      disabled={!isPowerUser(role)}
                      label={intl.formatMessage({ id: 'userProfile.userId' })}
                      type="text"
                      value={profile.userId}
                      onInput={(e) => {
                        if (e.target.value.length > 40) {
                          e.target.value = e.target.value.slice(0, 40);
                        }
                        handleChange('userId', e.target.value);
                      }}
                      margin="normal"
                      required
                      InputProps={isPowerUser(role) ? {
                        endAdornment: (
                          <InputAdornment>
                            <IconButton onClick={(e) => {
                              setModifiedProfile({});
                              axiosService.get(
                                '/user/profile?username=' + profile.userId,
                                {
                                  headers: {
                                    'Authorization': 'Bearer ' + Cookies.get('apiKey'),
                                    'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
                                  }
                                }).then(response => {
                                  console.log(response.data);
                                  function onSuccess(response) {
                                    if (response.status === 200) {
                                      if (response.data.error === 0 && response.data.message !== undefined) {
                                        let userProfile = response.data.message;
                                        userProfile.phone = userProfile.phone ? userProfile.phone : "";
                                        userProfile.address = userProfile.address ? userProfile.address : "";
                                        setProfile(userProfile);
                                        setMailLength(response.data.message.email.join(';').length);
                                      } else if (response.data.error === 3) {
                                        onClose();
                                      }
                                    }
                                  }
                                  successHandler(response, onSuccess);
                                }).catch(error => {
                                  errorHandler(error);
                                });
                            }}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      } : {}}
                    />
                  </Tooltip>
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControl style={{ marginTop: '16px' }}>
                    <InputLabel htmlFor="select-multiple-checkbox" shrink={true}>
                      <FormattedMessage id='userProfile.age' />
                    </InputLabel>
                    <Select
                      value={profile.age}
                      onChange={event => handleChange('age', event.target.value)}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={selected => selected}
                      MenuProps={MenuProps}
                    >
                      {fillRange(1, 120).map(age => (
                        <MenuItem key={age} value={age}>
                          <ListItemText primary={age} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControl style={{ marginTop: '16px' }}>
                    <InputLabel htmlFor="select-multiple-checkbox" shrink={true}>
                      <FormattedMessage id='userProfile.gender' />
                    </InputLabel>
                    <Select
                      value={parseGenderToString(intl, profile.gender)}
                      onChange={event => handleChange('gender', parseGender(event.target.value))}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={selected => selected}
                      MenuProps={MenuProps}
                    >
                      {genders.map(gender => (
                        <MenuItem key={parseGender(gender)} value={gender}>
                          <ListItemText primary={gender} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Tooltip title={profile.firstName ? profile.firstName.length + '/40' : '0/40'} enterDelay={500}>
                    <TextField
                      id="profile-firstName"
                      label={intl.formatMessage({ id: 'userProfile.firstName' })}
                      type="text"
                      value={profile.firstName}
                      onInput={(e) => {
                        if (e.target.value.length > 40) {
                          e.target.value = e.target.value.slice(0, 40);
                        }
                        handleChange('firstName', e.target.value);
                      }}
                      margin="normal"
                      required
                    />
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Tooltip title={profile.lastName ? profile.lastName.length + '/40' : '0/40'} enterDelay={500}>
                    <TextField
                      id="profile-lastName"
                      label={intl.formatMessage({ id: 'userProfile.lastName' })}
                      type="text"
                      value={profile.lastName}
                      onInput={(e) => {
                        if (e.target.value.length > 40) {
                          e.target.value = e.target.value.slice(0, 40);
                        }
                        handleChange('lastName', e.target.value);
                      }}
                      margin="normal"
                      required
                    />
                  </Tooltip>
                </GridItem>
                <GridItem id='profile-birth-form-grid' xs={12} sm={12} md={6}>
                  <AirDatepickerReact ref={profileBirthDpRef}
                    value={!profile.birth || isNaN(Date.parse(profile.birth)) ? null : moment(profile.birth).format('DD/MM/YYYY')}
                    onChange={updatedDate => {
                      console.log('birth', updatedDate);
                      if (updatedDate && moment(updatedDate, "DD/MM/YYYY").isValid()) {
                        handleChange('birth', moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD'));
                      } else {
                        handleChange('birth', null);
                      }
                    }}
                    datepickerConfig={{
                      autoClose: true,
                      position: "bottom left",
                      dateFormat: "dd/MM/yyyy",
                      buttons: ["clear"],
                      container: '#profile-birth-form-grid',
                      locale: lang === 'en-US' ? localeEn : localeZh
                    }}
                    textFieldOptions={{
                      label: intl.formatMessage({ id: 'userProfile.birth' }),
                      placeholder: "DD/MM/YYYY",
                      InputLabelProps: { shrink: true },
                      inputProps: { readOnly: true }
                    }}
                    format={"DD/MM/YYYY"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Tooltip title={profile.phone ? profile.phone.length + '/20' : '0/20'} enterDelay={500}>
                    <TextField
                      id="userProfile-phone"
                      label={intl.formatMessage({ id: 'userProfile.phone' })}
                      type="tel"
                      value={profile.phone ? profile.phone : ""}
                      onInput={(e) => {
                        if (e.target.value.length > 20) {
                          e.target.value = e.target.value.slice(0, 20);
                        }
                        handleChange('phone', e.target.value);
                      }}
                      margin="normal"
                    />
                  </Tooltip>
                </GridItem>
                {newUser ?
                  <GridContainer xs={12} sm={12} md={12}>
                    <GridItem xs={12} sm={12} md={3} style={{textAlign: 'center'}}>
                      <FormControl style={{ marginTop: '16px', marginBottom: '8px' }} required>
                        <InputLabel htmlFor="select-multiple-checkbox-status">
                          <FormattedMessage id='userProfile.status' />
                        </InputLabel>
                        <Select
                          style={{ minWidth: 120, maxWidth: 250 }}
                          value={parseStatusToString(profile.status)}
                          onChange={event => handleChange('status', parseStatus(event.target.value))}
                          input={<Input id="select-multiple-checkbox-status" />}
                          ref={menuRef}
                          onOpen={(e) => {
                            setMenuRefO(e.currentTarget);
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: () => {
                              return menuRef.current;
                            },
                            PaperProps: {
                              style: {
                                //maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                                //width: 250,
                              },
                            },
                          }}
                        >
                          {statusString.map(status => (
                            <MenuItem key={parseStatus(status)} value={status}>
                              <ListItemText primary={status} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                      <FormControl style={{ marginTop: '16px', marginBottom: '8px' }} required>
                        <InputLabel htmlFor="select-multiple-checkbox-role">
                          <FormattedMessage id='userProfile.role' />
                        </InputLabel>
                        <Select
                          style={{ minWidth: 120, maxWidth: 250 }}
                          value={parseRoleToString(profile.role)}
                          onChange={e => handleChange('role', parseRole(e.target.value))}
                          input={<Input id="select-multiple-checkbox-role" />}
                          ref={menuRefO}
                          onOpen={(e) => {
                            setMenuRefO(e.currentTarget);
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: () => {
                              return menuRefO.current;
                            },
                            PaperProps: {
                              style: {
                                //maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                                //width: 250,
                              },
                            },
                          }}
                        >
                          {roleString.map(role => (
                            <MenuItem key={parseRole(role)} value={role}>
                              <ListItemText primary={role} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                      <FormControl style={{ marginTop: '16px', marginBottom: '8px' }} required>
                        <InputLabel htmlFor="select-multiple-checkbox-location">
                          <FormattedMessage id='userProfile.location' />
                        </InputLabel>
                        <Select
                          style={{ minWidth: 120, maxWidth: 250 }}
                          value={profile.location.Name}
                          onChange={e => handleChange('location', e.target.value)}
                          input={<Input id="select-multiple-checkbox-location" />}
                          ref={menuRefO}
                          onOpen={(e) => {
                            setMenuRefO(e.currentTarget);
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: () => {
                              return menuRefO.current;
                            },
                            PaperProps: {
                              style: {
                                //maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                                //width: 250,
                              },
                            },
                          }}
                        >
                          {locationList.sort((a,b)=> {
                            return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase(), "zh-hant")
                          }).map(location => (
                            <MenuItem key={location.Uuid} value={location}>
                              <ListItemText primary={location.Name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                      <FormControlLabel
                        style={{ marginTop: '16px', marginBottom: '8px' }} required
                        label={<FormattedMessage id='userProfile.webview' />}
                        control={<Checkbox checked={profile.webview} onChange={(e) => {
                          handleChange('webview', e.target.checked);
                      }} />}
                      />
                    </GridItem>
                  </GridContainer> : null}
                <GridItem xs={12} sm={12} md={12}>
                  <Tooltip title={mailLength + '/300' } enterDelay={500}>
                    <TextField
                      id="userProfile-email"
                      label={intl.formatMessage({ id: 'userProfile.email' })}
                      value={profile.email.join(';')}
                      onInput={(e) => {
                        // if (e.target.value.split(';').length > 10) {
                        //   e.target.value = e.target.value.split(';')
                        //     .slice(0, 10).join(';');
                        // }
                        if(e.target.value.length > 300) {
                          e.target.value = e.target.value.slice(0,300);
                        }
                        setMailLength(e.target.value.length);
                        handleChange('email', e.target.value.split(';'));
                      }}
                      margin="normal"
                      fullWidth
                      required
                    />
                  </Tooltip>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Tooltip title={profile.address ? profile.address.length + '/200' : '0/200'} enterDelay={500}>
                    <TextField
                      id="userProfile-address"
                      label={intl.formatMessage({ id: 'userProfile.address' })}
                      value={profile.address ? profile.address : ""}
                      onInput={(e) => {
                        if (e.target.value.length > 200) {
                          e.target.value = e.target.value.slice(0, 200);
                        }
                        handleChange('address', e.target.value);
                      }}
                      fullWidth
                      margin="normal"
                    />
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{justifyContent: "center"}}>
              <Button color="primary" onClick={onHandleClick}>
                <FormattedMessage id={newUser ? "userProfile.add" : "userProfile.apply"}></FormattedMessage>
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardBody profile>
              <GridContainer>
                {newUser ? null : <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="userProfile-oldPassword"
                    label={intl.formatMessage({ id: 'userProfile.oldPassword' })}
                    value={profile.oldPassword}
                    onInput={(e) => handleChange('oldPassword', e.target.value)}
                    type="password"
                    margin="normal"
                  />
                </GridItem>}
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="userProfile-newPassword"
                    label={intl.formatMessage({ id: 'userProfile.newPassword' })}
                    value={profile.newPassword}
                    onInput={(e) => handleChange('newPassword', e.target.value)}
                    type="password"
                    margin="normal"
                    required={newUser}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="userProfile-newPassword_confirmation"
                    label={intl.formatMessage({ id: 'userProfile.confirmNewPassword' })}
                    value={profile.newPassword_confirmation}
                    onInput={(e) => handleChange('newPassword_confirmation', e.target.value)}
                    type="password"
                    margin="normal"
                    required={newUser}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
           </Card>
         </GridItem>
      </GridContainer>
    </div>
  );
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  newUser: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(UserProfile);
