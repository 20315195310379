import React, { useState, useEffect, useRef, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Refresh from '@material-ui/icons/Refresh';
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";
import { useIntl, FormattedMessage } from "react-intl";
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '../CustomButtons/Button'
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Timer from '@material-ui/icons/Timer';
import NewReleases from "@material-ui/icons/NewReleases";
import Message from "@material-ui/icons/Message";

import { useMappedState } from "redux-react-hook";
import { parseStatus, parseStatusForHospitalStaff } from 'views/PatientDetail/ReportInfo';
import { Box, Typography } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-react/components/buttonStyle';
import { parseGenderToString } from 'views/PatientDetail/PatientDetail';


const StyledTableRow = styled(TableRow)`
&:after {
    content: '';
    position: relative;
    width: 0px;
    height: 0px;
    display: block;
    border-top: 15px solid #F00;
    border-left: 15px solid transparent;
    top: 0;
    right: 0;
  }
`;

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderby, numSelected, rowCount, onRequestSort, headRows, tableHeaderColor, hideCheckbox } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const classes = useStyles();

  return (
    <TableHead className={tableStyle[tableHeaderColor + "TableHeader"]}>
      <TableRow>
        {!hideCheckbox && <TableCell padding={'none'} className={classes.tableCell} align={"left"}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all desserts' }}
          />
        </TableCell>}
        {headRows.map(row => row === null ? null : (
          <TableCell
            key={row.id}
            align={"left"}
            padding={row.padding}
            className={classes.tableCell}
            style={{ minWidth: row.minWidth }}
            sortDirection={orderby === row.id ? order : false}
          >
            <div>
              {row.sortable ?
                <TableSortLabel
                  active={orderby === row.id}
                  hideSortIcon={true}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                >
                  {row.fontStyle !== undefined ? <div style={{ fontStyle: row.fontStyle }} >
                    {row.label}
                  </div> : row.label}
                </TableSortLabel> : null}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderby: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  hideCheckbox: PropTypes.bool.isRequired,
  headRows: PropTypes.array,
  tableHeaderColor: PropTypes.string
};

export const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    display: 'inline-flex',
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  filter: {
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.short
    })
  },
  filterOpen: {
    transform: "rotate(0deg)"
  },
  filterClose: {
    transform: "rotate(90deg)"
  },
  duplicateTypography: {
    margin: theme.spacing(1)
  }
}));

const EnhancedTableFilter = props => {
  const { setFilterList, tableHead, locationList, onRefreshRequest } = props;
  const intl = useIntl();
  const menuRef = useRef(null);
  const [menuRefO, setMenuRefO] = useState(menuRef);
  const [genderFilter, setGenderFilter] = useState([]);
  const [ageStart, setAgeStart] = useState(0);
  const [ageEnd, setAgeEnd] = useState(120);
  const [statusFilter, setStatusFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [iconsFilter, setIconsFilter] = useState({
    newMessage: false,
    message: false,
    emergency: false,
    urgent: false
  });
  const [ageString, setAgeString] = useState('' + ageStart + '-' + ageEnd);
  const ageRef = useRef();
  const [ageSelection, setAgeSelection] = useState();

  useEffect(useCallback(() => {
    setFilterList([{
      field: 'age', // required
      limit1: ageStart, // optional
      limit2: ageEnd, // optional
      not: false, // optional, default false
    },
    {
      field: 'sex', // required
      limit1: genderFilter, // optional
      limit2: null, // optional
      not: false, // optional, default false
    },
    {
      field: 'status', // required
      limit1: statusFilter, // optional
      limit2: null, // optional
      not: false, // optional, default false
    },
    {
      field: 'location', // required
      limit1: locationFilter, // optional
      limit2: null, // optional
      not: false, // optional, default false
    }, {
      field: 'icons', // required
      limit1: iconsFilter,
      limit2: null,
      not: false
    }])
  }), [ageStart, ageEnd, genderFilter, statusFilter, locationFilter, iconsFilter])

  useEffect(useCallback(() => {
    if (!ageSelection) return;
    const { start, end } = ageSelection;
    ageRef.current.focus();
    ageRef.current.setSelectionRange(start, end);
  }), [ageSelection]);

  const statuses = [
    { key: 0, value: intl.formatMessage({ id: 'patientDetail.info.status.created' }) },
    { key: 1, value: intl.formatMessage({ id: 'patientDetail.info.status.signalUploading' }) },
    { key: 2, value: intl.formatMessage({ id: 'patientDetail.info.status.signalFailed' }) },
    { key: 3, value: intl.formatMessage({ id: 'patientDetail.info.status.signalSuccessed' }) },
    { key: 4, value: intl.formatMessage({ id: 'patientDetail.info.status.inspecting' }) },
    { key: 5, value: intl.formatMessage({ id: 'patientDetail.info.status.inspected' }) },
    { key: 6, value: intl.formatMessage({ id: 'patientDetail.info.status.analyzing' }) },
    { key: 7, value: intl.formatMessage({ id: 'patientDetail.info.status.analyzed' }) },
    { key: 8, value: intl.formatMessage({ id: 'patientDetail.info.status.reviewing' }) },
    { key: 9, value: intl.formatMessage({ id: 'patientDetail.info.status.reviewed' }) },
    { key: 10, value: intl.formatMessage({ id: 'patientDetail.info.status.confirming' }) },
    { key: 11, value: intl.formatMessage({ id: 'patientDetail.info.status.confirmed' }) },
    { key: 12, value: intl.formatMessage({ id: 'patientDetail.info.status.reportUploading' }) },
    { key: 13, value: intl.formatMessage({ id: 'patientDetail.info.status.reportUploaded' }) },
    { key: 14, value: intl.formatMessage({ id: 'patientDetail.info.status.reportReviewing' }) },
    { key: 15, value: intl.formatMessage({ id: 'patientDetail.info.status.reportReviewed' }) },
    { key: 16, value: intl.formatMessage({ id: 'patientDetail.info.status.reportDownloaded' }) },
    { key: 17, value: intl.formatMessage({ id: 'patientDetail.info.status.archived' }) },
  ];

  const genders = [
    intl.formatMessage({ id: 'patientDetail.info.female' }),
    intl.formatMessage({ id: 'patientDetail.info.male' }),
    intl.formatMessage({ id: 'patientList.unknown' }),
    intl.formatMessage({ id: 'patientList.others' }),
  ];

  const parseGender = (gender) => {
    return gender === intl.formatMessage({ id: 'patientDetail.info.male' }) ? 0 :
      (gender === intl.formatMessage({ id: 'patientDetail.info.female' }) ? 1 : 2);
  };

  return (<div><Grid container>
    <Grid item xs={12} style={{ textAlign: "center", overflow: "auto" }}>
      <Table>
        <TableBody>
          <TableRow>
            {tableHead.filter(head => head === null ? false : head.canFilter)
              .map(headData => {
                if (headData.id === 'sex') {
                  return (<TableCell style={{ textAlign: 'left', minWidth: "120px" }}>
                    <FormControl>
                      <InputLabel htmlFor="select-multiple-checkbox-sex">
                        <FormattedMessage id='patientList.sex' />
                      </InputLabel>
                      <Select
                        style={{ minWidth: 120, maxWidth: 250 }}
                        multiple
                        value={genderFilter.map(gender => parseGenderToString(intl, gender))}
                        onChange={event =>
                          setGenderFilter(event.target.value.map(v => genders.find(function (p) {
                            return p === v;
                          })).map(genderString => parseGender(genderString)))
                        }
                        input={<Input id="select-multiple-checkbox-sex" />}
                        renderValue={selected => selected.join(', ')}
                        ref={menuRefO}
                        onOpen={(e) => {
                          setMenuRefO(e.currentTarget);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: () => {
                            return menuRefO.current;
                          },
                          PaperProps: {
                            style: {
                            },
                          },
                        }}
                      >
                        {genders.map(gender => (
                          <MenuItem key={parseGender(gender)} value={gender}>
                            <Checkbox checked={genderFilter.indexOf(parseGender(gender)) > -1} />
                            <ListItemText primary={gender} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl></TableCell>);
                } else if (headData.id === 'age') {
                  return (<TableCell style={{ textAlign: 'left', minWidth: "120px" }}>
                    <FormControl>
                      <InputLabel htmlFor="formatted-text-mask-input">
                        <FormattedMessage id="patientList.age" />
                      </InputLabel>
                      <Input
                        inputRef={ageRef}
                        type="text"
                        inputProps={{
                          style: { "textAlign": "center" }
                        }}
                        value={ageString}
                        onKeyDown={e => {
                          if (e.keyCode === 8 || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)
                            || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 46 || e.keyCode === 13)
                            console.log("keyCode: " + e.keyCode);
                          else
                            e.preventDefault();
                        }}
                        onChange={(e) => {
                          if (!e.target.value.includes("-")) {
                            setAgeSelection({ start: e.target.value.length, end: e.target.value.length })
                            return;
                          }
                          setAgeSelection({ start: ageRef.current.selectionStart, end: ageRef.current.selectionEnd })
                          let age = e.target.value.split('-');
                          if (parseInt(age[0]) < 0 || parseInt(age[0]) > 120 || parseInt(age[1]) < 0 || parseInt(age[1]) > 120)
                            return;
                          if ((age[0].length > 1 && age[0][0] === '0') || (age[1].length > 1 && age[1][0] === '0'))
                            return;
                          setAgeString(age[0].trim() + "-" + age[1].trim());
                          setAgeStart(parseInt(age[0]));
                          setAgeEnd(parseInt(age[1]));
                        }
                        }
                      />
                    </FormControl>
                  </TableCell>);
                } else if (headData.id === 'status') {
                  return (<TableCell style={{ textAlign: 'left', minWidth: "120px" }}>
                    <FormControl>
                      <InputLabel htmlFor="select-multiple-checkbox-status">
                        <FormattedMessage id='patientList.status' />
                      </InputLabel>
                      <Select
                        style={{ minWidth: 120, maxWidth: 250 }}
                        multiple
                        value={statusFilter.map(status => statuses.find((p) => p.key === status).value)}
                        onChange={event =>
                          setStatusFilter(event.target.value.map(v => statuses.find(function (p) {
                            return p.value === v;
                          })).map(statusObj => statusObj.key))
                        }
                        input={<Input id="select-multiple-checkbox-status" />}
                        renderValue={selected => selected.join(', ')}
                        ref={menuRefO}
                        onOpen={(e) => {
                          setMenuRefO(e.currentTarget);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: () => {
                            return menuRefO.current;
                          },
                          PaperProps: {
                            style: {
                            },
                          },
                        }}
                      >
                        {statuses.map(statusObj => (
                          <MenuItem key={statusObj.key} value={statusObj.value}>
                            <Checkbox checked={statusFilter.indexOf(statusObj.key) > -1} />
                            <ListItemText primary={statusObj.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl></TableCell>);
                } else if (headData.id === 'location') {
                  return (<TableCell style={{ textAlign: 'left', minWidth: "120px" }}>
                    <FormControl>
                      <InputLabel htmlFor="select-multiple-checkbox-location">
                        <FormattedMessage id='patientList.location' />
                      </InputLabel>
                      <Select
                        style={{ minWidth: 120, maxWidth: 250 }}
                        multiple
                        value={locationFilter.map(location => locationList.find((p) => p.Uuid === location).Name)}
                        onChange={event =>
                          setLocationFilter(event.target.value.map(v => locationList.find((p) => p.Name === v))
                            .map(locationObj => locationObj.Uuid))
                        }
                        input={<Input id="select-multiple-checkbox-location" />}
                        renderValue={selected => selected.join(', ')}
                        ref={menuRefO}
                        onOpen={(e) => {
                          setMenuRefO(e.currentTarget);
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: () => {
                            return menuRefO.current;
                          },
                          PaperProps: {
                            style: {
                            },
                          },
                        }}
                      >
                        {locationList.sort((a, b) => {
                          return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase(), "zh-hant")
                        }).map(locationObj => (
                          <MenuItem key={locationObj.Uuid} value={locationObj.Name}>
                            <Checkbox checked={locationFilter.indexOf(locationObj.Uuid) > -1} />
                            <ListItemText primary={locationObj.Name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl></TableCell>);
                } else if (headData.id === 'icons') {
                  return (<><TableCell style={{ minWidth: "160px" }} align='justify'>

                    <FormControlLabel
                      control={<Checkbox checked={iconsFilter.newMessage} onClick={() => {
                        setIconsFilter({ ...iconsFilter, newMessage: !iconsFilter.newMessage });
                      }} />}
                      label={<Box style={{ display: 'flex' }}><Badge color="error" variant='dot'>
                        <Message style={{ verticalAlign: "middle", color: "green" }} />
                      </Badge>
                        <Typography>
                          <FormattedMessage id='patientList.newMessage' />
                        </Typography>
                      </Box>}
                      style={{ color: "#000000", paddingRight: "20px" }}
                    />
                  </TableCell>
                    <TableCell style={{ minWidth: "160px" }} align='justify'>
                      <FormControlLabel
                        control={<Checkbox checked={iconsFilter.message} onClick={() => {
                          setIconsFilter({ ...iconsFilter, message: !iconsFilter.message });
                        }} />}
                        label={<Box style={{ display: 'flex' }}><Message style={{ verticalAlign: "middle", color: "green" }} />

                          <Typography>
                            <FormattedMessage id='patientList.message' />
                          </Typography></Box>}
                        style={{ color: "#000000", paddingRight: "20px" }}
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: "160px" }} align='justify'>
                      <FormControlLabel
                        control={<Checkbox checked={iconsFilter.emergency} onClick={() => {
                          setIconsFilter({ ...iconsFilter, emergency: !iconsFilter.emergency });
                        }} />}
                        label={<Box style={{ display: 'flex' }}><NewReleases style={{ verticalAlign: "middle", color: "red" }} />
                          <Typography>
                            <FormattedMessage id='patientList.emergency' />
                          </Typography></Box>}
                        style={{ color: "#000000", paddingRight: "20px" }}
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: "160px" }} align='justify'>
                      <FormControlLabel
                        control={<Checkbox checked={iconsFilter.urgent} onClick={() => {
                          setIconsFilter({ ...iconsFilter, urgent: !iconsFilter.urgent });
                        }} />}
                        label={<Box style={{ display: 'flex' }}><Timer style={{ verticalAlign: "middle", color: "orange" }} />
                          <Typography>
                            <FormattedMessage id='patientList.urgent' />
                          </Typography></Box>}
                        style={{ color: "#000000" }}
                      />
                    </TableCell></>)
                }
                return (null);
              })}
            <TableCell style={{ textAlign: 'center' }} align='justify'>
              <Tooltip title={intl.formatMessage({ id: 'patientList.filter' })}>
                <Button color="primary" size="sm" aria-label="apply-Filter" onClick={e => onRefreshRequest(e)}>
                  <FormattedMessage id={'patientList.applyFilter'} />
                </Button>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  </Grid>
  </div>);
};

EnhancedTableFilter.propTypes = {
  setFilterList: PropTypes.func,
  tableHead: PropTypes.array,
  locationList: PropTypes.array,
  onRefreshRequest: PropTypes.func
};

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const intl = useIntl();
  const { resetNewData, numSelected, onAddNewRecord, onRefreshRequest, searchWord, setSearchWord, filterList, setFilterList,
    tableHead, locationList, hideCheckbox, onDelete, duplicateCases } = props;
  const [searchValue, setSearchValue] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  const refreshBtnRef = useRef(null);

  const createAddNewRecordHandler = event => {
    if (typeof onAddNewRecord === "function") {
      onAddNewRecord(event);
    }
  };

  const createRefreshHandler = () => {
    if (typeof onRefreshRequest === "function") {
      resetNewData();
      if (searchWord !== searchValue) {
        setSearchWord(searchValue);
      }
      else {
        onRefreshRequest();
      }
    }
  }

  const openFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          // [classes.highlight]: numSelected > 0,
        })}
      // style={{textAlign: "right", display: "block"}}
      >
        {/*<div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              Nutrition
            </Typography>
          )}
          </div>*/}
        {duplicateCases.length > 0 ? <Grid xs={12} container direction='row' style={{ alignItems: "center" }}>
          <Grid item>
            <Typography style={{ color: "red" }}><FormattedMessage id={'patientList.duplicatedCases'}></FormattedMessage></Typography>
          </Grid>
          {duplicateCases.map((value) =>
            <Grid item>
              <Typography onClick={(e) => {
                console.log(`on click ${value}`);
                setSearchValue(value);
                setSearchWord(value);
                // refreshBtnRef.current?.click();
              }} className={classes.duplicateTypography} style={{ cursor: "pointer", color: "red" }}>
                {value}
              </Typography>
            </Grid>)}
        </Grid> :
          <div className={classes.spacer} />}
        <div className={classes.actions}>
          <Paper className={classes.searchRoot} elevation={0}>
            <Tooltip title={intl.formatMessage({ id: 'patientList.searchExamination' })}>
              <TextField
                type={"search"}
                className={classes.input}
                // onFocus={() => setShowSearchButton(true)}
                // onBlur={() => setShowSearchButton(false)}
                value={searchValue}
                placeholder={intl.formatMessage({ id: 'patientList.searchPlaceholder' })}
                onInput={e => setSearchValue(e.target.value)}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    setSearchWord(searchValue);
                  }
                }}
              // InputProps={{
              //   endAdornment: (showSearchButton ? <InputAdornment position="end">
              //     <IconButton aria-label="Search list" onClick={e => setSearchWord(searchValue)}>
              //       <Search color="error" />
              //     </IconButton>
              //   </InputAdornment> : null)
              // }}
              />
            </Tooltip>
          </Paper>
          <Tooltip title={intl.formatMessage({ id: 'patientList.filter' })}>
            <IconButton aria-label="Filter" onClick={e => openFilter(e)}>
              <FilterListIcon color="error" className={[
                classes.filter,
                showFilter ? classes.filterOpen : classes.filterClose
              ]} />
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'patientList.refresh' })}>
            <IconButton ref={refreshBtnRef} aria-label="Refresh list" onClick={e => createRefreshHandler(e)}>
              <Refresh color="error" />
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'patientList.addRecord' })}>
            <IconButton aria-label="Add list" onClick={e => createAddNewRecordHandler(e)}>
              <AddCircleIcon color="error" />
            </IconButton>
          </Tooltip>
          {!hideCheckbox && <Tooltip title={intl.formatMessage({ id: 'patientList.delete' })}>
            <IconButton aria-label="Delete" disabled={numSelected === 0} onClick={() => onDelete()}>
              <DeleteIcon color={numSelected === 0 ? "disabled" : "error"} />
            </IconButton>
          </Tooltip>}
        </div>
      </Toolbar>
      <Collapse in={showFilter}>
        <EnhancedTableFilter onRefreshRequest={createRefreshHandler} filterList={filterList} setFilterList={setFilterList} tableHead={tableHead} locationList={locationList} />
      </Collapse>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onAddNewRecord: PropTypes.func,
  onRefreshRequest: PropTypes.func,
  offset: PropTypes.number,
  limit: PropTypes.number,
  orderby: PropTypes.string,
  order: PropTypes.string,
  searchWord: PropTypes.string,
  setSearchWord: PropTypes.func,
  filterList: PropTypes.array,
  setFilterList: PropTypes.func,
  tableHead: PropTypes.array,
  hideCheckbox: PropTypes.bool,
  onDelete: PropTypes.func,
  locationList: PropTypes.array,
  duplicateCases: PropTypes.array,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    // marginBottom: theme.spacing(2),
  },
  tableCell: {
    paddingLeft: '3px',
    paddingRight: '6px',
  },
  table: {
  },
  tableWrapper: {
    overflow: 'auto',
    height: '60vh'
  }
}));

export default function EnhancedTable({ ...props }) {
  const { isSigknow, tableHead, tableData, newData, setNewData, tableHeaderColor, count, handleRefreshRequest, handleDeleteRequest, handleNewRecord, handleOnClick,
    offset, setOffset, limit, setLimit, order, setOrder, orderby, setOrderby, selected, setSelected, searchWord, setSearchWord,
    filterList, setFilterList, locationList, duplicateCases } = props;
  const classes = useStyles();
  const [dense] = useState(true);
  const [hideCheckbox, setHideCheckbox] = useState(!isSigknow);
  const paginationRef = useRef(null);
  const intl = useIntl();
  const patientListCol = useMappedState(useCallback(state => state.setting.preference.patientListCol));
  const role = useMappedState(useCallback(state => state.setting.role));

  useEffect(() => {
    setHideCheckbox(!isSigknow);
  }, [isSigknow])

  useEffect(() => {
    setHideCheckbox(tableData.length === 0/* || !(tableData[0].sigknowTag)*/);
  }, [tableData]);

  function onRequestSort(event, property) {
    console.log(property);
    console.log(order);
    console.log(orderby);
    let newOrder = order === 'desc' ? 'asc' : 'desc';
    setOrder(newOrder);
    setOrderby(property);
  }

  function handleSelectAllClick(event) {
    if (selected.length === 0) {
      const newSelecteds = tableData.filter(n => (n.status != undefined && n.status < 6)).map(n => n.uuid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, uuid) {
    handleOnClick(event, uuid);
  }

  function handleChangePage(event, newPage) {
    setOffset(limit * newPage);
  }

  function handleChangeRowsPerPage(event) {
    console.log('handleChangeRowsPerPage in');
    console.log(event);
    let page = offset / limit;
    let newOffset = page * event.target.value;
    if (newOffset >= count) {
      if (event.target.value >= count) {
        newOffset = 0;
      } else {
        newOffset = (Math.floor((count - 1) / event.target.value)) * event.target.value;
      }
    }
    setLimit(event.target.value);
    setOffset(newOffset);
  }


  const onDelete = () => {
    console.log('On delete in');
    console.log('selected:');
    console.log(selected);
    handleDeleteRequest(selected);
  }

  const resetNewData = () => {
    if (setNewData && typeof setNewData === 'function') {
      setNewData([]);
    }
  }

  const isSelected = uuid => selected.indexOf(uuid) !== -1;

  const emptyRows = 0;//rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar resetNewData={resetNewData} numSelected={selected.length} onAddNewRecord={handleNewRecord} onRefreshRequest={handleRefreshRequest} offset={offset} limit={limit} orderby={orderby} order={order} searchWord={searchWord} setSearchWord={setSearchWord}
          filterList={filterList} setFilterList={setFilterList} tableHead={tableHead} locationList={locationList}
          hideCheckbox={hideCheckbox} onDelete={onDelete} duplicateCases={duplicateCases} />
        <div className={classes.tableWrapper}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderby={orderby}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={onRequestSort}
              rowCount={tableData.length}
              headRows={tableHead}
              tableHeaderColor={tableHeaderColor}
              hideCheckbox={hideCheckbox}
            />
            {newData && !!newData.length ? <TableBody >
              {newData
                .map((row, index) => {
                  console.log('newData', index, row);
                  const isItemSelected = isSelected(row.uuid);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const TR = row.isUpdated ? StyledTableRow : TableRow;

                  return (
                    <TR
                      hover
                      onClick={event => handleClick(event, row.uuid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.uuid}
                      selected={isItemSelected}
                    >
                      {!hideCheckbox && <TableCell padding={'none'} className={classes.tableCell}>
                        <Checkbox
                          disabled={!(row.status < 6)}
                          checked={isItemSelected}
                          onClick={
                            (e) => {
                              console.log("isselected: " + isSelected(row.uuid));
                              if (isSelected(row.uuid)) {
                                let newSelected = selected.filter(item => item !== row.uuid);
                                console.log(newSelected);
                                setSelected(newSelected);
                              } else {
                                console.log("add new item: " + row.uuid);
                                setSelected(
                                  [...selected,
                                  row.uuid]
                                );
                              }
                              e.stopPropagation();
                            }
                          }
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>}
                      {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell> */}
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.icons}</TableCell>
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.patchId}</TableCell>
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.deviceId}</TableCell>
                      {patientListCol.examNo ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.checkId}</TableCell> : null}
                      {patientListCol.name ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.name}</TableCell> : null}
                      {/* <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.tag}</TableCell> */}
                      {patientListCol.gender ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.sex}</TableCell> : null}
                      {patientListCol.age ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.age}</TableCell> : null}
                      {patientListCol.birth ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.birth}</TableCell> : null}
                      {patientListCol.chartNo ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.patientId}</TableCell> : null}
                      {patientListCol.physician ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.physician}</TableCell> : null}
                      {patientListCol.interpretedDoctor ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.interpretedDoctor}</TableCell> : null}
                      {patientListCol.applyDate ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.applyDate}</TableCell> : null}
                      {patientListCol.dayVersion ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.pateintCareDuration}</TableCell> : null}
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.signalComponent}</TableCell>
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.reportComponent}</TableCell>
                      {row.status === undefined || !patientListCol.status ? null : <TableCell align="left" padding={'none'} className={classes.tableCell}>{!isSigknow ? parseStatusForHospitalStaff(row.status) : parseStatus(row.status)}</TableCell>}
                      {row.location === undefined || !patientListCol.location ? null : <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.location}</TableCell>}
                      {patientListCol.create ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.createTimeComponent}</TableCell> : null}
                      {patientListCol.update ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.updateTimeComponent}</TableCell> : null}
                      {/* {row.sigknowTag === undefined ? null : <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.sigknowTag}</TableCell>} */}
                    </TR>
                  );
                })}
              <TableRow style={{ background: "gainsboro" }}>
                <TableCell style={{ textAlign: "center" }} colSpan={100}>
                  <FormattedMessage id='patientList.newPatientDivider' />
                </TableCell>
              </TableRow>
            </TableBody> : null}
            <TableBody>
              {tableData
                .map((row, index) => {
                  const isItemSelected = isSelected(row.uuid);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const TR = row.isUpdated ? StyledTableRow : TableRow;

                  return (
                    <TR
                      hover
                      onClick={event => handleClick(event, row.uuid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.uuid}
                      selected={isItemSelected}
                    >
                      {!hideCheckbox && <TableCell padding={"none"} className={classes.tableCell}>
                        <Checkbox
                          disabled={!(row.status < 6)}
                          checked={isItemSelected}
                          onClick={
                            (e) => {
                              console.log("isselected: " + isSelected(row.uuid));
                              if (isSelected(row.uuid)) {
                                let newSelected = selected.filter(item => item !== row.uuid);
                                console.log(newSelected);
                                setSelected(newSelected);
                              } else {
                                console.log("add new item: " + row.uuid);
                                setSelected(
                                  [...selected,
                                  row.uuid]
                                );
                              }
                              e.stopPropagation();
                            }
                          }
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>}
                      {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell> */}
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.icons}</TableCell>
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.patchId}</TableCell>
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.deviceId}</TableCell>
                      {patientListCol.examNo ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.checkId}</TableCell> : null}
                      {patientListCol.name ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.name}</TableCell> : null}
                      {/* <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.tag}</TableCell> */}
                      {patientListCol.gender ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.sex}</TableCell> : null}
                      {patientListCol.age ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.age}</TableCell> : null}
                      {patientListCol.birth ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.birth}</TableCell> : null}
                      {patientListCol.chartNo ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.patientId}</TableCell> : null}
                      {patientListCol.physician ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.physician}</TableCell> : null}
                      {patientListCol.interpretedDoctor ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.interpretedDoctor}</TableCell> : null}
                      {patientListCol.applyDate ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.applyDate}</TableCell> : null}
                      {patientListCol.dayVersion ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.dayVersion}</TableCell> : null}
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.signalComponent}</TableCell>
                      <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.reportComponent}</TableCell>
                      {row.status === undefined || !patientListCol.status ? null : <TableCell align="left" padding={'none'} className={classes.tableCell}>{!isSigknow ? parseStatusForHospitalStaff(row.status) : parseStatus(row.status)}</TableCell>}
                      {row.location === undefined || !patientListCol.location ? null : <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.location}</TableCell>}
                      {/* {row.sigknowTag === undefined ? null : <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.sigknowTag}</TableCell>} */}
                      {patientListCol.create ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.createTimeComponent}</TableCell> : null}
                      {patientListCol.update ? <TableCell align="left" padding={'none'} className={classes.tableCell}>{row.updateTimeComponent}</TableCell> : null}
                    </TR>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          ref={paginationRef}
          labelDisplayedRows={({ from, to, count }) =>
            intl.formatMessage({
              id: 'patientList.displayRows'
            },
              {
                from: from.toString(),
                to: to === -1 ? count.toString() : to.toString(),
                count: count.toString()
              })
          }
          labelRowsPerPage={intl.formatMessage({ id: "patientList.rowsPerPage" })}
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={offset / limit}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/*<FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />*/}
    </div>
  );
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHead: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  newData: PropTypes.array.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]).isRequired,
  defaultRowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleDeleteRequest: PropTypes.func.isRequired,
  handleRefreshRequest: PropTypes.func.isRequired,
  handleNewRecord: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  setOrder: PropTypes.func.isRequired,
  orderby: PropTypes.oneOf(['icons',
    'deviceId',
    'checkId',
    'name',
    'sex',
    'age',
    'patientId',
    'physician',
    'signal',
    'report',
    'updateTime',
    'createTime']).isRequired,
  setOrderby: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  searchWord: PropTypes.string,
  setSearchWord: PropTypes.func,
  filterList: PropTypes.array,
  setFilterList: PropTypes.func,
  locationList: PropTypes.array,
  isSigknow: PropTypes.bool,
  duplicateCases: PropTypes.array,
};
