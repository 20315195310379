import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useMappedState } from "redux-react-hook";
import withStyles from "@material-ui/core/styles/withStyles";
import { axiosService } from "axios/axiosInstance";
import Cookies from "js-cookie";
import EmergentNotification from "views/PatientDetail/EmergentNotification.jsx"
import FileHistory from "views/PatientDetail/FileHistory.jsx";
import { ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_HOSPITALSTAFFLIST, ACTIONTYPE_MESSAGE_CONFIG } from "../../redux/actionTypes";

import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import useAxiosHandler from '../../axios/axiosHandler';
import Messenger from './Messenger';
import { Grid } from 'views/PatientDetail/PatientDetail.jsx';
import { Tooltip, TextField, FormControl, InputLabel, Select, Input, MenuItem, ListItemText, Typography, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isHospitalStaff } from "components/Azure/Utilities";
import { parseStatus, parseStatusForHospitalStaff } from 'views/PatientDetail/ReportInfo';
import PatientInfo from './PatientInfo';
import AdditionalInformation from './AdditionalInformation';
import { ArrowDropDown } from '@material-ui/icons';
import { useToolbarStyles } from 'components/Table/EnhancedTable';

export const ITEM_HEIGHT = 30;
export const ITEM_PADDING_TOP = 4;

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  dividerTypography: {
    cursor: "pointer",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    background: "#DAE9F8"
  },
  gridItem: {
    paddingRight: theme.spacing(1)
  },
  sectionOpen: {
    transform: "rotate(0deg)"
  },
  sectionClose: {
    transform: "rotate(90deg)"
  },
  section: {
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.short
    }),
    verticalAlign: "bottom",
    float: "right"
  },
}));

const styles = {
  image: {
    maxWidth: "300px",
    maxHeight: "420px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const AnalystSelector = (props) => {
  const { analystList, labelId, onChange, selectValue, editable, setter } = props;
  const menuRef = React.useRef(null);
  const [menuRefO, setMenuRefO] = React.useState(menuRef);
  const classes = useStyles();

  return (<FormControl className={classes.formControl}>
    <InputLabel shrink htmlFor={`select-multiple-checkbox-${labelId}`}>
      <FormattedMessage id={`patientDetail.report.${labelId}`} />
    </InputLabel>
    <Select
      multiline
      multiple
      value={selectValue}
      onChange={event => onChange(labelId, event.target.value.map(v => analystList.find(function (p) {
        return p.Name === v;
      })), setter)}
      input={<Input id={`select-multiple-checkbox-${labelId}`} />}
      renderValue={selected => selected.join(', ')}
      readOnly={!editable}
      ref={menuRefO}
      onOpen={(e) => {
        console.log('on open');
        console.log(e);
        setMenuRefO(e.currentTarget);
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: () => {
          console.log('calling get content anchorEl');
          console.log(menuRefO.current);
          return menuRefO.current;
        },
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      }}
    >
      {analystList.map(analyst =>
        <MenuItem key={analyst.Uuid} value={analyst.Name}>
          <Checkbox checked={selectValue.indexOf(analyst.Name) > -1} />
          <ListItemText primary={analyst.Name} />
        </MenuItem>
      )}
    </Select>
  </FormControl>);
};

const StatusSelector = (props) => {

  const { statusList, onChange, selectValue, editable, setter, hasReportViewer } = props;
  const menuRef = React.useRef(null);
  const [menuRefO, setMenuRefO] = React.useState(menuRef);
  const classes = useStyles();

  return (<FormControl className={classes.formControl} mx="auto">
    <InputLabel shrink htmlFor="select-multiple-checkbox-status">
      <FormattedMessage id='patientDetail.report.status' />
    </InputLabel>
    <Select
      value={selectValue}
      onChange={event => onChange('status', event.target.value.key, setter)}
      input={<Input id="select-multiple-checkbox-status" />}
      renderValue={selected => selected}
      readOnly={!editable}
      ref={menuRefO}
      onOpen={(e) => {
        setMenuRefO(e.currentTarget);
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: () => {
          return menuRefO.current;
        },
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      }}
    >
      {statusList.map(status => (
        <MenuItem key={status.key} value={status} disabled={status.key < 15 && hasReportViewer}>
          <ListItemText primary={status.value} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>);
}

function PatientDetailForm(props) {

  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [successHandler, errorHandler] = useAxiosHandler();
  const { isNewRecord, uuid, onClose, handleRefreshRequest,
    model, deviceId, serviceId, deviceDayVersion, setDeviceDayVersion,
    setIsDetailModified, tags,
    reportInfo, setReportInfo,
    patientInfo, setPatientInfo,
    emergentNote, setEmergentNote,
    setModifiedReportInfo,
    setModifiedPatientInfo,
    setModifiedEmergentNote,
    onSelect, onUploaded, onDownloaded, pathname, isDemo, 
    birthDpRef, setupDateDpRef, expectedDateDpRef, highlightSerial } = props;

  const analystList = useMappedState(useCallback(state => state.initialData.analystList));
  const physicianList = useMappedState(useCallback(state => state.initialData.physicianList));
  const locationList = useMappedState(useCallback(state => state.initialData.locationList));
  const hospitalStaffList = useMappedState(useCallback(state => state.initialData.hospitalStaffList));
  const role = useMappedState(useCallback(state => state.setting.role));

  const [collapsePatientInfo, setCollapsePatientInfo] = useState(true);
  const [collapseAdditional, setCollapseAdditional] = useState(true);
  const [collapseSignalReport, setCollapseSignalReport] = useState(true);
  const [collapseMessage, setCollapseMessage] = useState(true);
  const [editMessenger, setEditMessenger] = useState(false);

  const [deviceIdState, setDeviceIdState] = useState(deviceId);
  const [serviceIdState, setServiceIdState] = useState(serviceId);
  const [dialogComponent, setDialogComponent] = useState(null);
  const [canUploadSignal, setCanUploadSignal] = useState(false);
  const [canUploadReport, setCanUploadReport] = useState(false);
  const [canDownloadSignal, setCanDownloadSignal] = useState(false);
  const [canDownloadReport, setCanDownloadReport] = useState(false);
  const [canOpenWebViewer, setCanOpenWebViewer] = useState(false);
  const [canOpenPdfViewer, setCanOpenPdfViewer] = useState(false);
  const [canEditComment, setCanEditComment] = useState(false);
  const [signalHistory, setSignalHistory] = useState([]);
  const [reportHistory, setReportHistory] = useState([]);

  const [showReportInfo, setShowReportInfo] = useState(false || (isNewRecord && (role === 4 || role === 5 || role === 100)));
  const [showHistory, setShowHistory] = useState(false);
  const [showMessenger, setShowMessenger] = useState(false);
  const [editReportInfo, setEditReportInfo] = useState(true);
  const [editPatientInfo, setEditPatientInfo] = useState(true);
  const [editEmergentNote, setEditEmergentNote] = useState(role === 4 || role === 5 || role === 100);
  const [isCommentModified, setIsCommentModified] = useState(false);

  const statuses = [
    { key: 0, value: intl.formatMessage({ id: 'patientDetail.info.status.created' }) },
    { key: 1, value: intl.formatMessage({ id: 'patientDetail.info.status.signalUploading' }) },
    { key: 2, value: intl.formatMessage({ id: 'patientDetail.info.status.signalFailed' }) },
    { key: 3, value: intl.formatMessage({ id: 'patientDetail.info.status.signalSuccessed' }) },
    { key: 4, value: intl.formatMessage({ id: 'patientDetail.info.status.inspecting' }) },
    { key: 5, value: intl.formatMessage({ id: 'patientDetail.info.status.inspected' }) },
    { key: 6, value: intl.formatMessage({ id: 'patientDetail.info.status.analyzing' }) },
    { key: 7, value: intl.formatMessage({ id: 'patientDetail.info.status.analyzed' }) },
    { key: 8, value: intl.formatMessage({ id: 'patientDetail.info.status.reviewing' }) },
    { key: 9, value: intl.formatMessage({ id: 'patientDetail.info.status.reviewed' }) },
    // { key: 10, value: intl.formatMessage({ id: 'patientDetail.info.status.confirming' }) },
    // { key: 11, value: intl.formatMessage({ id: 'patientDetail.info.status.confirmed' }) },
    { key: 12, value: intl.formatMessage({ id: 'patientDetail.info.status.reportUploading' }) },
    { key: 13, value: intl.formatMessage({ id: 'patientDetail.info.status.reportUploaded' }) },
    { key: 14, value: intl.formatMessage({ id: 'patientDetail.info.status.reportReviewing' }) },
    { key: 15, value: intl.formatMessage({ id: 'patientDetail.info.status.reportReviewed' }) },
    { key: 16, value: intl.formatMessage({ id: 'patientDetail.info.status.reportDownloaded' }) },
    { key: 17, value: intl.formatMessage({ id: 'patientDetail.info.status.archived' }) },
  ];

  useEffect(() => {
    setPatientInfo(preState => {
      return {
        ...preState,
        device: {
          ...preState.deviceId,
          Model: model,
          DeviceId: deviceId
        },
        patch: serviceId
      };
    })
  }, [model, deviceId, serviceId]);

  function onChange(key, value, setter) {
    if (typeof setter === 'function') {
      setter(prevState => {
        console.log(JSON.stringify(prevState[key]));
        console.log(JSON.stringify(value));
        if (JSON.stringify(prevState[key]) === JSON.stringify(value)) {
          console.log(key + ' state not updated');
          return prevState;
        } else {
          console.log(key + ' state updated');
          if (setter === setReportInfo) {
            setIsDetailModified(true);
            setModifiedReportInfo(preModifiedReportInfo => {
              return {
                ...preModifiedReportInfo,
                [key]: Array.isArray(value) ? Array.from(value) : value
              }
            });
          } else if (setter === setPatientInfo) {
            setIsDetailModified(true);
            setModifiedPatientInfo(preModifiedPatientInfo => {
              return {
                ...preModifiedPatientInfo,
                [key]: value
              };
            });
          } else if (setter === setEmergentNote) {
            setIsDetailModified(true);
            setModifiedEmergentNote(preModifiedEmergentNote => {
              return {
                ...preModifiedEmergentNote,
                [key]: value
              };
            });
          }
          return { ...prevState, [key]: value };
        }
      });
    }
  }

  function dispatchInitData(message) {
    if (message.initialData !== undefined) {
      if (message.initialData.analystList !== undefined) {
        dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: message.initialData.analystList } });
      }
      if (message.initialData.physicianList !== undefined) {
        dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: message.initialData.physicianList } });
      }
      if (message.initialData.locations !== undefined) {
        dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: message.initialData.locations } });
      }
      if (message.initialData.hospitalStaff !== undefined) {
        dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: message.initialData.hospitalStaff } });
      }
    }
  }

  const handleMessage = (message, isQueryInitData) => {
    if (message !== undefined && message !== null) {
      if (isQueryInitData) {
        dispatchInitData(message);
      }
      setDeviceDayVersion(message.deviceDayVersion);
      setEditMessenger(message.boardEditable);
      setShowMessenger(true);
      setEditEmergentNote(message.emergentNoteEditable);
      setEditPatientInfo(message.patientInfoEditable);
      setEditReportInfo(message.reportInfoEditable);
      if (message.emergentNote !== undefined && message.emergentNote !== null) {
        setEmergentNote(message.emergentNote);
      } else {
      }
      if (message.patientInfo !== undefined && message.patientInfo !== null) {
        setPatientInfo(message.patientInfo);
        setDeviceIdState(message.patientInfo.device.DeviceId);
        setServiceIdState(message.patientInfo.patch);
      } else {
      }
      if (message.reportInfo !== undefined && message.reportInfo !== null) {
        setShowReportInfo(true);
        setReportInfo(message.reportInfo);
      } else {
        setShowReportInfo(false);
      }
      if (message.isCommentModified !== undefined && message.isCommentModified !== null) {
        setIsCommentModified(message.isCommentModified);
      }
      if (message.history !== undefined && message.history !== null) {
        setShowHistory(true);
        if (message.history.canUploadSignal !== undefined && message.history.canUploadSignal !== null) {
          setCanUploadSignal(message.history.canUploadSignal);
        }
        if (message.history.canUploadReport !== undefined && message.history.canUploadReport !== null) {
          setCanUploadReport(message.history.canUploadReport);
        }
        if (message.history.canDownloadSignal !== undefined && message.history.canDownloadSignal !== null) {
          setCanDownloadSignal(message.history.canDownloadSignal);
        }
        if (message.history.canDownloadReport !== undefined && message.history.canDownloadReport !== null) {
          setCanDownloadReport(message.history.canDownloadReport);
        }
        if (message.history.canOpenWebViewer !== undefined && message.history.canOpenWebViewer !== null) {
          setCanOpenWebViewer(message.history.canOpenWebViewer);
        }
        if (message.history.canOpenPdfViewer !== undefined && message.history.canOpenPdfViewer !== null) {
          setCanOpenPdfViewer(message.history.canOpenPdfViewer);
        }
        if (message.history.canEditComment !== undefined && message.history.canEditComment != null) {
          setCanEditComment(message.history.canEditComment);
        }
        if (message.history.signal !== undefined && message.history.signal !== null) {
          setSignalHistory(message.history.signal);
        }
        if (message.history.report !== undefined && message.history.report !== null) {
          setReportHistory(message.history.report);
        }
      } else {
        setShowHistory(false);
      }
    }
  }

  useEffect(() => {
    let isQueryInitData = locationList.length === 0;


    if (isNewRecord === false && typeof uuid === 'string') {
      if (typeof onSelect === 'function') {
        handleMessage(onSelect({ uuid, isQueryInitData }), isQueryInitData)
      }
      if (pathname) {
        // query patient info from server by uuid
        axiosService.get(
          pathname + '?uuid=' + uuid + '&initdata=' + isQueryInitData,
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('apiKey'),
              'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
            }
          }).then(response => {
            console.log(response.data);
            function onSuccess(response) {
              if (response.status === 200) {
                if (response.data.error === 0) {
                  handleMessage(response.data.message, isQueryInitData);
                } else if (response.data.error === 4) {
                  onClose();
                }
              }
            }
            successHandler(response, onSuccess);
          }).catch(error => {
            errorHandler(error);
          });
      }
    } else {
      // if (isDemo) {
      // if (locationList.length === 0) {
      //   dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [{ Name: "Demo", Uuid: "demo_location" }] } });
      // }
      // } else 
      if (!isDemo && isQueryInitData && pathname) {
        axiosService.get(
          '/initdata',
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('apiKey'),
              'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
            }
          }).then(response => {
            console.log(response.data);
            function onSuccess(response) {
              if (response.status === 200 && response.data.error === 0
                && response.data.message !== undefined) {
                dispatchInitData(response.data.message);
              }
            }
            successHandler(response, onSuccess);
          }).catch(error => {
            errorHandler(error);
          });
      }
    }
    return () => {
    };
  }, []);

  useEffect(() => {
    if (locationList.length === 1) {
      setPatientInfo(prevState => {
        return {
          ...prevState,
          location: { Uuid: /*"40ecd831-7adc-11ea-b330-080027e0977a"*/locationList[0].Uuid, Name: /*"三軍總醫院"*/locationList[0].Name }
        };
      });
    }
  }, [locationList])

  return (
    <Grid container>
      <>{dialogComponent}</>
      <Grid container direction='column'>
        <Grid container direction='row' style={{ background: "#F2F2F2" }}>
          <Grid item xs={6} className={classes.gridItem}>
            <Tooltip
              title={patientInfo.device.DeviceId ? patientInfo.device.DeviceId.length + '/11' : '0/11'} enterDelay={500}>
              <TextField
                id="asynchronous-patient-deviceId"
                className={classes.formControl}
                label={intl.formatMessage({ id: 'patientDetail.info.deviceId' })}
                required
                value={patientInfo.device.DeviceId}
                margin="normal"
                InputProps={{readOnly: true}}
                readOnly
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: (highlightSerial && patientInfo.device.Model === 'UG01' ? { color: "red" } : { color: "inherit" }) 
                }}
                helperText={patientInfo.device.Model === 'UG01' ? (highlightSerial ? intl.formatMessage({ id: 'error.deviceDuplicate' }, { red: str => <span style={{color: 'red'}}>{str}</span>}) : intl.formatMessage({ id: 'patientDetail.info.patientIdText' }, { days: deviceDayVersion })) : null}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <Tooltip
              title={patientInfo.patch ? patientInfo.patch.length + '/11' : '0/11'} enterDelay={500}>
              <TextField
                id="patch-id"
                className={classes.formControl}
                label={intl.formatMessage({ id: 'patientDetail.info.patch' })}
                value={patientInfo.patch}
                InputProps={{ readOnly: true }}
                margin="normal"
                readOnly
                inputProps={{
                  style: ( highlightSerial && patientInfo.device.Model === 'UG02' ? { color: "red" } : { color: "inherit" })
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={patientInfo.device.Model === 'UG02' ? ( highlightSerial ? intl.formatMessage({ id: 'error.patchDuplicate'}, { red: str => <span style={{color: 'red'}}>{str}</span>}) : intl.formatMessage({ id: 'patientDetail.info.patientIdText' }, { days: deviceDayVersion })) : null}
              />
            </Tooltip>
          </Grid>
        </Grid>

        {showReportInfo && <Grid container direction='row' style={{ background: "#F2F2F2" }}>
          <Grid item xs={3} className={classes.gridItem}><AnalystSelector labelId='analyst' selectValue={reportInfo.analyst.map(analyst => analyst.Name)} analystList={analystList} editable={editReportInfo} onChange={onChange} setter={setReportInfo} /></Grid>
          <Grid item xs={3} className={classes.gridItem}><AnalystSelector labelId='reviewer' selectValue={reportInfo.reviewer.map(analyst => analyst.Name)} analystList={analystList} editable={editReportInfo} onChange={onChange} setter={setReportInfo} /></Grid>
          <Grid item xs={3} className={classes.gridItem}><AnalystSelector labelId='reportReviewer' selectValue={reportInfo.reportReviewer.map(analyst => analyst.Name)} analystList={analystList} editable={editReportInfo} onChange={onChange} setter={setReportInfo} /></Grid>
          <Grid item xs={3} className={classes.gridItem}><StatusSelector selectValue={isHospitalStaff(role) ? parseStatusForHospitalStaff(reportInfo.status) : parseStatus(reportInfo.status)} statusList={statuses} editable={editReportInfo} onChange={onChange} setter={setReportInfo} hasReportViewer={reportInfo.reportReviewer.length > 0}></StatusSelector></Grid>
        </Grid>}
        <Grid container direction='row'>
          <Grid item xs={12}>
            <EmergentNotification emergentNote={emergentNote} onChange={onChange} setter={setEmergentNote} style={{ mx: 'auto' }} editable={editEmergentNote} uuid={uuid} />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <Typography className={classes.dividerTypography} onClick={(e) => setCollapsePatientInfo(state => !state)}>
          <FormattedMessage id={'patientDetail.hospitalPatient'} values={{ strong: str => <strong>{str}</strong> }} />
          <ArrowDropDown className={[
            classes.section,
            collapsePatientInfo ? classes.sectionOpen : classes.sectionClose
          ]} />
        </Typography>
      </Grid>
      <Grid container direction='column'>
        <Collapse in={collapsePatientInfo}>
          <Grid item>
            <PatientInfo deviceDayVersion={deviceDayVersion}
              isNewRecord={isNewRecord} patientInfo={patientInfo} onChange={onChange} setter={setPatientInfo} style={{ mx: 'auto' }} editable={editPatientInfo} physicianList={physicianList} locationList={locationList} hospitalStaffList={hospitalStaffList} 
              birthDpRef={birthDpRef} setupDateDpRef={setupDateDpRef} expectedDateDpRef={expectedDateDpRef}/>
          </Grid>
        </Collapse>
      </Grid>
      <Grid xs={12} item>
        <Typography className={classes.dividerTypography} onClick={(e) => setCollapseAdditional(state => !state)}>
          <FormattedMessage id={'patientDetail.additionalInformation'} values={{ strong: str => <strong>{str}</strong> }} />
          <ArrowDropDown className={[
              classes.section,
              collapseAdditional ? classes.sectionOpen : classes.sectionClose
            ]} />
        </Typography>
      </Grid>
      <Grid container direction='column'>
        <Collapse in={collapseAdditional}>
          <Grid item>
            <AdditionalInformation deviceDayVersion={deviceDayVersion}
              isNewRecord={isNewRecord} patientInfo={patientInfo} onChange={onChange} setter={setPatientInfo} style={{ mx: 'auto' }} editable={editPatientInfo} physicianList={physicianList} locationList={locationList} hospitalStaffList={hospitalStaffList} />
          </Grid>
        </Collapse>
      </Grid>
      {showHistory && !isNewRecord ?
        <>
          <Grid xs={12} item>
            <Typography className={classes.dividerTypography} onClick={(e) => setCollapseSignalReport(state => !state)}>
              <FormattedMessage id={'patientDetail.signalReport'} values={{ strong: str => <strong>{str}</strong> }} />
              <ArrowDropDown className={[
                  classes.section,
                  collapseSignalReport ? classes.sectionOpen : classes.sectionClose
                ]} />
            </Typography>
          </Grid>
          <Grid container >
            <Collapse in={collapseSignalReport}>
              <Grid xs={12} item>
                <FileHistory isDemo={isDemo} dense secondary canUpload={canUploadSignal} canDownload={canDownloadSignal} canOpenWebViewer={canOpenWebViewer} canOpenPdfViewer={canOpenPdfViewer} canEditComment={canEditComment} history={signalHistory} historySetter={setSignalHistory} title={intl.formatMessage({ id: 'patientDetail.signal' })}
                  type={isDemo ? 'signal-demo' : 'signal'} fileType={'.bin'} uuid={uuid} deviceId={deviceIdState} patchId={serviceIdState} onUploaded={onUploaded} onDownloaded={onDownloaded} />
                <FileHistory isDemo={isDemo} dense secondary canUpload={canUploadReport} canDownload={canDownloadReport} canOpenWebViewer={canOpenWebViewer} canOpenPdfViewer={canOpenPdfViewer} canEditComment={canEditComment} history={reportHistory} historySetter={setReportHistory} title={intl.formatMessage({ id: 'patientDetail.report' })}
                  type={isDemo ? 'report-demo' : 'report'} fileType={'.pdf,.doc,.docx'} signalHistory={signalHistory} uuid={uuid} deviceId={deviceIdState} patchId={serviceIdState} setDialogComponent={setDialogComponent} onUploaded={onUploaded} onDownloaded={onDownloaded} isCommentModified={isCommentModified} />
              </Grid>
            </Collapse>
          </Grid>
        </>
        : null}
      {showMessenger ? <>
        <Grid xs={12} item>
          <Typography className={classes.dividerTypography} onClick={(e) => setCollapseMessage(state => !state)}>
            <FormattedMessage id={'patientDetail.message'} values={{ strong: str => <strong>{str}</strong> }} />
            <ArrowDropDown className={[
                classes.section,
                collapseMessage ? classes.sectionOpen : classes.sectionClose
              ]} />
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Collapse in={collapseMessage}>
            <Messenger isDemo={isDemo} uuid={uuid} editable={editMessenger} handleRefreshRequest={handleRefreshRequest} messages={isDemo ? onSelect({ uuid }).board.messages : []} />
          </Collapse>
        </Grid>
      </> : null}
    </Grid>
  );
}

PatientDetailForm.defaultProps = {
  model: 'UG01',
  deviceId: '',
  serviceId: '',
  isNewRecord: false,
  uuid: '',
  onClose: () => { console.log('default onClose'); },
  handleRefreshRequest: () => { console.log('default handleRefreshRequest'); },
  highlightSerial: false
}

PatientDetailForm.propTypes = {
  model: PropTypes.string,
  deviceId: PropTypes.string,
  serviceId: PropTypes.string,
  deviceDayVersion: PropTypes.number,
  setDeviceDayVersion: PropTypes.func,
  isNewRecord: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefreshRequest: PropTypes.func.isRequired,
  setIsDetailModified: PropTypes.func.isRequired,
  tags: PropTypes.array,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onUploaded: PropTypes.func,
  onDownload: PropTypes.func,
  isDemo: PropTypes.bool,
  reportInfo: PropTypes.object.isRequired,
  setReportInfo: PropTypes.func.isRequired,
  patientInfo: PropTypes.object.isRequired,
  setPatientInfo: PropTypes.func.isRequired,
  emergentNote: PropTypes.object.isRequired,
  setEmergentNote: PropTypes.func.isRequired,
  setModifiedReportInfo: PropTypes.func.isRequired,
  setModifiedPatientInfo: PropTypes.func.isRequired,
  setModifiedEmergentNote: PropTypes.func.isRequired,
  birthDpRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  setupDateDpRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  expectedDateDpRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  highlightSerial: PropTypes.bool,
};

export default withStyles(styles)(PatientDetailForm);