import axios from "axios";
import https from "https";
import React, { useEffect } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from '@material-ui/core/colors';

import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button";
import { FormattedMessage } from "react-intl";
import { axiosService } from "../../axios/axiosInstance";
import Cookies from "js-cookie";
import useAxiosHandler from "../../axios/axiosHandler";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Card, CardHeader, Typography } from '@material-ui/core';


const styles = (theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  card: {
    border: '',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
});

function PreUpdateCheck(props) {
  const { classes, onClose } = props;
  const [successHandler, errorHandler] = useAxiosHandler();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(9);

  useEffect(() => {
  }, []);

  const saveResult = async (code, message) => {
    return await axiosService.post('/settings/preupdatecheck',
      {
        code,
        message
      },
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }
    )
  }

  const SuccessCard = () => {
    const successStyles = {
      ...styles,
      card: { ...styles.card, borderColor: '#4CAF50' },
      cardHeader: { ...styles.cardHeader, backgroundColor: '#E8F5E9', color: '#4CAF50' },
    };

    return (
      <Card style={successStyles.card}>
        <CardHeader
          avatar={<CheckCircleIcon />}
          title={<Typography>Success</Typography>}
          style={successStyles.cardHeader}
        />
      </Card>
    );
  }

  const FailureCard = () => {
    const failureStyles = {
      ...styles,
      card: { ...styles.card, borderColor: '#F44336' },
      cardHeader: { ...styles.cardHeader, backgroundColor: '#FFEBEE', color: '#F44336' },
    };

    return (
      <Card style={failureStyles.card}>
        <CardHeader
          avatar={<ErrorIcon />}
          title={<Typography>Failure</Typography>}
          style={failureStyles.cardHeader}
        />
      </Card>
    );
  }

  const onHandleClick = async () => {
    try {
      let code;
      let message;
      setLoading(true);
      const httpsAgent = new https.Agent({});
      const checkResponse = await axios.get(
        'https://update-check.report.sigknow.com',
        {
          httpsAgent,
          headers: { 'access-control-allow-origin': '*', 'Content-Type': 'application/json' },
          timeout: 15000
        }
      );

      if (!checkResponse || checkResponse.status !== 200) {
        throw new Error('Failed to get pre-update check response.')
      }
      else if (!checkResponse.data || !checkResponse.data.code || !checkResponse.data.message) {
        throw new Error('Unexpected content of pre-update check response.')
      } else {
        code = checkResponse.data.code;
        message = checkResponse.data.message;
      }

      const response = await saveResult(code, message);

      const onSuccess = (response) => {
        if (response.status === 200 && (response.data.error === 0 || response.data.error === 3)) {
          console.log('ok')
        }
      }
      successHandler(response, onSuccess);
      setLoading(false);
      setSuccess(0)

    } catch (error) {
      console.log(error);
      await saveResult('1', error.message);
      errorHandler(error);
      setLoading(false);
      setSuccess(1)
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <span style={{ textAlign: "center" }}>
            <FormattedMessage id="headerLinks.preUpdateCheck.content"></FormattedMessage>
          </span>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <div className={classes.wrapper}>
            {(() => {
              console.log(success);
              if (success === 0) {
                return <SuccessCard />
              } else if (success === 1) {
                return <FailureCard />
              } else {
                return <div></div>
              }
            })()}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={onHandleClick}
              disabled={loading}
            >
              <FormattedMessage id="headerLinks.preUpdateCheck"></FormattedMessage>
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              onClick={onClose}
              disabled={loading}
            >
              <FormattedMessage id="headerLinks.preUpdateCheck.close"></FormattedMessage>
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

PreUpdateCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
}

export default withStyles(styles)(PreUpdateCheck);
