import React, { useEffect, useState, useRef, useCallback } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cookies from "js-cookie";
import { axiosService } from "axios/axiosInstance";
import { FormattedMessage, useIntl } from 'react-intl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useAxiosHandler from "../../axios/axiosHandler";
import { useDispatch, useMappedState } from "redux-react-hook";
import { ACTIONTYPE_SETTING_PREFERENCE_PATIENTLISTCOL } from "redux/actionTypes";

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 4;
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

function Preference(props) {
  const { classes, onClose } = props;


  const intl = useIntl();
  const dispatch = useDispatch();
  const patientListCol = useMappedState(useCallback(state => state.setting.preference.patientListCol));
  const [isLoading, setIsLoading] = useState(true);

  const [patientListColSetting, setPatientListColSetting] = useState({
    examNo: true,
    name: true,
    gender: true,
    age: true,
    birth: false,
    chartNo: true,
    physician: true,
    interpretedDoctor: false,
    applyDate: false,
    dayVersion: false,
    status: true,
    location: false,
    create: true,
    update: false
});

  const [successHandler, errorHandler] = useAxiosHandler();

  const handleChange = (key, value) => {
    console.log(key, value);

    setPatientListColSetting({...patientListColSetting,
      [key]: value
    });
  };

  useEffect(() => {
    console.log(patientListCol);
    // query sales preference status
    axiosService.get('/settings/preference',
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200) {
            if(response.data.error === 0) {
              setPatientListColSetting({
                examNo: response.data.message.patientListCol.examNo,
                name: response.data.message.patientListCol.name,
                gender: response.data.message.patientListCol.gender,
                age: response.data.message.patientListCol.age,
                birth: response.data.message.patientListCol.birth,
                chartNo: response.data.message.patientListCol.chartNo,
                physician: response.data.message.patientListCol.physician,
                interpretedDoctor: response.data.message.patientListCol.interpretedDoctor,
                applyDate: response.data.message.patientListCol.applyDate,
                dayVersion: response.data.message.patientListCol.dayVersion,
                status: response.data.message.patientListCol.status,
                location: response.data.message.patientListCol.location,
                create: response.data.message.patientListCol.create,
                update: response.data.message.patientListCol.update
                });
            } else if (response.data.error === 3) {
              onClose();
            }
          }
          setIsLoading(false);
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
        setIsLoading(false);
      });
  }, []);

  const onClickApply = () => {
    axiosService.post('/settings/preference',
      {
        preference: { patientListCol: patientListColSetting}
      },
      {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('apiKey'),
          'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
        }
      }).then(response => {
        console.log(response.data);
        function onSuccess(response) {
          if(response.status === 200 && (response.data.error === 0 || response.data.error === 3)) {
            dispatch({
              type: ACTIONTYPE_SETTING_PREFERENCE_PATIENTLISTCOL, value: patientListColSetting
            });
            onClose();
          }
        }
        successHandler(response, onSuccess);
      }).catch(error => {
        errorHandler(error);
      });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox 
                disabled={isLoading}
                checked={patientListColSetting.examNo} 
                onClick={() => handleChange('examNo', !patientListColSetting.examNo)} />}
              label={intl.formatMessage({ id: 'patientDetail.info.checkId' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.name}
                onClick={() => handleChange('name', !patientListColSetting.name)} />}
              label={intl.formatMessage({ id: 'patientDetail.info.name' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.gender}
                onClick={() => handleChange('gender', !patientListColSetting.gender)} />}
              label={intl.formatMessage({ id: 'patientList.sex' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.age}
                onClick={() => handleChange('age', !patientListColSetting.age)} />}
              label={intl.formatMessage({ id: 'patientList.age' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.birth}
                onClick={() => handleChange('birth', !patientListColSetting.birth)} />}
              label={intl.formatMessage({ id: 'patientDetail.info.dateOfBirth' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.chartNo}
                onClick={() => handleChange('chartNo', !patientListColSetting.chartNo)} />}
              label={intl.formatMessage({ id: 'patientList.patientId' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.physician}
                onClick={() => handleChange('physician', !patientListColSetting.physician)} />}
              label={intl.formatMessage({ id: 'patientList.physician' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.interpretedDoctor}
                onClick={() => handleChange('interpretedDoctor', !patientListColSetting.interpretedDoctor)} />}
              label={intl.formatMessage({ id: 'patientList.reportingPhysician' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.applyDate}
                onClick={() => handleChange('applyDate', !patientListColSetting.applyDate)} />}
              label={intl.formatMessage({ id: 'patientList.applyDate' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.dayVersion}
                onClick={() => handleChange('dayVersion', !patientListColSetting.dayVersion)} />}
              label={intl.formatMessage({ id: 'patientDetail.info.dayVersion' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.status}
                onClick={() => handleChange('status', !patientListColSetting.status)} />}
              label={intl.formatMessage({ id: 'patientList.status' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.location}
                onClick={() => handleChange('location', !patientListColSetting.location)} />}
              label={intl.formatMessage({ id: 'patientList.location' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.create}
                onClick={() => handleChange('create', !patientListColSetting.create)} />}
              label={intl.formatMessage({ id: 'patientList.create' })}
              style={{ color: "#000000" }}
            />
            <FormControlLabel
              control={<Checkbox
                disabled={isLoading}
                checked={patientListColSetting.update}
                onClick={() => handleChange('update', !patientListColSetting.update)} />}
              label={intl.formatMessage({ id: 'patientList.update' })}
              style={{ color: "#000000" }}
            />
          </FormGroup>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
          <Button disabled={isLoading} variant="contained" color="primary" onClick={onClickApply}>
            <FormattedMessage id="settings.privilege.apply"></FormattedMessage>
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

Preference.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
}

export default withStyles(styles)(Preference);
