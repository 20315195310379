import React, { useEffect, useState, useCallback, useRef, lazy, Suspense } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NewReleases from "@material-ui/icons/NewReleases"
import Warning from "@material-ui/icons/Warning";
import Message from "@material-ui/icons/Message";
import Timer from "@material-ui/icons/Timer";
// core components
import Popover from '@material-ui/core/Popover';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { useDispatch, useMappedState } from "redux-react-hook";
import Cookies from "js-cookie";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import EnhancedTable from "../../components/Table/EnhancedTable";
import { ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_HOSPITALSTAFFLIST, ACTIONTYPE_PATIENTLIST_UPDATE, ACTIONTYPE_PATIENTLIST_ADD, ACTIONTYPE_PATIENTLIST_REMOVEALL, ACTIONTYPE_PATIENTLIST_COUNT, ACTIONTYPE_SETTING_TO_LOGIN, ACTIONTYPE_SETTING_TO_PATIENTLIST, ACTIONTYPE_MESSAGE_CONFIG } from "../../redux/actionTypes";
import Echo from 'laravel-echo';

import Button from "components/CustomButtons/Button.jsx";
import FileComp from 'components/FileComponent/FileComp';
import { useIntl, FormattedMessage } from 'react-intl';
import { isPowerUser, isAnalyst, isSeniorSales, isJuniorSales } from "../../components/Azure/Utilities";
import { TimeComponent } from "views/PatientList/PatientList";
import { parseGenderToString } from "views/PatientDetail/PatientDetail";

const PatientDetail = lazy(() => import('views/PatientDetail/PatientDetail'));

const styles = {
  icons: {
    verticalAlign: "middle"
  }
};

const MessageButton = (props) => {
  const { hasNewMessage, onClick } = props;
  const intl = useIntl();
  return <Tooltip title={intl.formatMessage({ id: 'patientDetail.conversation' })} >
    <IconButton component="div" aria-label="openChat" onClick={onClick}>
      {hasNewMessage ? <Badge variant="dot" color="error">
        <Message style={{ color: "green" }} />
      </Badge> : <Message style={{ color: "green" }} />}
    </IconButton>
  </Tooltip >;
}
MessageButton.propTypes = {
  hasNewMessage: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

const EmergentIcon = (props) => {
  const { emergentNoteOrigin } = props;
  const intl = useIntl();
  return emergentNoteOrigin ? <Tooltip title={intl.formatMessage({ id: 'patientList.emergency' })}>
    <IconButton component="div" disabled><NewReleases style={{ ...styles.icons, color: "red" }} /></IconButton>
  </Tooltip> : <IconButton component="div" disabled><NewReleases color="disabled" style={{ verticalAlign: "middle" }} /></IconButton >;
}
EmergentIcon.propTypes = {
  emergentNoteOrigin: PropTypes.bool.isRequired
}

const UrgentIcon = (props) => {
  const { isUrgent } = props;
  const intl = useIntl();
  return isUrgent ? <Tooltip title={intl.formatMessage({ id: 'patientDetail.info.isUrgent' })}>
    <IconButton component="div" disabled><Timer style={{ ...styles.icons, color: "orange" }} /></IconButton>
  </Tooltip> : <IconButton component="div" disabled><Timer color="disabled" style={{ verticalAlign: "middle" }} /></IconButton>;
}
UrgentIcon.propTypes = {
  isUrgent: PropTypes.bool.isRequired
}

const Icons = (props) => {
  return <div>
    {props.children}
  </div>;
}
Icons.propTypes = {
  children: PropTypes.node
}

export const Tag = (props) => {
  return <Tooltip title={props.title}><div style={{
    marginLeft: '1px',
    marginRight: '1px',
    marginTop: 'auto',
    marginBottom: 'auto',
    backgroundColor: props.color,
    width: 10,
    height: 20,
  }} />
  </Tooltip>;
}
Tag.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}


function DemoPatientList(props) {

  const [demoPatient, setDemoPatient] = useState([]);
  const lang = useMappedState(useCallback(state => state.setting.language));

  /*******
   * From Patient List begin
   */

  const { 
    // queryContent, createEchoChannel, detailGet, detailCreate, detailUpdate, demoPatient, uploadHandler, downloadHandler,
    classes } = props;
  const dispatch = useDispatch();
  const count = useMappedState(useCallback(state => state.patientList.count));
  const patientInfoArray = useMappedState(useCallback(state => state.patientList.listContent));
  const locationList = useMappedState(useCallback(state => state.initialData.locationList));
  const role = useMappedState(useCallback(state => state.setting.role));
  const patientListCol = useMappedState(useCallback(state => state.setting.preference.patientListCol));
  const patientInfoArrayRef = useRef();
  patientInfoArrayRef.current = patientInfoArray;

  const [dialogComponent, setDialogComponent] = useState(null);
  const [detailUuid, setDetailUuid] = useState('');
  const detailUuidRef = useRef();
  detailUuidRef.current = detailUuid;
  const [showPatientDetail, setShowPatientDetail] = useState(false);
  const [showMessenger, setShowMessenger] = useState(false);
  const showMessengerRef = useRef();
  showMessengerRef.current = showMessenger;
  const [enableChatNotify, setEnableChatNotify] = useState(false);


  const [isFocus, setIsFocus] = useState(true);
  const isFocusRef = useRef();
  isFocusRef.current = isFocus;

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderby, setOrderby] = useState('updateTime');
  const [selected, setSelected] = useState([]);
  const [searchWord, setSearchWord] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [showStatus, setShowStatus] = useState(true);
  const [showLocation, setShowLocation] = useState(false);
  const [socketId, setSocketId] = useState(window.Echo ? window.Echo.socketId() : '');
 
  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const [isDetailModified, setIsDetailModified] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [duplicateCases, setDuplicateCases] = useState([]);
  const [newRecordTags, setNewRecordTags] = useState(null);

  const intl = useIntl();

  const onFocus = () => {
    setIsFocus(true);
  }

  const onBlur = () => {
    setIsFocus(false);
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus, { capture: true });
    window.addEventListener('blur', onBlur, { capture: true });
    let dialog = document.getElementById("detailDialog");
    if (!!dialog && showMessenger) {
      dialog.scrollTop = dialog.scrollHeight;
    }
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    }
  });

  const onMessageButtonClick = ({ uuid }) => {

    setDetailUuid(uuid);
    const target = patientInfoArrayRef.current.find((value) => {
      return value.uuid === uuid;
    });
    if (target !== undefined) {
      setEnableChatNotify(target.hasSubscribed);
    }
    setShowMessenger(true);
  }

  function createData(item, location, tags, sigknowTags) {
    const icons = <Icons>
      <MessageButton hasNewMessage={item.hasNewMessage} onClick={e => {
        e.stopPropagation();
        onMessageButtonClick({ uuid: item.uuid, hasSubscribed: item.hasSubscribed, boardEditable: item.boardEditable });
      }} />
      <EmergentIcon emergentNoteOrigin={item.emergentNote} />
      <UrgentIcon isUrgent={item.isUrgent} />
    </Icons>;
    const sex = parseGenderToString(intl, item.sex);
    const updateTimeComponent = <TimeComponent timeString={item.updateTime} lang={lang}/>;
    const createTimeComponent = <TimeComponent timeString={item.createTime} lang={lang}/>;
    const signalComponent = <FileComp isDemo identifier="list" type={'signal-demo'} fileType={'.bin'} canUpload={item.canUploadSignal} canDownload={item.canDownloadSignal} uuid={item.uuid} deviceId={item.deviceId} patchId={item.patchId} inputFileName={item.signalName} inputFileSize={item.signalSize} onUploaded={onUploadDemo} onDownloaded={onDownloadDemo} prePathname={"/demo/file"}></FileComp>;
    const reportComponent = <FileComp isDemo identifier="list" type={'report-demo'} fileType={'.pdf,.doc,.docx'} canUpload={item.canUploadReport} canDownload={item.canDownloadReport} canOpenWebViewer={item.canOpenWebViewer} canOpenPdfViewer={item.canOpenPdfViewer} canEditComment={item.canEditComment} uuid={item.uuid} deviceId={item.deviceId} patchId={item.patchId} inputFileName={item.reportName} signalName={item.signalName} inputFileSize={item.reportSize} onUploaded={onUploadDemo} onDownloaded={onDownloadDemo} setDialogComponent={setDialogComponent} uploadHandler={onUploadDemo} downloadHandler={onDownloadDemo} prePathname={"/demo/file"}></FileComp>;

    // If item doesn't have location field, then current user is not Sigknow user and only one location exists.
    // One location applies to one 'tags' element(4 tags included).

    const sigknowTag = item.sigknowTag === null || item.sigknowTag === undefined ? null : <div style={{
      width: 'fit-content',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
    }}>{item.sigknowTag[0] !== null ? <Tag color={"red"} title={sigknowTags[0].TagName} /> : null}
      {item.sigknowTag[1] !== null ? <Tag color={"green"} title={sigknowTags[1].TagName} /> : null}
      {item.sigknowTag[2] !== null ? <Tag color={"orange"} title={sigknowTags[2].TagName} /> : null}
      {item.sigknowTag[3] !== null ? <Tag color={"pink"} title={sigknowTags[3].TagName} /> : null}</div>;

console.log(item);
    return {
      uuid: item.uuid,
      hasSubscribed: item.hasSubscribed,
      emergentNoteOrigin: item.emergentNoteOrigin,
      isUrgent: item.isUrgent,
      boardEditable: item.boardEditable,
      icons: icons,
      patchId: item.patchId,
      deviceId: item.deviceId,
      name: item.name,
      canEditName: item.canEditName,
      // tag: tag, 
      sex: sex,
      age: item.age === 0 ? intl.formatMessage({ id: 'patientDetail.info.unknown' }) : item.age, 
      birth: item.birth ? <TimeComponent tooltip={false} timeString={item.birth} lang={lang} type="date" /> : intl.formatMessage({ id: 'patientList.unknown' }),
      patientId: item.patientId,
      canEditPatientId: item.canEditPatientId,
      checkId: item.checkId,
      canEditCheckId: item.canEditCheckId,
      physician: item.physician,
      interpretedDoctor: item.interpretedDoctor,
      applyDate: <TimeComponent tooltip={false} timeString={item.applyDate} lang={lang} type="date" />,
      dayVersion: intl.formatMessage({ id: 'patientList.patientCareDurationDay' }, { day: item.dayVersion }),
      signalComponent: signalComponent,
      reportComponent: reportComponent,
      updateTimeComponent: updateTimeComponent,
      createTimeComponent: createTimeComponent,
      status: item.status,
      location: item.location === null || item.location === undefined ? null : item.location.Name,
      sigknowTag: sigknowTag
    };
  }

  const handleCloseDetail = (event, reason) => {
    console.log("reason: " + reason);
    if (isDetailModified && !isOnSubmit) {
      console.log("data is modified, please confirm");
      if (event && typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
      setOpenConfirmDialog(true);
      return;
    }
    setIsDetailModified(false);
    setShowPatientDetail(false);
    setShowMessenger(false);
    setIsOnSubmit(false);
  };


  function handleAddNewPatient() {
    setShowPatientDetail(true);
    setDetailUuid('');
  }

  const handleMessage = (message) => {
    if (message) {
      dispatch({
        type: ACTIONTYPE_PATIENTLIST_REMOVEALL, value: {
          listContent: [],
        }
      });
      if (message.initialData !== undefined) {
        if (message.initialData.analystList !== undefined) {
          dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: message.initialData.analystList } });
        }
        if (message.initialData.physicianList !== undefined) {
          dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: message.initialData.physicianList } });
        }
        if (message.initialData.locations !== undefined) {
          dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: message.initialData.locations } });
        }
        if (message.initialData.hospitalStaff !== undefined) {
          dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: message.initialData.hospitalStaff } });
        }
      }
      dispatch({
        type: ACTIONTYPE_PATIENTLIST_COUNT,
        value: message.count,
      });
      if (message.patientInfo.length > 0) {
        setShowStatus(message.patientInfo[0].status !== undefined);
        console.log(`showLocation is ${message.patientInfo[0].location !== undefined}`)
        setShowLocation(message.patientInfo[0].location !== undefined);
        // setShowSigknowTag(message.patientInfo[0].sigknowTag !== undefined);
        message.patientInfo.map((item, index) => {
          dispatch({
            type: ACTIONTYPE_PATIENTLIST_ADD, value: {
              listContentIndex: index,
              listContentItem: createData(item, message.location, message.tags, message.sigknowTags)
            }
          });
        });
      } else {
        console.log('patient info is empty');
      }
      if (message.sigknowTags !== undefined) {
        setNewRecordTags(message.sigknowTags);
      } else {
        setNewRecordTags(message.tags[0]);
      }
      if (Array.isArray(message.duplicateCases)) {
        console.log('set duplicateCases', message.duplicateCases)
        setDuplicateCases(message.duplicateCases);
      }
    } else {
      console.log("message is empty");
    }
  };

  function handleDeleteRequest(deletes) {
    // TODO: delete demo patient
    // Since customer accounts is unable to delete examination records,
    // delete function for demonstration is not neccessary.
  }

  function handleRefreshRequest() {
    console.log('demoPatient', demoPatient);
    handleMessage(queryContent({ order, orderby, offset, limit, searchWord, filterList }));
  }

  function handleOnClick(event, uuid) {
    setDetailUuid(uuid);
    setShowPatientDetail(true);
  }

  useEffect(useCallback(() => {
    if (detailUuid !== '') {
      const target = patientInfoArrayRef.current.find((value) => {
        return value.uuid === detailUuid;
      });
      if (target !== undefined) {
        console.log("set hasSubscribed: " + target.hasSubscribed + ", " + enableChatNotify);
        dispatch({
          type: ACTIONTYPE_PATIENTLIST_UPDATE, value: {
            ...target,
            hasSubscribed: enableChatNotify,
          }
        });
      }
    }
  }), [enableChatNotify]);

  useEffect(useCallback(() => {
    handleRefreshRequest();
  }), [demoPatient])

  useEffect(() => {
    if (Cookies.get('username') && Cookies.get('apiKey') && socketId && socketId.length !== 0) {
      handleMessage(queryContent({ order, orderby, offset, limit, searchWord, filterList }));
    }
  }, [order, orderby, offset, limit, searchWord, socketId]);


  useEffect(() => {
    console.log('username', Cookies.get('username'));
    console.log('apiKey', Cookies.get('apiKey'));

    if (!(Cookies.get('username') && Cookies.get('apiKey'))) {
      console.log('cookies are invalid, move to login');
      dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true, } });
    // } else if(!(isPowerUser(role) || isSeniorSales(role) || isJuniorSales(role))) {
    //   console.log('invalid user, move to patientlist');
    //   dispatch({ type: ACTIONTYPE_SETTING_TO_PATIENTLIST, value: { toPatientList: true, }});
    }

    if (locationList.length === 0){
      dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [{ Uuid: _uuid(), Name: "Demo"}] } });
    }

    console.log('username', Cookies.get('username'));
    console.log('apiKey', Cookies.get('apiKey'));

    if (!(Cookies.get('username') && Cookies.get('apiKey'))) {
      console.log('cookies are invalid, move to login');
      dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true, } });
    } else if (!(isPowerUser(role) || isSeniorSales(role) || isJuniorSales(role))) {
      console.log('invalid user, move to patientlist');
      dispatch({ type: ACTIONTYPE_SETTING_TO_PATIENTLIST, value: { toPatientList: true, } });
    }

    if (window.Echo === undefined || window.Echo === null) {
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: window.location.protocol + '//' + window.location.hostname,
        transports: ['websocket'], // Fix CORS error!
        enabledTransports: ['ws', 'wss'],
        client: io,
        authEndpoint: '/webanalyzer/v1/broadcasting/auth',
        // As I'm using JWT tokens, I need to manually set up the headers.
        auth: {
          headers: {
            //AccessControlAllowOrigin: 'https://' + window.location.hostname,
            Authorization: 'Bearer ' + Cookies.get('apiKey'),
            Accept: 'application/json',
          },
        },
      });

      window.Echo.connector.socket.on('connect', () => {
        setSocketId(window.Echo.socketId());
        console.log("socketid", window.Echo.socketId());
      });
    }

    window.Echo.private('UserNotificationChannel-' + Cookies.get('username'))
      .listen('UserNotification', (e) => {
        console.log("receive user login notification!");
        console.log(e);
        const msg = JSON.parse(e.data);
        if (msg.type === 'forcelogout' && Cookies.get('username') === msg.userId && msg.socketId !== window.Echo.socketId()) {
          dispatch({
            type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'login.alreadyLogin' }, { ip: msg.source }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } },
            onClose: dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: true } })
          });
          Cookies.remove('username', { path: '/', secure: true });
          Cookies.remove('apiKey', { path: '/', secure: true });
          Cookies.remove('uuid', { path: '/', secure: true });

          // clear patient list
          dispatch({
            type: ACTIONTYPE_PATIENTLIST_REMOVEALL, value: {
              listContent: [],
            }
          });
          dispatch({
            type: ACTIONTYPE_PATIENTLIST_COUNT,
            value: 0,
          });

          // clear init data
          dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: [] } });
          dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: [] } });
          dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: [] } });
          dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: [] } });
        } else if (msg.type === 'message') {
          console.log('lang: ' + lang);
          console.log(JSON.parse(msg.message));
          console.log(msg.anchor.horizontal);
          console.log(msg.anchor.vertical);
          if (isFocusRef.current) {
            console.log('focus, show message');
            dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: <div dangerouslySetInnerHTML={{ __html: JSON.parse(msg.message)[lang] }} />, timeout: msg.duration, color: msg.color, anchorOrigin: { horizontal: msg.anchor.horizontal, vertical: msg.anchor.vertical } } });
            console.log('messageId: ' + msg.messageId);
            axiosService.post(
              '/notification',
              {
                messageId: msg.messageId,
              },
              {
                headers: {
                  'Authorization': 'Bearer ' + Cookies.get('apiKey'),
                  'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
                }
              }).then(response => {
                console.log(response.data);
                successHandler(response);
              }).catch(error => {
                errorHandler(error);
              });
          } else {
            console.log('not focus, skip message');
          }
        }
      });

    return () => {
      console.log("patientList Demo exit!");
      console.log("UserChannel exit!");
      window.Echo.private('UserChannel-' + Cookies.get('username')).stopListening('UpdateData');

      console.log("UserNotificationChannel exit!");
      window.Echo.private('UserNotificationChannel-' + Cookies.get('username')).stopListening('UserNotification');
    };
  }, []);

  /*********
   * From Patient List end
   */

  function _uuid() {
    var d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  function searchObj(obj, query) {

    for (var key in obj) {
      var value = obj[key];
      if (typeof value === 'object') {
        if(searchObj(value, query))
          return true;
      }
      if (typeof value === "string" && value.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        // console.log('property=' + key + ' value=' + value);
        return true;
      }
    }
    return false;
  }

  const queryContent = ({ order, orderby, offset, limit, searchWord, filterList }) => {
    // Return a list of patient information
    console.log("query content from local");
    const queryDemoPatient = demoPatient.map(p => {
      return {
        age: p.patientInfo.age,
        birth: p.patientInfo.patientBirthday,
        boardEditable: false,
        canDownloadReport: true,
        canDownloadSignal: true,
        canOpenWebViewer: true,
        canOpenPdfViewer: true,
        canEditComment: true,
        canUploadReport: true,
        canUploadSignal: true,
        checkId: p.patientInfo.checkId,
        createTime: p.createTime,
        deviceId: p.patientInfo.device.DeviceId,
        emergentNote: p.emergentNote.isEmergency,
        hasNewMessage: true,
        hasSubscribed: false,
        location: p.patientInfo.location,
        name: p.patientInfo.name,
        patientId: p.patientInfo.patientId,
        patchId: p.patientInfo.patch,
        physician: p.patientInfo.physicianName,
        interpretedDoctor: p.patientInfo.interpretedDoctor,
        applyDate: p.patientInfo.setupDate,
        dayVersion: p.patientInfo.dayVersion,
        reportName: p.history.report.length > 0 ? p.history.report[p.history.report.length - 1].name : "",
        reportSize: p.history.report.length > 0 ? p.history.report[p.history.report.length - 1].size : 0,
        reportTime: p.history.report.length > 0 ? p.history.report[p.history.report.length - 1].time : "",
        sex: p.patientInfo.gender,
        // sigknowTag: [null, null, null, null],
        signalName: p.history.signal.length > 0 ? p.history.signal[p.history.signal.length - 1].name : "",
        signalSize: p.history.signal.length > 0 ? p.history.signal[p.history.signal.length - 1].size : 0,
        signalTime: p.history.signal.length > 0 ? p.history.signal[p.history.signal.length - 1].time : "",
        // status: 3,
        tags: [],//p.patientInfo.tags.map(t => t.TagId),
        updateTime: p.updateTime,
        uuid: p.patientInfo.uuid,
        isUrgent: p.patientInfo.isUrgent,
        urgentDescription: p.patientInfo.urgentDescription,
        expectedDate: p.patientInfo.expectedDate
      };
    }).filter(function (item) {
      var ret = searchObj(item, searchWord)
      filterList.forEach((value) => {
        console.log(value)
        if(value.field === 'age') {
          ret = ret && (item.age >= value.limit1 && item.age <= value.limit2);
        } else if (value.field === 'sex' && value.limit1.length > 0) {
          ret = ret && value.limit1.includes(item.sex);
        } else if (value.field === 'status' && value.limit1.length > 0) {
          ret = ret && value.limit1.includes(item.status);
        } else if (value.field === 'location') {

        } else if (value.field === 'icons') {
          if (value.limit1.emergency) {
            ret = ret && item.emergentNote;
          }
          if(value.limit1.urgent) {
            ret = ret && item.isUrgent;
          }
        }
      })
      return ret;
    }).sort(function compare(a, b) {
      var _a, _b;
      if (orderby === 'deviceId') {
        _a = a.deviceId;
        _b = b.deviceId;
      } else if (orderby === 'checkId') {
        _a = a.checkId;
        _b = b.checkId;
      } else if (orderby === 'name') {
        _a = a.name;
        _b = b.name;
      } else if (orderby === 'tag') {
        _a = a.tags;
        _b = b.tags;
      } else if (orderby === 'updateTime') {
        _a = a.updateTime;
        _b = b.updateTime;
      } else if (orderby === 'createTime') {
        _a = a.createTime;
        _b = b.createTime;
      } else if (orderby === 'physician') {
        _a = a.physician;
        _b = b.physician;
      } else if (orderby === 'sex') {
        _a = a.sex;
        _b = b.sex;
      } else if (orderby === 'age') {
        _a = a.age;
        _b = b.age;
      } else if (orderby === 'patientId') {
        _a = a.patientId;
        _b = b.patientId;
      }
      const _order = order === "asc" ? 1 : -1;

      if (typeof _a === "string") {
        var result = 0;
        if (_a < _b)
          result = -1;
        else if (_a > _b)
          result = 1;
        return _order * result;
      } else if (typeof _a === "number") {
        return _order * (_a - _b);
      }
    }).slice(offset, limit);

    const sortedSn = demoPatient.map((value) => {
      if(value.patientInfo.patch && value.patientInfo.patch.length > 0) {
        return value.patientInfo.patch;
      } else {
        return value.patientInfo.device.DeviceId;
      }
    }).sort();

    let results = [];
    for (let i = 0; i < sortedSn.length - 1; i++) {
      if (sortedSn[i + 1] == sortedSn[i]) {
        results.push(sortedSn[i]);
      }
    }

    return {
      offset: offset,
      limit: limit,
      order: order,
      orderby: orderby,
      count: queryDemoPatient.length,
      location: [{Name: "Demo"}],
      patientInfo: queryDemoPatient,
      tags: [[{ TagId: null, TagName: "Tag1", LoId: 38 }, { TagId: null, TagName: "Tag2", LoId: 38 }, { TagId: null, TagName: "Tag3", LoId: 38 }, { TagId: null, TagName: "Tag4", LoId: 38 }]],
      duplicateCases: results
    };
  };

  const detailGet = ({ uuid }) => {
    console.log("detailGet IN");

    const patient = demoPatient.find(p => p.patientInfo.uuid === uuid);
    if(!patient) {
      console.log("cannot find patient uuid: "+ uuid);
      return {};
    } else {
      return {
        initialData: [],
        patientInfoEditable: true,
        patientInfo: {
          uuid: patient.patientInfo.uuid,
          name: patient.patientInfo.name,
          canEditName: patient.patientInfo.canEditName,
          age: patient.patientInfo.age,
          gender: patient.patientInfo.gender,
          checkId: patient.patientInfo.checkId,
          canEditCheckId: patient.patientInfo.canEditCheckId,
          dayVersion: patient.patientInfo.dayVersion,
          device: {
            Uuid: patient.patientInfo.device.Uuid,
            DeviceId: patient.patientInfo.device.DeviceId,
            Model: patient.patientInfo.device.Model
          },
          paceMaker: patient.patientInfo.paceMaker,
          interpretedDoctor: patient.patientInfo.interpretedDoctor,
          patientBirthday: patient.patientInfo.patientBirthday,
          icd: patient.patientInfo.icd,
          electrocauterization: patient.patientInfo.electrocauterization,
          patientId: patient.patientInfo.patientId,
          patch: patient.patientInfo.patch,
          canEditPatientId: patient.patientInfo.canEditPatientId,
          physician: patient.patientInfo.physician,
          physicianName: patient.patientInfo.physicianName,
          abnormal: {
            key: patient.patientInfo.abnormal.key,
            value: patient.patientInfo.abnormal.value
          },
          location: {
            Uuid: patient.patientInfo.location.Uuid,
            Name: patient.patientInfo.location.Name
          },
          comment: patient.patientInfo.comment,
          indication: patient.patientInfo.indication,
          enableChatNotify: false,
          tags: patient.patientInfo.tags,
          setupDate: patient.patientInfo.setupDate,
          isUrgent: patient.patientInfo.isUrgent,
          urgentDescription: patient.patientInfo.urgentDescription,
          expectedDate: patient.patientInfo.expectedDate,
          estimatedDate: patient.patientInfo.estimatedDate,//moment().format('YYYY-MM-DD'),
        },
        reportInfoEditable: false,
        emergentNoteEditable: true,
        emergentNote: {
          isEmergency: patient.emergentNote.isEmergency,
          reason: patient.emergentNote.reason,
          figures: patient.emergentNote.figures
        },
        historyEditable: true,
        history: {
          canUploadSignal: true,
          canUploadReport: true,
          canDownloadSignal: true,
          canDownloadReport: true,
          canOpenWebViewer: true,
          canOpenPdfViewer: true,
          canEditComment: true,
          signal: patient.history.signal,
          report: patient.history.report,
        },
        boardEditable: false,
        board: { messages: [
          {
            id: 1,
            name: 'TestReceiver',
            time: (new Date()).toISOString(),
            type: 0,
            content: {message: 'Test message'}}
        ]}
      };
    }
  }

  const detailCreate = ({ patientInfo, reportInfo, emergentNote }) => {
    console.log("detailCreate IN");
    patientInfo.uuid = _uuid();
    console.log(patientInfo);
    console.log(reportInfo);
    console.log(emergentNote);
    const date = new Date();
    setDemoPatient(prevState => {
      return [...prevState, {
        patientInfo: patientInfo,
        reportInfo: reportInfo,
        emergentNote: emergentNote,
        history: { signal: [], report: [] },
        createTime: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds(),
        updateTime: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds(),
      }];
      });
  };

  const detailUpdate = ({ uuid, patientInfo, reportInfo, emergentNote }) => {
    console.log("detailUpdate IN");
    
    console.log(props);
    console.log(patientInfo);
    console.log(reportInfo);
    console.log(emergentNote);
    setDemoPatient(prevState => prevState.map(p => {
      if(p.patientInfo.uuid !== uuid) {
        return p;
      } else {
        const date = new Date();
        const _patientInfo = p.patientInfo;
        const _reportInfo = p.reportInfo;
        const _emergentNote = p.emergentNote;
        return {
          patientInfo: { ..._patientInfo, ...patientInfo},
          reportInfo: {..._reportInfo, ...reportInfo},
          emergentNote: {..._emergentNote, ...emergentNote},
          history: p.history,
          createTime: p.createTime,
          updateTime: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds(),

        }
      }
    }));
  }
  detailUpdate.propTypes = {
    uuid: PropTypes.string,
    patientInfo: PropTypes.shape({
      uuid: PropTypes.string
    }),
    reportInfo: PropTypes.object,
    emergentNote: PropTypes.object
  }

  const onUploadDemo = (reponse, props) => {
    console.log("run upload demo");
    const { uuid, type, filename, filesize } = props;

    setDemoPatient(prevState => prevState.map(p => {
      if (p.patientInfo.uuid !== uuid) {
        return p;
      } else {
        const date = new Date();
        return {
          patientInfo: p.patientInfo,
          reportInfo: p.reportInfo,
          emergentNote: p.emergentNote,
          history: type.toLowerCase().includes('report') ?
            {
              signal: p.history.signal,
              report: [...p.history.report, {
                name: filename,
                size: filesize,
                time: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds()
              }]
            } :
            {
              signal: [...p.history.signal, {
                name: filename,
                size: filesize,
                time: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds()
              }],
              report: p.history.report
            },
          createTime: p.createTime,
          updateTime: date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds(),
        }
      }
    }));
  };

  const onDownloadDemo = () => {
    return function (e) {
      e.stopPropagation();
      console.log("run download demo");
    };
  };

  return (
    <div>
      <div>{dialogComponent}</div>
      {showPatientDetail || showMessenger ?
        <Suspense fallback={<div></div>}>
          <PatientDetail uuid={detailUuid} isNewRecord={detailUuid === ''} onClose={handleCloseDetail}
            tags={newRecordTags}
            isOnSubmit={isOnSubmit}
            setIsOnSubmit={setIsOnSubmit}
            handleRefreshRequest={handleRefreshRequest}
            offset={offset}
            limit={limit}
            order={order}
            orderby={orderby}
            selected={selected}
            setIsDetailModified={setIsDetailModified}
            onSelect={detailGet}
            onCreate={detailCreate}
            onUpdate={detailUpdate}
            onUploaded={onUploadDemo}
            onDownloaded={onDownloadDemo}
            isDemo />
        </Suspense>
        : null}
      <Popover
        open={openConfirmDialog}
        anchorEl={this}
        onClose={() => {
          setOpenConfirmDialog(false);
        }}
        anchorOrigin={{
          vertical: 'middle',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ classes: { root: classes.paper } }}
      >
        <Grid container style={{ maxWidth: '400px' }}>
          <Grid xs={12} item style={{ margin: '20px', display: 'flex' }}>
            <Warning style={{ ...styles.icons, color: "#FFCC00" }} />
            <Typography variant="h6" style={{ paddingLeft: "10px", display: "flex", justifyContent: "center" }}>
              <FormattedMessage id={'patientDetail.confirmDiscardTitle'} />
            </Typography>
          </Grid>
          <Grid xs={12} item style={{ margin: '10px' }}>
            <Typography variant="body1" style={{ display: "flex", justifyContent: "center" }}>
              <FormattedMessage id={'patientDetail.confirmDiscard'} />
            </Typography>
          </Grid>
          <Grid xs={12} item style={{ direction: "rtl", marginRight: "10px" }}>
            <Button color="primary" onClick={() => {
              setOpenConfirmDialog(false);
              setShowPatientDetail(false);
              setIsDetailModified(false);
            }}>
              <FormattedMessage id={'patientList.confirm'} />
            </Button>
            <Button onClick={() => {
              setOpenConfirmDialog(false);
            }}>
              <FormattedMessage id={'patientDetail.cancel'} />
            </Button>
          </Grid>
        </Grid>
      </Popover>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                <FormattedMessage id="routes.demoPatientList" />
              </h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <EnhancedTable
                isSigknow={false}
                role={role}
                offset={offset}
                setOffset={setOffset}
                limit={limit}
                setLimit={setLimit}
                order={order}
                setOrder={setOrder}
                orderby={orderby}
                setOrderby={setOrderby}
                selected={selected}
                setSelected={setSelected}
                defaultRowsPerPage={limit}
                searchWord={searchWord}
                setSearchWord={setSearchWord}
                filterList={filterList}
                setFilterList={setFilterList}
                locationList={locationList.filter((item) => item.Name.toLowerCase() === 'demo')}
                count={count}
                handleDeleteRequest={handleDeleteRequest}
                handleRefreshRequest={handleRefreshRequest}
                handleNewRecord={handleAddNewPatient}
                tableHeaderColor="primary"
                handleOnClick={handleOnClick}
                tableHead={[
                  /**
                   * The id field is also the identity for sorting feature, if the id field is changed, backend also
                   * needs to be sync of parsing sorting identity.
                   * Refer to app\Http\Controllers\webanalyzer\v1\patientlist\contentController.php.
                   */
                  { id: 'icons', numeric: false, disablePadding: false, padding: "none", minWidth: "150px", label:  null, sortable: false, canFilter: true },
                  { id: 'patchId', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.patchId' }), sortable: true, canFilter: false },
                  { id: 'deviceId', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.deviceId' }), sortable: true, canFilter: false },
                  patientListCol.examNo ? { id: 'checkId', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.checkId' }), sortable: true, canFilter: false } : null,
                  patientListCol.name ? { id: 'name', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.name' }), sortable: true, canFilter: false } : null,
                  // { id: 'tag', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.tag' }), sortable: true, canFilter: false },
                  patientListCol.gender ? { id: 'sex', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label: intl.formatMessage({ id: 'patientList.sex' }), sortable: true, canFilter: false } : null,
                  patientListCol.age ? { id: 'age', numeric: true, disablePadding: false, padding: "none", minWidth: "100px", label: intl.formatMessage({ id: 'patientList.age' }), sortable: true, canFilter: false } : null,
                  patientListCol.birth ? { id: 'birth', numeric: true, disablePadding: false, padding: "none", minWidth: "100px", label: intl.formatMessage({ id: 'patientList.birth' }), sortable: true, canFilter: false } : null,
                  patientListCol.chartNo ? { id: 'patientId', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.patientId' }), sortable: true, canFilter: false } : null,
                  patientListCol.physician ? { id: 'physician', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.physician' }), sortable: true, canFilter: false } : null,
                  patientListCol.interpretedDoctor ? { id: 'interpretedDoctor', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.reportingPhysician' }), sortable: true, canFilter: false } : null,
                  patientListCol.applyDate ? { id: 'applyDate', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.applyDate' }), sortable: true, canFilter: false } : null,
                  patientListCol.dayVersion ? { id: 'dayVersion', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label: intl.formatMessage({ id: 'patientList.dayVersion' }), sortable: true, canFilter: false } : null,
                  { id: 'signal', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.signal' }), sortable: true, canFilter: false },
                  { id: 'report', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.report' }), sortable: true, canFilter: false },
                  showStatus && patientListCol.status ? { id: 'status', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label: intl.formatMessage({ id: 'patientList.status' }), sortable: true, canFilter: false } : null,
                  showLocation && patientListCol.location ? { id: 'location', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label: intl.formatMessage({ id: 'patientList.location' }), sortable: true, canFilter: false } : null,
                  patientListCol.create ? { id: 'createTime', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.create' }), sortable: true, canFilter: false } : null,
                  patientListCol.update ? { id: 'updateTime', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.update' }), sortable: true, canFilter: false } : null,
                  // showSigknowTag ? { id: 'sigknowTag', numeric: false, disablePadding: false, padding: "none", minWidth: "100px", label:  intl.formatMessage({ id: 'patientList.sigknowTag' }), sortable: true, canFilter: false, fontStyle: 'italic' } : null,
                ]}

                tableData={patientInfoArray}
                duplicateCases={duplicateCases}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

DemoPatientList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(DemoPatientList);
