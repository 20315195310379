import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useCallback } from "react";
import { TextField } from "@material-ui/core";
import { useStyles } from "views/PatientDetail/PatientDetailForm";
import moment from "moment";

const AirDatepickerReact = React.forwardRef((props, ref) => {

  const { textFieldOptions, value, onChange, datepickerConfig, format } = props;
  const { minDate, startDate, readOnly } = props;

  useEffect(() => {
    if (dp.current && startDate) {
      console.log('update start date',startDate)
      dp.current.update({ startDate: startDate });
    }
  }, [startDate]);

  useEffect(() => {
    console.log(minDate)
    if (dp.current && minDate && (!dp.current.minDate || dp.current.minDate.getTime() !== minDate.getTime())){
      console.log('set mindate', minDate)
      dp.current.update({ minDate: minDate });
    }
  }, [minDate]);

  let $input = useRef();
  let dp = useRef();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);

  const selectDate = useCallback((date, silent) => {
    console.log('select date', date);
    dp.current?.selectDate(date, { silent });
    dp.current?.setViewDate(date);
  }, []);

  useEffect(() => {
    const newDate = value
      ? moment(value, format).toDate()
      : "";
    console.log(newDate, value);

    selectDate(newDate, false);
    dp.current?.update({ startDate: newDate});

    console.log('view date', dp.current?.viewDate);
  }, [value, selectDate]);

  React.useImperativeHandle(ref, () => ({
    onScroll: () => {
      if (dp.current.visible) {
        dp.current.show();
      }
    }
  }));

  // Start init
  useEffect(() => {
    // Save instance for the further update
    dp.current = new AirDatepicker($input.current, { ...datepickerConfig,
      keyboardNav: false,
      onSelect({ date, formattedDate, datepicker }) {
        if(datepickerConfig.onSelect) {
          datepickerConfig.onSelect({formattedDate});
        }
        if (!Array.isArray(formattedDate)) {
          setInputValue(formattedDate);
        }
      },
      onBeforeSelect: ({date, datepicker}) => {
        if (datepickerConfig.onBeforeSelect) {
          return datepickerConfig.onBeforeSelect({ date, datepicker });
        } else {
          return true;
        }
      }
     } );

    if(startDate) {
      console.log('set start date', startDate)
      dp.current.update({startDate: startDate});
    }
    if(minDate) {
      console.log('set min date', minDate)
      dp.current.update({minDate: minDate});
    }
    return () => {
      dp.current.destroy();
      dp.current = undefined;
    };
  }, []);

  useEffect(() => {
    console.log('input value changed', inputValue)
    if (onChange) {
      onChange(inputValue);
    }
  }, [ inputValue]);

  return <TextField {...textFieldOptions} 
    className={classes.formControl}
    value={value}
    InputProps={{ readOnly: true}}
    inputProps={{ ...textFieldOptions.inputProps, ref: $input, disabled: readOnly }}
  />
});

export default AirDatepickerReact;

AirDatepickerReact.propTypes={
  ref: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  textFieldOptions: PropTypes.object, 
  value: PropTypes.string.isRequired, 
  onChange: PropTypes.func, 
  datepickerConfig: PropTypes.object, 
  format: PropTypes.string
};