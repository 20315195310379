import React, { useEffect, useState, useCallback, useRef, Suspense } from 'react';
import { useDispatch } from "redux-react-hook";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from '@material-ui/core/styles';
import MuiGrid from "@material-ui/core/Grid";
import { styled } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.jsx";
import { axiosService } from "axios/axiosInstance";
import Cookies from "js-cookie";
import { Tooltip, TextField } from '@material-ui/core';
import { ACTIONTYPE_MESSAGE_CONFIG } from "../../redux/actionTypes";

import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import useAxiosHandler from '../../axios/axiosHandler';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import UG01_model from 'assets/img/UG01_model.png';
import UG02_model from 'assets/img/UG02_model.png';
import UG01_deviceId from 'assets/img/UG01_deviceId.png';
import UG02_deviceId from 'assets/img/UG02_deviceId.png';
import UG02_serviceSN from 'assets/img/UG02_serviceSN.png';
import PatientDetailForm from './PatientDetailForm';
import ConfirmDetail from './ConfirmDetail';

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 4;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
    },
  },
};

export const Grid = styled(MuiGrid)({
  textAlign: 'left',
});

export const abnormalList = () => {
  const intl = useIntl();
  return [
    { key: 0, value: intl.formatMessage({ id: 'patientDetail.info.abnormal.none' }) },
    { key: 1, value: intl.formatMessage({ id: 'patientDetail.info.abnormal.allergy' }) },
    { key: 2, value: intl.formatMessage({ id: 'patientDetail.info.abnormal.sweat' }) },
    { key: 3, value: intl.formatMessage({ id: 'patientDetail.info.abnormal.other' }) }];
}

export const genders = () => {
  const intl = useIntl();
  return [
    intl.formatMessage({ id: 'patientDetail.info.female' }),
    intl.formatMessage({ id: 'patientDetail.info.male' }),
    intl.formatMessage({ id: 'patientList.unknown' }),
    intl.formatMessage({ id: 'patientList.others' }),
  ];
}

export const getAgeValue = (age) => {
  const intl = useIntl()
  if (age === undefined)
    return '';
  else
    return age === 0 ? intl.formatMessage({ id: 'patientDetail.info.unknown' }) : age;
}

export const parseGenderToString = (intl, gender) => {
  switch(gender) {
    case 0:
      return (intl.formatMessage({id: 'patientList.male'}));
    case 1:
      return (intl.formatMessage({id: 'patientList.female'} ));
    case 2:
      return (intl.formatMessage({id: 'patientList.unknown'} ));
    case 3:
      return (intl.formatMessage({id: 'patientList.others'} ));
    default:
      return "";
  }
}

export const parsePacemaker = (type) => {
  const intl = useIntl();
  const pacemakers = [
    intl.formatMessage({ id: 'patientDetail.info.none' }),
    'DDD',
    'DDDR',
    'AAI',
    'VVI',
    intl.formatMessage({ id: 'patientDetail.info.other' }),
  ];
  return pacemakers[type];
};

export const parseDayVersion = (dayVersion) => {
  const intl = useIntl()
  if (dayVersion === 0)
    return "";
  return dayVersion < 0 ? intl.formatMessage({ id: 'patientDetail.info.mins' }, { time: Math.abs(dayVersion) }) : (intl.formatMessage({ id: 'patientDetail.info.days' }, { time: dayVersion }))
};

export const parseIcd = (hasIcd) => {
  const intl = useIntl();
  const yesOrNo = [
    intl.formatMessage({ id: 'patientDetail.info.yes' }),
    intl.formatMessage({ id: 'patientDetail.info.no' })
  ];
  return hasIcd ? yesOrNo[0] : yesOrNo[1];
};

const useStyles = makeStyles(theme => ({
  centerAlign: {
    textAlign: "center"
  },
  swipeableBase: {
    height: '100%'
  },
  swipeableChild: {
    height: '100%',
    alignContent: 'center'
  }
}));

const styles = {
  image: {
    maxWidth: "300px",
    maxHeight: "420px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const maxSteps = 4;

function PatientDetailConfirm(props) {
  const { isNewRecord, submit } = props;
  const intl = useIntl();
  return (<Button color="primary"
    onClick={(e) => submit(e)}>
    {isNewRecord === true ? intl.formatMessage({ id: 'patientDetail.create' }) : intl.formatMessage({ id: 'patientDetail.apply' })}
  </Button>);
}
PatientDetailConfirm.propTypes = {
  isNewRecord: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
}

function PatientDetailCancel(props) {
  const { onClose } = props;
  return (<Button onClick={onClose}>
    <FormattedMessage id='patientDetail.cancel' />
  </Button>);
}
PatientDetailCancel.propTypes = {
  onClose: PropTypes.func.isRequired,
}

function ModelSelector(props) {

  const { setModel } = props;
  const classes = useStyles();

  return (<Grid container>
    <Grid item xs={12} className={classes.centerAlign} >
      <RadioGroup style={{ display: "block" }} row aria-label="position" name="position" defaultValue="ug01">
        <FormControlLabel
          value="ug01"
          control={<Radio />}
          labelPlacement="bottom"
          label={<img src={UG01_model} background={"gray"} style={styles.image} onClick={() => {
            setModel('UG01');
          }} />}
          onChange={() => setModel('UG01')}
        />
        <FormControlLabel
          value="ug02"
          control={<Radio />}
          labelPlacement="bottom"
          label={
            <img src={UG02_model} style={styles.image} onClick={() => {
              setModel('UG02');
            }} />}
          onChange={() => setModel('UG02')}
        />
      </RadioGroup>
    </Grid>
    <Grid item xs={12} className={classes.centerAlign} >
      <FormattedMessage id='patientDetail.selectModel' />
    </Grid>
  </Grid>);
}
ModelSelector.propTypes = {
  setModel: PropTypes.func.isRequired
}

function UG01InputView(props) {
  const { deviceId, setDeviceId } = props;

  const intl = useIntl();

  return (<Grid container>
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <img src={UG01_deviceId} style={styles.image} />
    </Grid>

    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Tooltip style={{ width: '100%', maxWidth: '80%' }}
        title={deviceId ? deviceId.length + '/11' : '0/11'} enterDelay={500}>
        <TextField
          label={intl.formatMessage({ id: 'patientDetail.info.deviceId' })}
          required
          value={deviceId}
          margin="normal"
          error={deviceId === ""}
          helperText={deviceId === "" ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}
          onInput={(e) => {
            if (e.target.value.length > 11) {
              e.target.value = e.target.value.slice(0, 11);
            }
            e.target.value = e.target.value.toUpperCase();
            setDeviceId(e.target.value);
          }}
        />
      </Tooltip>
    </Grid>
  </Grid>);
}
UG01InputView.propTypes = {
  deviceId: PropTypes.string,
  setDeviceId: PropTypes.func
}

function UG02InputView(props) {
  const { deviceId, setDeviceId, serviceId, setServiceId } = props;

  const intl = useIntl();

  return (<Grid container>
    <Grid container xs={6}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <img src={UG02_serviceSN} style={styles.image} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Tooltip style={{ width: '100%', maxWidth: '80%' }}
          title={serviceId ? serviceId.length + '/11' : '0/11'} enterDelay={500}>
          <TextField
            label={intl.formatMessage({ id: 'patientDetail.info.patch' })}
            required
            value={serviceId}
            margin="normal"
            error={serviceId === ""}
            helperText={deviceId === "" ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}
            onInput={(e) => {
              if (e.target.value.length > 11) {
                e.target.value = e.target.value.slice(0, 11);
              }
              e.target.value = e.target.value.toUpperCase();
              setServiceId(e.target.value);
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
    <Grid container xs={6}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <img src={UG02_deviceId} style={styles.image} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Tooltip style={{ width: '100%', maxWidth: '80%' }}
          title={deviceId ? deviceId.length + '/11' : '0/11'} enterDelay={500}>
          <TextField
            label={intl.formatMessage({ id: 'patientDetail.info.deviceId' })}
            required
            value={deviceId}
            margin="normal"
            error={deviceId === ""}
            helperText={deviceId === "" ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}
            onInput={(e) => {
              if (e.target.value.length > 11) {
                e.target.value = e.target.value.slice(0, 11);
              }
              e.target.value = e.target.value.toUpperCase();
              setDeviceId(e.target.value);
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  </Grid>);
}
UG02InputView.propTypes = {
  deviceId: PropTypes.string,
  setDeviceId: PropTypes.func,
  serviceId: PropTypes.string,
  setServiceId: PropTypes.func
}



function PatientDetail(props) {

  const { isNewRecord, uuid, onClose, handleRefreshRequest, isOnSubmit, setIsOnSubmit,
    offset, limit, order, orderby, selected, setIsDetailModified, tags,
    onSelect, onCreate, onUpdate, onUploaded, onDownloaded, pathname, isDemo } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [model, setModel] = useState('UG01');
  const [deviceId, setDeviceId] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [deviceDayVersion, setDeviceDayVersion] = useState(0);
  const [swipeableStyle, setSwipeableStyle] = useState({ maxHeight: "550px" });
  const [openReusedSnWarning, setOpenReusedSnWarning] = useState(false);
  const [highlightSerial, setHighlightSerial] = useState(false);
  const [successHandler, errorHandler] = useAxiosHandler();
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const birthDpRef = useRef(null);
  const setupDateDpRef = useRef(null);
  const expectedDateDpRef = useRef(null);

  const [reportInfo, setReportInfo] = useState({
    analyst: [],
    inspector: [],
    reviewer: [],
    uploader: [],
    reportReviewer: [],
    status: 0,
  });

  const [patientInfo, setPatientInfo] = useState({
    uuid: uuid,
    isNewPatient: true,
    patientId: '',
    canEditPatientId: true,
    name: '',
    canEditName: true,
    age: undefined,
    patientBirthday: null,
    gender: undefined,
    checkId: '',
    canEditCheckId: true,
    device: { Uuid: '', DeviceId: deviceId, Model: model },
    patch: serviceId,
    paceMaker: 0, // 0: None, 1: DDD, 2: DDDR, 3: AAI, 4: VVI, 5: Others
    icd: false,
    electrocauterization: false,
    physician: [],
    physicianName: '',
    interpretedDoctor: '',
    location: { Uuid: '', Name: '' },
    comment: '',
    abnormal: { key: 0, value: intl.formatMessage({ id: 'patientDetail.info.abnormal.none' }) },
    indication: '',
    enableChatNotify: true,
    address: '',
    phone: '',
    identifier: '',
    tags: isNewRecord ? tags : [],
    setupDate: null,
    dayVersion: 0,
    isUrgent: false,
    urgentDescription: '',
    expectedDate: null,
    estimatedDate: null,//moment().format('YYYY-MM-DD'),
  });

  const [emergentNote, setEmergentNote] = useState(
    {
      isEmergency: false,
      reason: '[]',
      figures: [],
    }
  );

  const [modifiedReportInfo, setModifiedReportInfo] = useState({});
  const [modifiedPatientInfo, setModifiedPatientInfo] = useState({});
  const [modifiedEmergentNote, setModifiedEmergentNote] = useState({});
  const [disableNextButton, setDisableNextButton] = useState(false);

  useEffect(() => {
    setDeviceId('');
    setServiceId('');
  }, [model]);

  useEffect(() => {
    // if (activeStep === 2) {
    //   setSwipeableStyle({});
    // } else {
    //   setSwipeableStyle({ maxHeight: "550px" });
    // }
  }, [activeStep]);

  const handleNext = (e) => {
    e.preventDefault();
    console.log("activeStep: " + activeStep);
    if (activeStep === maxSteps - 1) {
      setIsOnSubmit(true);
    } else {
      if (pathname && activeStep === maxSteps - 3) {
        setHighlightSerial(false);
        if (deviceId.length !== 11) {
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.fileComp.deviceIdFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          return;
        }
        if (model === 'UG02' && serviceId.length !== 11) {
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.fileComp.serviceIdFail' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          return;
        }
        setDisableNextButton(true);
        axiosService.get(
          '/match/device?deviceId=' + deviceId + (model === 'UG01' ? '' : '&serviceId=' + serviceId) + '&model=' + model,
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('apiKey'),
              'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
            }
          }
        ).then((response) => {
          setDisableNextButton(false);
          console.log(response);
          successHandler(response);
          if (response.status !== 200 || response.data.error !== 0) {
            console.log("device check response error: " + response.data.error);
            e.stopPropagation();

            // bypass customer and device doesn't match error
            console.log("index result: " + [9, 110, 111, 112, 113].indexOf(response.data.error));
            if ([9, 110, 111, 112, 113].indexOf(response.data.error) === -1) {
              return;
            }
            // For error 110 (reused s/n), popup a dialog
            if (110 === response.data.error) {
              setOpenReusedSnWarning(true);
            }
          } else {
            setDeviceDayVersion(response.data.message.days);
            setPatientInfo(prevState => {
              return {
                ...prevState,
                dayVersion: response.data.message.days,
              }
            });
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }).catch((error) => {
          setDisableNextButton(false);
          errorHandler(error);
        });

        return;
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onClose();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(useCallback((e) => {
    console.log('isOnSubmit changed: ' + isOnSubmit);
    if (isOnSubmit) {
      onSubmit(e);
    }
  }), [isOnSubmit]);

  const updatePhysicians = useCallback((physician) => {
    var localPhysicians = JSON.parse(localStorage.getItem("physicianList")) || [];
    if (localPhysicians.indexOf(physician) === -1) {
      localPhysicians.push(physician);
      localStorage.setItem("physicianList", JSON.stringify(localPhysicians));
    }
  });

  const onSubmit = (e) => {
    setIsOnSubmit(true);
    console.log("onsubmit ", isNewRecord ? "new patient" : "update patient");

    let resetIsOnSubmit = true;
    if (validateInputs()) {
      if (isNewRecord) {
        if (typeof onCreate === 'function') {
          resetIsOnSubmit = false;
          onCreate({
            patientInfo: patientInfo,
            reportInfo: reportInfo,
            emergentNote: emergentNote,
          });
          handleRefreshRequest({ event: null, offset: offset, limit: limit, orderby: orderby, order: order })
          onClose(e);
        }
        if (pathname) {
          resetIsOnSubmit = false;
          axiosService.post(pathname,
            {
              patientInfo: patientInfo,
              reportInfo: reportInfo,
              emergentNote: emergentNote,
            },
            {
              headers: {
                'Authorization': 'Bearer ' + Cookies.get('apiKey'),
                'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
              }
            }).then(response => {
              console.log(response.data);
              function onSuccess(response) {
                if (response.status === 200 && response.data.error === 0) {
                  handleRefreshRequest({ event: null, offset: offset, limit: limit, orderby: orderby, order: order });
                  updatePhysicians(patientInfo.physicianName);
                  onClose(e);
                } else {
                  setIsOnSubmit(false);
                }
              }
              successHandler(response, onSuccess);
            }).catch(error => {
              errorHandler(error);
              setIsOnSubmit(false);
            });
        } else {
          resetIsOnSubmit = true;
        }
      } else {
        resetIsOnSubmit = false;
        let data = new FormData();
        data.append('patientInfo', JSON.stringify(modifiedPatientInfo));
        data.append('reportInfo', JSON.stringify(modifiedReportInfo));
        data.append('emergentNote', JSON.stringify(modifiedEmergentNote));
        data.append('_method', 'PUT');
        if (typeof onUpdate === 'function') {
          onUpdate({
            uuid: uuid,
            patientInfo: modifiedPatientInfo,
            reportInfo: modifiedReportInfo,
            emergentNote: modifiedEmergentNote,
          });
          handleRefreshRequest({ event: null, offset: offset, limit: limit, orderby: orderby, order: order })
          onClose(e);
        }
        if (pathname) {
          axiosService.post(pathname + '/' + uuid,
            data,
            {
              headers: {
                'Authorization': 'Bearer ' + Cookies.get('apiKey'),
                'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
              }
            }).then(response => {
              console.log(response.data);
              function onSuccess(response) {
                if (response.status === 200 && response.data.error === 0) {
                  console.log('post to server patient detail success.');
                  console.log('order is ' + order);
                  handleRefreshRequest({ event: null, offset: offset, limit: limit, orderby: orderby, order: order });
                  updatePhysicians(patientInfo.physicianName);
                  onClose(e);
                } else {
                  setIsOnSubmit(false);
                }
              }
              successHandler(response, onSuccess);
            }).catch(error => {
              errorHandler(error);
              setIsOnSubmit(false);
            });
        } else {
          resetIsOnSubmit = true;
        }
      }
    }
    if (resetIsOnSubmit) {
      setIsOnSubmit(false);
    }
  }

  const validateInputs = () => {
    const breakLoop = false;
    do {
      if (patientInfo.age === undefined) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.ageEmpty' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        break;
      }

      if (patientInfo.gender === undefined) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.sexEmpty' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        break;
      }

      if (patientInfo.device.DeviceId === null || patientInfo.device.DeviceId.length === 0) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.deviceIdEmpty' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        break;
      }

      if (patientInfo.location.Name === null || patientInfo.location.Name.length === 0) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.locationEmpty' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        break;
      }

      if (isNaN(Date.parse(patientInfo.setupDate))) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.setupDayEmpty' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        break;
      }

      if (patientInfo.dayVersion === 0) {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.dayVersionEmpty' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
        break;
      }

      if (patientInfo.isUrgent) {
        if (patientInfo.urgentDescription.length === 0) {
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.urgentDescriptionEmpty' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        }
        if (isNaN(Date.parse(patientInfo.expectedDate))) {
          dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.expectedDateEmpty' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } } });
          break;
        }
      }
      return true;
    }
    while (breakLoop);
    return false;
  };

  return (
    <Dialog open fullWidth maxWidth={"md"} data-testid="detail-dialog" PaperProps={{ className: classes.swipeableBase, style: {overflow: "hidden"} }}
      onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogContent id={"detailDialog"} data-testid={"detailDialogContent"}
      onScroll={(e) => {
        birthDpRef.current.onScroll(e);
        setupDateDpRef.current.onScroll(e);
        expectedDateDpRef.current.onScroll(e);
      }}>
        <Suspense fallback={<div></div>}>
          {isNewRecord ?
            <SwipeableViews index={activeStep} style={{overflow: "hidden"}}  onChangeIndex={handleStepChange} className={classes.swipeableBase} containerStyle={{height: '100%'}} slideClassName={classes.swipeableChild}
              >
              <ModelSelector setModel={setModel} />
              {model === 'UG01' ?
                <UG01InputView deviceId={deviceId} setDeviceId={setDeviceId} /> :
                <UG02InputView deviceId={deviceId} setDeviceId={setDeviceId} serviceId={serviceId} setServiceId={setServiceId} />}
              <div style={{ height: "100%", overflow: "auto" }} onScroll={(e) => {
                birthDpRef.current.onScroll(e);
                setupDateDpRef.current.onScroll(e);
                expectedDateDpRef.current.onScroll(e);
              }}>
                <PatientDetailForm uuid={uuid} isNewRecord={isNewRecord} onClose={onClose}
                  model={model}
                  deviceId={deviceId}
                  serviceId={serviceId}
                  deviceDayVersion={deviceDayVersion}
                  setDeviceDayVersion={setDeviceDayVersion}
                  tags={tags}
                  handleRefreshRequest={handleRefreshRequest}
                  selected={selected}
                  setIsDetailModified={setIsDetailModified}
                  onSelect={onSelect}
                  onUploaded={onUploaded}
                  onDownloaded={onDownloaded}
                  pathname={pathname}
                  reportInfo={reportInfo}
                  setReportInfo={setReportInfo}
                  patientInfo={patientInfo}
                  setPatientInfo={setPatientInfo}
                  emergentNote={emergentNote}
                  setEmergentNote={setEmergentNote}
                  setModifiedReportInfo={setModifiedReportInfo}
                  setModifiedPatientInfo={setModifiedPatientInfo}
                  setModifiedEmergentNote={setModifiedEmergentNote}
                  birthDpRef={birthDpRef}
                  setupDateDpRef={setupDateDpRef}
                  expectedDateDpRef={expectedDateDpRef}
                  highlightSerial={highlightSerial} />
              </div>
              <ConfirmDetail patientInfo={patientInfo} highlightSerial={highlightSerial} />
            </SwipeableViews> :
            <PatientDetailForm uuid={uuid} isNewRecord={isNewRecord} onClose={onClose}
              model={model}
              deviceId={deviceId}
              serviceId={serviceId}
              deviceDayVersion={deviceDayVersion}
              setDeviceDayVersion={setDeviceDayVersion}
              tags={tags}
              handleRefreshRequest={handleRefreshRequest}
              selected={selected}
              setIsDetailModified={setIsDetailModified}
              onSelect={onSelect}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onUploaded={onUploaded}
              onDownloaded={onDownloaded}
              pathname={pathname}
              reportInfo={reportInfo}
              setReportInfo={setReportInfo}
              patientInfo={patientInfo}
              setPatientInfo={setPatientInfo}
              emergentNote={emergentNote}
              setEmergentNote={setEmergentNote}
              setModifiedReportInfo={setModifiedReportInfo}
              setModifiedPatientInfo={setModifiedPatientInfo}
              setModifiedEmergentNote={setModifiedEmergentNote}
              isDemo={isDemo}
              birthDpRef={birthDpRef}
              setupDateDpRef={setupDateDpRef}
              expectedDateDpRef={expectedDateDpRef} />}
        </Suspense>
        {openReusedSnWarning ? <Dialog open={openReusedSnWarning} maxWidth={"xl"} onClose={() => { }} aria-labelledby="form-dialog-title" style={{ height: "100%" }} >
          <DialogContent >
            <Typography variant="body1" style={{ paddingLeft: "10px", display: "flex", justifyContent: "center" }}>
              <FormattedMessage id={'error.reusedSerialNumber'}/>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => {
              setOpenReusedSnWarning(false);
              setHighlightSerial(true);
            }}>
              {intl.formatMessage({ id: 'patientList.confirm' })}
            </Button>
          </DialogActions>
        </Dialog> : null}
      </DialogContent>
      <DialogActions style={isNewRecord ? { display: "block" } : { display: "flex" }}>{isNewRecord ?
        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          style={{ background: "white" }}
          // className={classes.root}
          nextButton={
            <Button size="small" onClick={handleNext} color="primary" disabled={isOnSubmit || disableNextButton}>
              {activeStep === maxSteps - 1 ? <FormattedMessage id='patientDetail.create' /> : <FormattedMessage id='patientDetail.next' />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack}>
              {activeStep === 0 ? <FormattedMessage id='patientDetail.cancel' /> : <FormattedMessage id='patientDetail.previous' />}
            </Button>
          }
        /> :
        <div>
          <PatientDetailCancel onClose={onClose} />
          <PatientDetailConfirm isNewRecord={isNewRecord} submit={() => {
            setIsOnSubmit(true);
          }} />
        </div>}
      </DialogActions>
    </Dialog>);
}

PatientDetail.defaultProps = {
  isNewRecord: false,
  uuid: '',
  onClose: () => { console.log('default onClose'); },
  handleRefreshRequest: () => { console.log('default handleRefreshRequest'); },
  pathname: undefined,
  isDemo: false,
}

PatientDetail.propTypes = {
  isNewRecord: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  handleRefreshRequest: PropTypes.func.isRequired,
  isOnSubmit: PropTypes.bool.isRequired,
  setIsOnSubmit: PropTypes.func.isRequired,
  setIsDetailModified: PropTypes.func.isRequired,
  tags: PropTypes.array,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderby: PropTypes.oneOf(['emergentNote',
    'deviceId',
    'checkId',
    'name',
    'sex',
    'age',
    'patientId',
    'physician',
    'signal',
    'report',
    'updateTime',
    'createTime']).isRequired,
  selected: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onUploaded: PropTypes.func,
  onDownloaded: PropTypes.func,
  pathname: PropTypes.string,
  isDemo: PropTypes.bool,
};

export default withStyles(styles)(PatientDetail);