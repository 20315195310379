import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { Navigate } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { useSnackbar } from 'notistack';

import sha256 from "js-sha256";
import { useDispatch, useMappedState } from "redux-react-hook";
import { ACTIONTYPE_ANALYSTLIST, ACTIONTYPE_PHYSICIANLIST, ACTIONTYPE_LOCATIONLIST, ACTIONTYPE_HOSPITALSTAFFLIST, ACTIONTYPE_SETTING_ROLE, ACTIONTYPE_SETTING_TO_LOGIN, ACTIONTYPE_MESSAGE_CONFIG} from "../../redux/actionTypes";
import { useIntl, FormattedMessage} from "react-intl";
import { useAxiosHandler } from "../../axios/axiosHandler";
import { useNavigate } from 'react-router';
import { Typography, Button } from '@material-ui/core';
import { axiosService } from 'axios/axiosInstance';
import Cookies from 'js-cookie';
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ACTIONTYPE_SETTING_PREFERENCE_PATIENTLISTCOL } from "redux/actionTypes";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

function Login() {
  const intl = useIntl();
  const [toPatientList, setToPatientList] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const usernameRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const lang = useMappedState(useCallback(state => state.setting.language));
  const [successHandler, errorHandler] = useAxiosHandler();

  useEffect(() => {
    if(isLogin){
      setIsLogin(false);

    }
    return () => {};
  }, [isLogin]);

  const onSubmit = (e) => {
    console.log(usernameRef.current.children[0].value);
    console.log(passwordRef.current.children[0].value);
    Cookies.set('username', usernameRef.current.children[0].value, { path: '/', secure: true });
    e.preventDefault();

    const loginUrl = window.location.protocol + "//" + 'report.sigknow.com' + (window.location.port ? ':' + window.location.port : '') +"/webanalyzer/v1/login";

    console.log("password hash:" + sha256(loginUrl + passwordRef.current.children[0].value));

    setIsLogin(true);

    // axios.post(loginUrl,
    axiosService.post('/login',
        {
          account: usernameRef.current.children[0].value,
          password: sha256(loginUrl + passwordRef.current.children[0].value),
        },
        {
          headers: {
            'X-Socket-ID': window.Echo ? window.Echo.socketId() : ''
          }
        }
    ).then(response => {
      console.log(response);

      function onSuccess(response) {
        if(response.status === 200 && response.data.error !== undefined) {
          if (response.data.message.notify){
            if (response.data.message.notify.passwordReset) {
              if (!(Cookies.get('skip_pw_reset'))) {
                console.log('popup password reset reminder');
                dispatch({
                  type: ACTIONTYPE_MESSAGE_CONFIG, value: {
                    key: 'passwordResetRemindKey',
                    open: true,
                    message: <div style={{ display: 'block' }}>
                      <div>
                        <Typography>{intl.formatMessage({ id: 'login.passwordResetRemind' }, { days: response.data.message.notify.passwordReset})}</Typography>
                      </div>
                      <div style={{ display: "block", textAlign: "right" }}>
                        <Button style={{ marginLeft: "auto", marginRight: "auto", fontWeight: "bold" }} color="primary"
                          disableElevation variant="outlined" size="small"
                          onClick={() => {
                            var expiration = new Date();
                            expiration.setDate(expiration.getDate() + response.data.message.notify.passwordReset);
                            Cookies.set('skip_pw_reset', true, { path: '/', secure: true, expires: expiration });
                            closeSnackbar('passwordResetRemindKey');
                          }}>
                          <FormattedMessage id="login.donotshowagain" />
                        </Button>
                        <Button style={{ marginLeft: "auto", marginRight: "auto" }} color="primary"
                          disableElevation size="small" variant="contained"
                          onClick={() => {
                            closeSnackbar('passwordResetRemindKey');
                          }}>
                          <FormattedMessage id="login.igotit" />
                        </Button>
                      </div>
                    </div>,
                    timeout: 0,
                    color: 'info',
                    anchorOrigin: { horizontal: 'center', vertical: 'top' }
                  }
                });
              }
            }
            if (response.data.message.notify.outDateBin && response.data.message.notify.outDateBin.length > 0) {
              dispatch({
                type: ACTIONTYPE_MESSAGE_CONFIG, value: {
                  open: true,
                  message: <div style={{ display: 'block'}}><div>{intl.formatMessage({ id: 'login.outDateBin' })}</div>
                  <div>{intl.formatMessage({ id: 'login.outDateBinDevices'}, { 
                    devices: <span style={{wordBreak: 'break-word'}}>{response.data.message.notify.outDateBin.join(', ')}</span>,
                  })}</div></div>,
                  timeout: 0,
                  color: 'info',
                  anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
            }
            if (response.data.message.notify.unreadChat && response.data.message.notify.unreadChat.length > 0) {
              enqueueSnackbar(<div style={{ display: 'block' }}><div>{intl.formatMessage({ id: 'login.unreadChat' })}</div>
                <div style={{maxHeight: '200px', overflow: 'auto'}}>{intl.formatMessage({ id: 'login.unreadChatDevices' }, {
                  devices: <span style={{ wordBreak: 'break-word' }}>{response.data.message.notify.unreadChat.join(', ')}</span>,
                })}</div></div>, {
                key: undefined,
                variant: 'info',
                autoHideDuration: null,
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                style: { display: 'block', pointerEvents: 'all' },
                action: (key) => (
                  <Fragment>
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <Close />
                    </IconButton>
                  </Fragment>
                ),
                onClose: null,
              });
            }

            if(response.data.message.preference && response.data.message.preference.patientListCol) {
              dispatch({
                type: ACTIONTYPE_SETTING_PREFERENCE_PATIENTLISTCOL, value: response.data.message.preference.patientListCol
              });
            }

            if(response.data.message.notify.messageNotify.length > 0) {
              for(var i in response.data.message.notify.messageNotify){
                let msg = response.data.message.notify.messageNotify[i];
                enqueueSnackbar(<div dangerouslySetInnerHTML={{ __html: JSON.parse(msg.message)[lang] }} />, {
                  key: undefined,
                  variant: msg.color,
                  autoHideDuration: msg.duration === 0 ? null : msg.duration,
                  anchorOrigin: { horizontal: msg.anchor.horizontal, vertical: msg.anchor.vertical },
                  style: { display: 'block', pointerEvents: 'all' },
                  action: msg.duration === 0 ? (key) => (
                    <Fragment>
                      <IconButton onClick={() => { closeSnackbar(key) }}>
                        <Close />
                      </IconButton>
                    </Fragment>
                  ) : null,
                  onClose: null,
                });
              }
            }
          }

          dispatch({ type: ACTIONTYPE_SETTING_ROLE, value: { role: response.data.message.role } });

          if (response.data.message.initialData !== undefined) {
            if (response.data.message.initialData.analystList !== undefined) {
              console.log('analyst is not empty');
              dispatch({ type: ACTIONTYPE_ANALYSTLIST, value: { analystList: response.data.message.initialData.analystList } });
            }
            if (response.data.message.initialData.physicianList !== undefined) {
              console.log('physicianList is not empty');
              dispatch({ type: ACTIONTYPE_PHYSICIANLIST, value: { physicianList: response.data.message.initialData.physicianList } });
            }
            if (response.data.message.initialData.locations !== undefined) {
              console.log('locationList is not empty');
              dispatch({ type: ACTIONTYPE_LOCATIONLIST, value: { locationList: response.data.message.initialData.locations } });
            }
            if (response.data.message.initialData.hospitalStaff !== undefined) {
              console.log('hospitallStaffList is not empty');
              dispatch({ type: ACTIONTYPE_HOSPITALSTAFFLIST, value: { hospitalStaffList: response.data.message.initialData.hospitalStaff } });
            }
          }

          if (response.data.error === 7) {
            console.log('login username', Cookies.get('username'));
            console.log('login apiKey', Cookies.get('apiKey'));
            navigate('/webanalyzer/v1/demo', { replace: true });
          } else if(response.data.error === 5) {
            navigate('/webanalyzer/v1/user/resetpassword', { replace: true });
          } else if (response.data.error === 0) {
            navigate('/webanalyzer/v1/patientlist', { replace: true });
          }
        }
      }

      Cookies.set('username', usernameRef.current.children[0].value, { path: '/', secure: true });

      successHandler(response, onSuccess);
    }).catch(error => {
      function onError() {
        dispatch({ type: ACTIONTYPE_MESSAGE_CONFIG, value: { open: true, message: intl.formatMessage({ id: 'error.login' }), timeout: 3000, color: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top'} } });
      }
      console.log('request failed', error);
      errorHandler(error, onError);
    }).finally(() => {

    });
  }

  useEffect(()=>{
    dispatch({ type: ACTIONTYPE_SETTING_TO_LOGIN, value: { toLogin: false,}});
    if(Cookies.get("username") && Cookies.get("username").length !== 0
    && Cookies.get("apiKey") && Cookies.get("apiKey").length !== 0){
      console.log("Identity already exists, redirect to list view.");
      setToPatientList(true);
    }
  }
  , [])

  return (
    <div>
      <div>{toPatientList ? <Navigate from='*' to='/webanalyzer/v1/patientlist' /> : 
      <form onSubmit={(e) => onSubmit(e)}>
        <GridContainer>
          <GridItem style={{ margin: "0 auto" }}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                        inputProps={{
                          ref: usernameRef,
                          autocomplete: "username"}}
                      labelText={intl.formatMessage({ id: "login.username" })}
                      id="Username"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={intl.formatMessage({ id: "login.password"})}
                      id="Password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        autocomplete:"current-password",
                        type: "password",
                        ref: passwordRef
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button variant="contained" style={{ marginLeft: "auto", marginRight: "auto" }} color="primary" type="submit">
                  <FormattedMessage id="login.login"/>
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        </form>}</div></div>
  );
}

export default withStyles(styles)(Login);
