import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import { Grid } from "../PatientDetail/PatientDetail";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { MenuProps } from "views/PatientDetail/PatientDetail.jsx";
import {  Tooltip } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { useIntl, FormattedMessage } from "react-intl";
import moment from 'moment-timezone';
import { useMappedState } from "redux-react-hook";
import { parsePacemaker, parseIcd, abnormalList } from '../PatientDetail/PatientDetail';

import { useStyles } from './PatientDetailForm';

const _ = require('lodash');

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    marginTop: '16',
    marginBottom: '8',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    display: 'flex,'
  },
  gridItem: {
    textAlign: 'center'
  }
});

const fillRange = (start, end) => {
  return Array(end - start + 1).fill().map((item, index) => start + index);
};

function AdditionalInformation(props) {

  const intl = useIntl();
  const classes = useStyles();
  const { patientInfo, editable, onChange, setter } = props;

  const [openAbnormalSelect, setOpenAbnormalSelect] = useState(false);
  const [anchorElAbnormal, setAnchorElAbnormal] = useState(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickAway = () => {
    setOpenPhysicianSelect(false);
    setOpenAbnormalSelect(false);
  }

  return (
    <Grid container direction='column'>
      <Grid container direction='row'>
        <Grid xs={2} item className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox-pacemaker" shrink>
              <FormattedMessage id='patientDetail.info.pacemaker' />
            </InputLabel>
            <Select
              value={patientInfo.paceMaker}
              onChange={event => onChange('paceMaker', event.target.value, setter)}
              input={<Input id="select-multiple-checkbox-pacemaker" />}
              renderValue={selected => parsePacemaker(selected)}
              MenuProps={MenuProps}
              readOnly={!editable}
            >
              {fillRange(0, 5).map(type => (
                <MenuItem key={type} value={type}>
                  <ListItemText primary={parsePacemaker(type)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2} item className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox-icd" shrink>
              <FormattedMessage id='patientDetail.info.icd' />
            </InputLabel>
            <Select
              value={patientInfo.icd}
              onChange={event => onChange('icd', event.target.value, setter)}
              input={<Input id="select-multiple-checkbox-icd" />}
              renderValue={selected => parseIcd(selected)}
              MenuProps={MenuProps}
              readOnly={!editable}
            >
              {[true, false].map(icd => (
                <MenuItem key={icd} value={icd}>
                  <ListItemText primary={parseIcd(icd)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2} item className={classes.gridItem}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox-rfca" shrink>
              <FormattedMessage id='patientDetail.info.rfca' />
            </InputLabel>
            <Select
              value={patientInfo.electrocauterization}
              onChange={event => onChange('electrocauterization', event.target.value, setter)}
              input={<Input id="select-multiple-checkbox-rfca" />}
              renderValue={selected => parseIcd(selected)}
              MenuProps={MenuProps}
              readOnly={!editable}
            >
              {[true, false].map(electrocauterization => (
                <MenuItem key={electrocauterization} value={electrocauterization}>
                  <ListItemText primary={parseIcd(electrocauterization)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={6} item className={classes.gridItem}>
          <Tooltip disableTouchListener={patientInfo.abnormal.key !== 3} disableFocusListener={patientInfo.abnormal.key !== 3} disableHoverListener={patientInfo.abnormal.key !== 3} title={patientInfo.abnormal.value.length + '/100'} enterDelay={500}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="select-multiple-checkbox-abnormal">
                <FormattedMessage id='patientDetail.info.abnormal' />
              </InputLabel>
              <Input
                id="select-multiple-checkbox-abnormal"
                type={'text'}
                value={patientInfo.abnormal.key === 3 ? intl.formatMessage({ id: 'patientDetail.info.abnormal.other' }) + ": " + patientInfo.abnormal.value : abnormalList().find(v => v.key === patientInfo.abnormal.key).value}
                readOnly={!editable || patientInfo.abnormal.key !== 3}
                onChange={(e) => {
                  let commaIdx = e.target.value.indexOf(':');
                  if (commaIdx === -1) {
                    if (e.target.value.length > 100)
                      return;
                    onChange('abnormal', { key: 3, value: e.target.value }, setter);
                  }
                  else {
                    if (e.target.value.substr(commaIdx + 2).length > 100)
                      return;
                    onChange('abnormal', { key: 3, value: e.target.value.substr(commaIdx + 2) }, setter);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle abnormal visibility"
                      disabled={!editable}
                      onClick={(e) => {
                        setAnchorElAbnormal(e.currentTarget);
                        setOpenAbnormalSelect(!openAbnormalSelect);
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {openAbnormalSelect ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                    <Poppers
                      style={{ zIndex: 2000 }}
                      open={openAbnormalSelect}
                      anchorEl={anchorElAbnormal}
                      transition
                      disablePortal
                      className={
                        classNames({ [classes.popperClose]: !openAbnormalSelect })
                      }
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom"
                          }}
                        >
                          <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <MenuList role="menu" variant="selectedMenu">
                                {abnormalList().map(abnormal => (
                                  <MenuItem onClick={() => {
                                    setOpenAbnormalSelect(!openAbnormalSelect);
                                    // abnormalSelected.setIsAbnormalSelected(true);
                                    console.log("menuitem on click: " + abnormal.value);
                                    if (abnormal.key === 3)
                                      onChange('abnormal', { key: abnormal.key, value: '' }, setter);
                                    else
                                      onChange('abnormal', abnormal, setter);
                                  }}
                                    className={classes.dropdownItem}
                                    key={abnormal.key} value={abnormal.value}>
                                    <ListItemText primary={abnormal.value} />
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Poppers>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container direction='row'>
        <Grid xs={12} item className={classes.gridItem}>
          <Tooltip title={patientInfo.comment ? patientInfo.comment.length + '/1000' : '0/1000'} enterDelay={500}>
            <TextField
              id="patient-comment"
              className={classes.formControl}
              label={intl.formatMessage({ id: 'patientDetail.info.comment' })}
              value={patientInfo.comment}
              onInput={(e) => {
                if (e.target.value.length > 1000) {
                  e.target.value = e.target.value.slice(0, 1000);
                }
                onChange('comment', e.target.value, setter);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{readOnly: !editable}}
              fullWidth
              margin="normal"
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}

AdditionalInformation.propTypes = {
  deviceDayVersion: PropTypes.number,
  isNewRecord: PropTypes.bool,
  physicianList: PropTypes.array,
  locationList: PropTypes.array,
  hospitalStaffList: PropTypes.array,
  patientInfo: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setter: PropTypes.func.isRequired
};

export default withStyles(styles)(AdditionalInformation);