const en = {
  /* routes */
  'routes.login': 'Login',
  'routes.patientList': 'Examination List',
  'routes.demoPatientList': 'Demo Examination List',

  /* header */
  'header.system': 'EZY Connect',

  /* header links */
  'headerLinks.toggleDemo': 'Enter Demo',
  'headerLinks.leaveDemo': 'Exit Demo',
  'headerLinks.editSalesPrivilege': 'Privilege',
  'headerLinks.exportQualityDocument': 'Export',
  'headerLinks.addUser': 'Add user',
  'headerLinks.location': 'Location',
  'headerLinks.editAnnouncement': 'Announcement',
  'headerLinks.editProfile': 'Edit profile',
  'headerLinks.editPreference': 'Viewer Preference',
  'headerLinks.logout': 'Logout',
  'headerLinks.accept': 'Accept',
  'headerLinks.cookieConsent': 'This website uses cookies to enhance the user experience. To find out more about the cookies we use, see our {link}',
  'headerLinks.cookiePolicy': 'Cookie Policy',
  'headerLinks.privacyTitle': 'Notice of Personal Information Consent',
  'headerLinks.privacyContent.main': '<strong>Personal Data Collection Notification</strong>{br}\
  {br}\
  Dear User,{br}\
    This consent form explains how SIGKNOW Biomedical Co., Ltd. (hereinafter referred to as "the Company") will handle the personal identifiable information collected through the "EZYPRO ECG Data Analysis Service"(hereinafter referred to as "the Service").By selecting "I Agree", you confirm that you have read, understood, and agree to all the contents of this consent form and any subsequent amendments.{br}\
  {main.1}{br}\
  {br}\
  {main.2}{br}\
  {br}\
  {main.3}{br}\
  {br}\
  {main.4}{br}\
  {br}\
  {main.5}{br}\
  {br}\
  This consent form shall be interpreted and governed by the laws of the Republic of Singapore. Any disputes arising from or related to the consent form shall be submitted to the International Court of Arbitration of the International Chamber of Commerce (ICC) and shall be finally settled under the Rules of Arbitration of the ICC by one arbitrator appointed in accordance with the said Rules. The Seat of arbitration shall be Singapore. The language of arbitration shall be English.{br}\
  ',
  'headerLinks.privacyContent.1': 'Collection, Update, and Maintenance of PII{br}\
  {main.1.1}{br}\
  ',
  'headerLinks.privacyContent.1.1': '<ol>\
  <li>test 1.1</li>\
  </ol>',
  'headerLinks.privacyContent.2': '蒐集個人資料之目的{br}\
  <ol>\
  <li>本公司為提供「連續式心電圖監測檢查之數據分析服務」需蒐集您自然人客戶的個人資料。</li>\
  <li>當個人資料使用方式與當初本公司蒐集的目的不同時，我們會在使用前先徵求您的同意，您可以拒絕向本公司提供您的自然人客戶個人資料，但您可能因此喪失您的權益。</li>\
  <li>本公司利用您自然人客戶的個人資料期間為自本服務案件成立日起算7年內，並於屆期後銷毀所有具有個人資料之電子數據及紙本文件。</li>\
  <li>本公司主要利用個人資料地區以中華民國台灣地區為主。由於本公司伺服器架設於境外地區(美國以及新加坡)，而有將個人資料國際傳輸之情形。</li>\
  </ol>',
  'headerLinks.privacyContent.3': '基本資料之保密{br}\
  <ol>\
  <li>您自然人客戶的個人資料受到本公司【隱私權保護政策】之保護及規範。請閱讀【隱私權保護政策】以查閱本公司完整【隱私權保護政策】。本公司如違反「個人資料保護法」規定或因天災、事變或其他不可抗力所致者，致您的個人資料被竊取、洩漏、竄改、遭其他侵害者，本公司將於查明後以電話、電子郵件或網站公告等方法，擇適當方式通知您。</li>\
  <li>本公司因業務需要而委託其他單位處理您的個人資料時，本公司將會善盡監督之責。</li>\
  </ol>',
  'headerLinks.privacyContent.4': '同意書之效力{br}\
  <ol>\
  <li>當您勾選「我同意」時，即表示您已閱讀、瞭解並同意本同意書之所有內容。</li>\
  <li>本同意書如有未盡事宜，依個人資料保護法或其他相關法規之規定辦理；同時，您對您自然人客戶所有之個人資料，須負保密責任，若因洩露第三者，導致個人資料外洩、遺失，請自行負責。</li>\
  <li>您瞭解此一同意書符合個人資料保護法及相關法規之要求，且同意本公司留存此同意書紀錄，供日後取出查驗。</li>\
  </ol>',
  'headerLinks.privacyContent.5': '準據法與管轄法院{br}\
  本同意書之解釋與適用，以及本同意書有關之爭議，均應依照中華民國法律予以處理，並以臺灣臺北地方法院為管轄法院。{br}',
  'headerLinks.privacyContent': 'In accordance with Article 13 of the EU General Data Protection Regulation (GDPR, the Regulation (EU) 2016/679)(hereafter “GDPR”), Sigknow Biomedical Co., Ltd. (hereafter “SIGKNOW”, “we”, “us”) is obliged to explain the following issues to help understand your rights:{br}\
{br}\
1.	Data Processor{br}\
Sigknow Biomedical Co., Ltd.{br}\
{br}\
2.	Purpose of Personal Information Collection{br}\
The purpose of collecting, processing, and using your personal information is to analyze electrocardiography, customer relationship management, provide services, for the interests of investigation, statistics and research, data management, and other issues including, but not limited, contracting, legislation, government affairs.{br}\
{br}\
3.	Types of Personal Information Gathered{br}\
The personal data that we collect includes name, age, gender, medical history, health examination, and other data requested in this application form.The ultimate personal data that we collect depends on the interactions or services between SIGKNOW and our clients, the handling demands from our clients or our authorized third parties.{br}\
{br}\
4.	Handling and Use of Personal Information{br}\
<li>	Course of Data Handling</li>\
The course that your data being used includes but not limited to a specific purpose of events, the retention period based on legislation, necessary activities for business execution or specific events.{br}\
<li>	Location of Use</li>\
Places of your nationality, residency, or places where you might be involved.{br}\
<li>	Users of Personal Data</li>\
SIGKNOW, contracted parties under our authorization, governmental or legislative organizations, parties that are approved by clients.{br}\
<li>	Way of Data Handling and Use</li>\
By automatic machine or non - automatic method{br}\
{br}\
5.	Exercise of Personal Information Collection Rights{br}\
According to Article 15 - 18 of GDPR, you can exercise the following rights in regard to the personal data:{br}\
<li>	Request for searches or reviews of your personal information</li>\
<li>	Request for duplication of your personal information</li>\
<li>	Request for supplement or correction of your personal information</li>\
<li>	Request for deleting your personal information</li>\
{br}\
6.	Please refer to the following methods to understand how to exercise your rights prescribed in Article 15 - 18 of GDPR: {br}\
<li>	The company contact number: +886-2-27612577</li>\
<li>	The company contact email address: <mailto>contact@sigknow.com.tw"</mailto></li>\
{br}\
7.	You can choose whether to provide your personal information.However, if you decline to provide your personal data, SIGKNOW may not be able to verify or process information, and lead to the lack of better services or even inability to providing relevant services.Your understanding is well appreciated.{br}\
',
  'headerLinks.privacyCheck': 'I have carefully read and understood this Notice of Personal Information Consent and I hereby agree to provide my personal information.',
  'headerLinks.getDevicePlaceholder': 'Serial number',
  'headerLinks.unableToGetDeviceDays': 'Unable to get device information.',
  'headerLinks.deviceDays': '{deviceId} is {days} day(s) version.',
  'headerLinks.preUpdateCheck': 'Pre-update Check',
  'headerLinks.preUpdateCheck.content': 'Thank you for your support of our product. To ensure a smooth process for the next major update, please assist us by clicking the Pre-update Check button to perform the test. If the test fails, we will arrange for a specialist to contact you to resolve the issue. Thank you for your understanding and cooperation.',
  'headerLinks.preUpdateCheck.close': 'Close',

  /* login */
  'login.login': 'Login',
  'login.password': 'Password',
  'login.username': 'Account',
  'login.alreadyLogin': 'You have logged in from {ip}',
  'login.outDateBin': 'Following devices should be ready to upload signal：',
  'login.outDateBinDevices': '{devices}',
  'login.unreadChat': 'Following devices have unread messages:',
  'login.unreadChatDevices': '{devices}',
  'login.passwordResetRemind': 'Your password has not been changed for {days} days. We\'d like to suggest you to change password to remain account in secure.',
  'login.igotit': 'I got it!',
  'login.donotshowagain': 'Never show again.',

  /* reset password */
  'resetPassword.firstLogin': 'The user needs to reset the password for the first login.',
  'resetPassword.oldPassword': 'Old Password',
  'resetPassword.newPassword': 'New Password',
  'resetPassword.newPasswordConfirm': 'New Password Confirm',
  'resetPassword.continue': 'Continue',
  'resetPassword.passwordResetSuccessful': 'Password reset successful.',

  /* patient list */
  'patientList.patchId': 'Service S/N',
  'patientList.checkId': 'Exam No.',
  'patientList.name': 'Name',
  'patientList.tag': 'Tag',
  'patientList.sex': 'Gender',
  'patientList.male': 'Male',
  'patientList.female': 'Female',
  'patientList.others': 'Others',
  'patientList.unknown': 'Unknown',
  'patientList.birth': 'DOB',
  'patientList.age': 'Age',
  'patientList.patientId': 'Chart No.',
  'patientList.deviceId': 'Device ID',
  'patientList.physician': 'Physician',
  'patientList.reportingPhysician': 'Reporting Doctor',
  'patientList.applyDate': 'Apply Date',
  'patientList.dayVersion': 'Pt. Care Duration',
  'patientList.patientCareDurationDay': '{day}d',
  'patientList.signal': 'Signal',
  'patientList.report': 'Report',
  'patientList.update': 'Update',
  'patientList.create': 'Create',
  'patientList.status': 'Status',
  'patientList.location': 'Location',
  'patientList.sigknowTag': 'Sigknow Tag',
  'patientList.searchExamination': 'Search examination',
  'patientList.searchPlaceholder': 'Search',
  'patientList.filter': 'Filter',
  'patientList.applyFilter': 'Go',
  'patientList.addRecord' : 'Add record',
  'patientList.refresh': 'Refresh',
  'patientList.delete': 'Delete',
  'patientList.emergency': 'Emergency',
  'patientList.urgent': 'Urgent',
  'patientList.newMessage': 'New message',
  'patientList.message': 'Message',
  'patientList.rowsPerPage': 'Rows per page',
  'patientList.displayRows': '{from}-{to} of {count}',
  'patientList.notUploadYet': 'Not uploaded',
  'patientList.confirm': 'Confirm',
  'patientList.deleteFailed': 'Delete failed. Please try again later.',
  'patientList.pdfViewer': 'Report Viewer',
  'patientList.editPdf': 'Edit comments',
  'patientList.memoryNotEnough': 'Your system memory is less than the recommended volume of 8GB. For better user experience and upload stability, please use a computer system with at least 8GB of system memory.',
  'patientList.newPatientDivider': 'New patient data listed above',
  'patientList.duplicatedCases': 'Abnormal S/N:',

  /* file component */
  'fileComp.confirmPatchTitle': 'Service S/N is required',
  'fileComp.confirmPatch': 'Service S/N',

  /* patient detail */
  'patientDetail.detail': 'Detail',
  'patientDetail.conversation': 'Customer service',
  'patientDetail.newPatient': 'New Patient',
  'patientDetail.newMessageNotification': 'New message notification',
  'patientDetail.create': 'Create',
  'patientDetail.apply': 'Apply',
  'patientDetail.cancel': 'Cancel',
  'patientDetail.signal': 'Signal',
  'patientDetail.report': 'Report',
  'patientDetail.inputMessage': 'Type a message',
  'patientDetail.confirmDiscardTitle': 'You are leaving this page',
  'patientDetail.confirmDiscard': 'Data has been edited, are you sure to leave this page and discard change?',
  'patientDetail.next': 'Next',
  'patientDetail.previous': 'Back',
  'patientDetail.selectModel': 'Please select device model above',
  'patientDetail.updateData': 'This content has been modified, please reopen this page for latest information.',

  /* patient detail - patient info*/
  'patientDetail.info.emptyField': 'Empty field!',
  'patientDetail.info.patch': 'Service S/N',
  'patientDetail.info.patientId': 'Chart No.',
  'patientDetail.info.patientIdText': 'The S/N supports the recording for a maximum of {days} day(s).',
  'patientDetail.info.notFound': 'Not found',
  'patientDetail.info.name': 'Name',
  'patientDetail.info.sex': 'Gender',
  'patientDetail.info.male': 'Male',
  'patientDetail.info.female': 'Female',
  'patientDetail.info.unknown': 'Unknown',
  'patientDetail.info.physician': 'Physician',
  'patientDetail.info.interpretedDoctor': 'Reporting Doctor',
  'patientDetail.info.age': 'Age',
  'patientDetail.info.dateOfBirth': 'Date of Birth',
  'patientDetail.info.phone': 'Phone',
  'patientDetail.info.identifier': 'Identifier',
  'patientDetail.info.birthday': 'Birthday',
  'patientDetail.info.abnormal': 'Abnormal Situation',
  'patientDetail.info.abnormal.none': 'None',
  'patientDetail.info.abnormal.allergy': 'Allergy',
  'patientDetail.info.abnormal.sweat': 'Detached by sweat',
  'patientDetail.info.abnormal.other': 'Other',
  'patientDetail.info.comment': 'Comment',
  'patientDetail.info.address': 'Address',
  'patientDetail.info.setupDate': 'Apply Date',
  'patientDetail.info.dayVersion': 'Patient Care Duration',
  'patientDetail.info.pacemaker': 'Pacemaker',
  'patientDetail.info.icd': 'ICD',
  'patientDetail.info.rfca': 'RFCA',
  'patientDetail.info.yes': 'Yes',
  'patientDetail.info.no': 'No',
  'patientDetail.info.location': 'Location',
  'patientDetail.info.checkId': 'Examination No.',
  'patientDetail.info.deviceId': 'Device ID',
  'patientDetail.info.model': 'Patient Care Duration',
  'patientDetail.info.days': '{time} Day(s)',
  'patientDetail.info.mins': '{time} Minutes',
  'patientDetail.info.indication': 'Indication',
  'patientDetail.info.none': 'None',
  'patientDetail.info.other': 'Other',
  'patientDetail.info.status.created': 'Created',
  'patientDetail.info.status.signalUploading': 'S/Uploading',
  'patientDetail.info.status.signalFailed': 'S/Failed',
  'patientDetail.info.status.signalFailedRed': '<r>S/Failed</r>',
  'patientDetail.info.status.signalSuccessed': 'S/Success',
  'patientDetail.info.status.inspecting': 'Inspecting',
  'patientDetail.info.status.inspected': 'Inspected',
  'patientDetail.info.status.analyzing': 'Analyzing',
  'patientDetail.info.status.analyzed': 'Analyzed',
  'patientDetail.info.status.reviewing': 'Reviewing',
  'patientDetail.info.status.reviewed': 'Reviewed',
  'patientDetail.info.status.confirming': 'Confirming',
  'patientDetail.info.status.confirmed': 'Confirmed',
  'patientDetail.info.status.reportUploading': 'R/Uploading',
  'patientDetail.info.status.reportUploaded': 'R/Uploaded',
  'patientDetail.info.status.reportReviewing': 'R/Reviewing',
  'patientDetail.info.status.reportReviewed': 'R/Reviewed',
  'patientDetail.info.status.reportDownloaded': 'R/Downloaded',
  'patientDetail.info.status.processing': 'Processing',
  'patientDetail.info.status.released': 'Released',
  'patientDetail.info.status.archived': 'Archived',
  'patientDetail.info.isUrgent': 'Urgent',
  'patientDetail.info.urgentNote': 'Additional charges might apply for urgent reports.',
  'patientDetail.info.urgentDescription': 'Urgent Reason',
  'patientDetail.info.expectedDate': 'Expected Date',
  'patientDetail.info.expectedDateNote': 'Expected Date isn\'t a guarantee.Sigknow reserves the flexibility to adjust the report ready time.',
  'patientDetail.info.estimatedDate': 'Estimated Date',
  'patientDetail.edit.comment': 'Comments',
  'patientDetail.edit.commentNotification': 'Please make sure all filled up content is updated on the report after clicking accept button.',
  'patientDetail.edit.signatures': 'Signature',
  'patientDetail.edit.signatureTooltip': 'Append to comments',
  'patientDetail.edit.signAndSave': 'Sign & Save',

/* patient detail - confirm */
  'patientDetail.confirm.title': 'Please confirm the information',

/* patient detail - emergency*/
  'patientDetail.emergency.emergency': 'Emergency',
  'patientDetail.emergency.reason': 'Reason(s)',
  'patientDetail.emergency.addFigure': 'Add figure(s)',
  'patientDetail.emergency.removeFigure': 'Remove figure(s)',
  'patientDetail.emergency.noFigure': 'No figures',
  'patientDetail.emergency.figures': 'Figures:',
  'patientDetail.emergency.yes': 'Yes',
  'patientDetail.emergency.no': 'No',

  /* patient detail - report info*/
  'patientDetail.report.analyst': 'Analyst',
  'patientDetail.report.inspector': 'Inspector',
  'patientDetail.report.reviewer': 'Reviewer',
  'patientDetail.report.uploader': 'Report Uploader',
  'patientDetail.report.reportReviewer': 'Report Reviewer',
  'patientDetail.report.status': 'Status',

  /* [atoemt detail - sections */
  'patientDetail.hospitalPatient': '<strong>Hospital & Patient</strong>',
  'patientDetail.additionalInformation': '<strong>Additional Information</strong>', 
  'patientDetail.signalReport': '<strong>Signal & Report</strong>',
  'patientDetail.message': '<strong>Message</strong>',

  /* settings */
  'settings.export.from': 'From',
  'settings.export.to': 'To',
  'settings.export.export': 'Export',
  'settings.privilege.apply': 'Apply',
  'settings.privilege.location': 'Location',
  'settings.privilege.user': 'User',
  'settings.privilege.update' : 'Update privilege success',
  'settings.announcement.config': 'Config',
  'settings.announcement.status': 'Status',
  'settings.announcement.level': 'Level',
  'settings.announcement.duration': 'Duration',
  'settings.announcement.position': 'Position',
  'settings.announcement.notifyAfter': 'From',
  'settings.announcement.notifyBefore': 'To',
  'settings.preference': 'Viewer Preference',

  /* user profile */
  'userProfile.addProfile': 'Add profile',
  'userProfile.editProfile': 'Edit profile',
  'userProfile.addProfileSuccessfully': 'Add profile successfully.',
  'userProfile.editProfileSuccessfully': 'Edit profile successfully.',
  'userProfile.firstName': 'First name',
  'userProfile.lastName': 'Last name',
  'userProfile.userId': 'Account',
  'userProfile.oldPassword': 'Old password',
  'userProfile.newPassword': 'New password',
  'userProfile.confirmNewPassword': 'Confirm new password',
  'userProfile.age': 'Age',
  'userProfile.gender': 'Gender',
  'userProfile.birth': 'Birth',
  'userProfile.email': 'Email',
  'userProfile.identifier': 'Identifier',
  'userProfile.location': 'Location',
  'userProfile.phone': 'Phone',
  'userProfile.address': 'Address',
  'userProfile.add': 'Create profile',
  'userProfile.apply': 'Update profile',
  'userProfile.status': 'Status',
  'userProfile.status.disabled': 'Disabled',
  'userProfile.status.enabled': 'Enabled',
  'userProfile.status.deleted': 'Deleted',
  'userProfile.role': 'role',
  'userProfile.role.analyst': 'Analyst',
  'userProfile.role.hospitalStaff': 'Hospital Staff',
  'userProfile.role.sales': 'Sales',
  'userProfile.webview': 'Online ECG',

  /* location profile */
  'locationProfile.location': 'Location',
  'locationProfile.customerId': 'Customer Number',
  'locationProfile.name': 'Name',
  'locationProfile.division': 'Division',
  'locationProfile.divisionHelper': 'Multiple divisions can be seperated by semicolon(;).',
  'locationProfile.phone': 'Phone',
  'locationProfile.address': 'Address',
  'locationProfile.apply': 'Apply',
  'locationProfile.add': 'Add',
  'locationProfile.addProfile': 'Add location',
  'locationProfile.success': 'Update location successful',

  /* web viewer */
  'webViewer.sidebar': 'SIDEBAR',
  'webViewer.closeSidebar': 'Close Sidebar',
  'webViewer.openSidebar': 'Open Sidebar',
  'webViewer.collapse': 'COLLAPSE',
  'webViewer.expand': 'EXPAND',
  'webViewer.closeECGWindow': 'Close ECG Window',
  'webViewer.openECGWindow': 'Open ECG Window',
  'webViewer.firstPage': 'First Page',
  'webViewer.previousPage': 'Previous Page',
  'webViewer.currentPage': 'Current Page',
  'webViewer.nextPage': 'Next Page',
  'webViewer.lastPage': 'Last Page',
  'webViewer.zoomOut': 'Zoom Out',
  'webViewer.zoomIn': 'Zoom In',
  'webViewer.playReverse': 'Play (Reverse)',
  'webViewer.playForward': 'Play (Forward)',
  'webViewer.stop': 'Stop',
  'webViewer.resolutionMessage': 'WebViewer recommends the best resolution: 1920 x 937, current resolution: ',
  'webViewer.lastPageMessage': 'You have reached the very last page',
  'webViewer.firstPageMessage': 'You have reached the very first page',
  'webViewer.inProgress': 'In progress. Please Wait... ',
  'webviewer.loading': 'Loading report {reportName}...',
  'webviewer.noData': 'Report {reportName} doesn\'t exist.',
  'webviewer.error': 'Fail to open report {reportName}, please try again later.',
  'webviewer.passwordRequired': 'Please enter password',
  'webViewer.rawSignal': 'Raw Signal',
  'webViewer.signal': 'Filtered Signal',

  /* error message */
  'error.login': 'Account name or password is incorrect!',
  'error.resetPasswordFailed': 'Reset password failed!',
  'error.passwordDoesNotMatchTheFormat': 'The password does not match the format (requires 6 digits or more).',
  'error.theNewPasswordCannotBeTheSameAsTheOldPassword': 'The new password cannot be the same as the old password.',
  'error.patientIdEmpty': 'Chart No. cannot be empty.',
  'error.nameEmpty': 'Name cannot be empty.',
  'error.checkIdEmpty': 'Check ID cannot be empty.',
  'error.deviceIdEmpty': 'Device ID cannot be empty.',
  'error.locationEmpty': 'Location cannot be empty.',
  'error.setupDayEmpty': 'Setup Day cannot be empty.',
  'error.dayVersionEmpty': 'Day version cannot be empty.',
  'error.urgentDescriptionEmpty': 'Urgent description cannot be empty.',
  'error.expectedDateEmpty': 'Expected date cannot be empty.',
  'error.ageEmpty': 'Age cannot be empty.',
  'error.sexEmpty': 'Gender cannot be empty.',
  'error.serverError': 'Server return error({errorCode}), please try again later.',
  'error.receiveFailFromServer': 'Fail to receive data from server. Please try again later.',
  'error.receiveFailFromECGServer': 'Fail to receive ECG data from server. Server error message: ',
  'error.authenticationFailed': 'Authentication failed.',
  'error.demoAccount': 'You are using DEMO account.',
  'error.passwordResetAlready': 'Password had been reset before.',
  'error.apiKeyExpired': 'Login is expired, please login again.',
  'error.insufficientPrivilege': 'Insufficient privilege.',
  'error.readFileFail': 'Unable to read the uploading file, please try again later.',
  'error.fileInvalid': 'Uploading file is invalid.',
  'error.uploadFail': 'Upload failed, please try again later.',
  'error.downloadFail': 'Download failed, please try again later.',
  'error.fileNameMatchFail': 'The ECG file “{fileName}” does not match with the Service S/N or Device ID you created. Please ensure that the selected file is correct. Before uploading, please confirm that(1) Service S/N (2) the ready ECG file “{fileName}” are both correct.',
  'error.reportFileNameMatchFail': 'The report file name has been edited to “{fileName}”. Please ensure that the uploaded file is correct.{br}Before uploading, please verify that (1) Service S/N (2) the uploaded report file for “{fileName}” are both correct.',
  'error.commentEditWarning': 'The comment of the report has been edited.',
  'error.checkPatientDataBeforeBinUpload': 'User data cannot be edited once ECG data is uploaded. Please confirm all mandatory fields before uploading.',
  'error.reusedSerialNumber': 'Service S/N or Device ID has been used.',
  'error.name_invalid': 'File name is invalid. File name should be a composition of alphabetic, decimal digits or -_.!~*\'().',
  'error.userId': 'Account is required.',
  'error.firstName': 'First name is required.',
  'error.lastName': 'Last name is required.',
  'error.email': 'Email address is required.',
  'error.newPassword': 'Password is required.',
  'error.patientNotFound': 'Patient information is incorrect.',
  'error.emsInvalidResponse': 'Invalid external format.',
  'error.deviceNotFound': 'Device ID is invalid.',
  'error.patchNotFound': 'Service S/N is invalid',
  'error.deviceDuplicate': '<red>This device ID has been used.</red>',
  'error.patchDuplicate': '<red>This service S/N has been used.</red>',
  'error.wrongModel': 'S/N and model doesn\'t match. Please select other model from previous step.',
  'error.profile.userIdRequired': 'Account is required.',
  'error.profile.userIdUnique': 'The account name has been taken.',
  'error.profile.userIdRegex': 'Account name cannot contain special character.',
  'error.profile.firstNameRequired': 'First name is required.',
  'error.profile.firstNameAlpha': 'First name should be valid alphabet.',
  'error.profile.lastNameRequired': 'Last name is required.',
  'error.profile.lastNameAlpha': 'Last name should be valid alphabet.',
  'error.profile.emailRequired': 'E-mail address is required.',
  'error.profile.emailEmail': 'E-mail address has incorrect format.',
  'error.profile.newPasswordRequired': 'New password is required.',
  'error.profile.newPasswordConfirm': 'Please check password and confirmed password are identicle.',
  'error.profile.newPasswordConfirmRequired': 'Confirmed password is required.',
  'error.profile.birthDate': 'Birthday has incorrect format.',
  'error.profile.ageNumeric': 'Age is invalid.',
  'error.profile.genderNumeric': 'Gender is invalid.',
  'error.profile.newPasswordRequiredWith': 'Please enter new password if you are modifying password.',
  'error.profile.oldPasswordRequiredWith': 'Please enter old password if you are modifying password.',
  'error.profile.passwordIncorrect': 'Password incorrect.',
  'error.profile.locationRequired': 'Location is required.',
  'error.profile.locationNameNotString': 'Location name should be a string.',
  'error.profile.locationUuidNotString': 'Location uuid should be a string.',
  'error.profile.locationStatusNotInteger': 'Status should be an integer.',
  'error.profile.locationRoleNotInteger': 'Role should be an integer.',
  'error.profile.webviewNotBoolean': 'Webview should be a boolean.',
  'error.server.maintanence': 'Service temporarily unavailable, please try again later.',
  'error.location.customerId': 'Customer number is required.',
  'error.location.name': 'Location name is required.',
  'error.location.addFail': 'Add location failed, please try again later.',
  'error.location.updateFail': 'Update location failed, please try again later.',
  'error.fileComp.serviceIdFail': 'Service S/N is invalid, please make sure the input is correct. If the input value is invalid, please contact customer service.',
  'error.fileComp.deviceIdFail': 'Device ID is invalid, please make sure the input is correct.',
  'error.fileComp.binUploadDeny': 'File cannot be uploaded when the examination is under reviewing.',
  'error.commentEditSuccess': 'Report comment is updated.',
  'error.azure': 'Cloud internal failed.',
  'error.secure.ipWhiteList': 'IP address validation failed.',
};
export default en;