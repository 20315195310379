import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from "../PatientDetail/PatientDetail";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { MenuProps } from "views/PatientDetail/PatientDetail.jsx"
import Gallery from 'react-grid-gallery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NewReleases from "@material-ui/icons/NewReleases";
import Checkbox from '@material-ui/core/Checkbox';
import { useIntl, FormattedMessage } from "react-intl";
import { Delete } from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';
import { useToolbarStyles } from '../../components/Table/EnhancedTable.jsx';
import { useStyles } from './PatientDetailForm';

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 4;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  wildTextField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    display: 'flex,'
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

const fileToJson = (file) => {
  return {
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    name: file.name,
    size: file.size,
    type: file.type 
  };
}

function EmergentNotification(props) {
  const intl = useIntl();
  const toolbarClasses = useToolbarStyles();
  const { emergentNote, editable, onChange, setter, uuid } = props;
  const menuRef = useRef(null);
  const [menuRefO, setMenuRefO] = useState(menuRef);
  const classes = useStyles();

  const yesOrNo = [
    true,
    false,
  ];

  const reasons = [
    { key: 1, value: 'Pause ≧ 3 seconds' },
    { key: 5, value: 'HR <40/min' },
    { key: 2, value: 'Atrioventricular block(≧2°Ⅱ)' },
    { key: 3, value: 'Ventricular tachycardia (≧3 beats)' },
    { key: 9, value: 'Ventricular tachycardia (≧4 beats)' },
    //{ key: 10, value: 'Ventricular tachycardia (≧5 beats)'}, Deprecated, never record in database
    { key: 11, value: 'Ventricular tachycardia (≧6 beats)' },
    { key: 12, value: 'PVC ≧10%/day' },
    { key: 13, value: 'Ventricular fibrillation/Torsades de Pointes' },
    { key: 4, value: 'PSVT >30 seconds' },
    { key: 14, value: 'Sustained SVT> 30 seconds' },
    { key: 15, value: 'Paroxysmal/sustained atrial fibrillation/flutter' },
    { key: 16, value: 'Complete atrial fibrillation/flutter with RVR(Avg. HR >110 bpm)' },
    { key: 17, value: 'Overall Max HR ≧150bpm' },
    { key: 18, value: 'Pre-excitation/Wolff-Parkinson-White' },
    { key: 19, value: 'Marked ST elevation/depression' },
    { key: 6, value: 'VPC >10,000/day' },
    { key: 7, value: 'Wide QRS (≧100 bpm, ≧3 beats)' },
    { key: 8, value: 'Atrial Fibrillation' }];

  const convertYesOrNo = (boolean) => {
    return boolean ? 
      intl.formatMessage({ id: 'patientDetail.emergency.yes'}) : 
      intl.formatMessage({ id: 'patientDetail.emergency.no' });
  };

  function readFileAsDataURL(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.readAsDataURL(file);
    });
  }

  async function getImageFromFile(files) {
    return await Promise.all(Array.from(files).map(async (f) => {
      return {
        info: fileToJson(f),
        content: await readFileAsDataURL(f)
      };}));
  }

  function onUploadFigure(uuid, acceptedFiles) {
    getImageFromFile(acceptedFiles).then(
      content => {
        handleChange('figures', emergentNote.figures.concat(
          content.map(f => 
          {
            return {
              src: f.content,
              thumbnail: f.content,
              thumbnailWidth: 100,
              thumbnailHeight: 100,
              caption: f.info.name,
              /*srcSet: [`${f.content} 1024w`, `${f.content} 800w`, `${f.content} 500w`, `${f.content} 320w`],*/
              isSelected: false,
            };
          })
        ));
      }
    );
    
  }
  
  const handleChange = (key, value) => {
    onChange(key, value, setter);
  };

  return (
    <div xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container>
        <Grid xs={1} item className={classes.gridItem} style={{alignContent: "center"}}>{emergentNote.isEmergency ? <Tooltip title={intl.formatMessage({ id: 'patientList.emergency' })}><NewReleases style={{ color: "red" }}></NewReleases></Tooltip> : <NewReleases color="disabled"></NewReleases>}</Grid>
        <Grid xs={2} item className={classes.gridItem} style={{ textAlign: 'left'}}>
          <FormControl className={classes.formControl} /*className={styles.textField}*/>
            <InputLabel htmlFor="select-multiple-checkbox-emergency" shrink >
              <FormattedMessage id='patientDetail.emergency.emergency'/>
            </InputLabel>
            <Select
              value={emergentNote.isEmergency}
              onChange={event => handleChange('isEmergency', event.target.value)}
              input={<Input id="select-multiple-checkbox-emergency" />}
              renderValue={selected => convertYesOrNo(selected)}
              disabled={!editable}
            >
              {yesOrNo.map(item => (
                <MenuItem key={item} value={item}>
                  <ListItemText primary={convertYesOrNo(item)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={9} item className={classes.gridItem}>
          <FormControl className={classes.formControl} /*className={styles.textField}*/>
            <InputLabel htmlFor="select-multiple-checkbox-reason" shrink>
              <FormattedMessage id='patientDetail.emergency.reason' />
            </InputLabel>
            <Select
              multiple
              SelectDisplayProps={{
                  style: { whiteSpace: 'normal'}}}
	      value={JSON.parse(emergentNote.reason).map(reason => {
                let v = reasons.find(e => e.key === reason);
                return v ? v.value : "";
              })}
              onChange={(e, child) => {
                let reasonArray = JSON.parse(emergentNote.reason);
                const index = reasonArray.indexOf(child.props.value.key);
                if(index > -1){
                  reasonArray.splice(index, 1);
                } else {
                  reasonArray = reasonArray.concat([child.props.value.key]);
                }
                return handleChange('reason', JSON.stringify(reasonArray));
              }}
              input={<Input id="select-multiple-checkbox-reason" />}
              renderValue={selected => selected.join(', ')}
              disabled={!editable}
              ref={menuRefO}
              onOpen={(e) => {
                setMenuRefO(e.currentTarget);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: () => {
                  return menuRefO.current;
                },
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              }}
            >
              {reasons.map(reason => (
                <MenuItem key={reason.key} value={reason}>
                  <Checkbox checked={JSON.parse(emergentNote.reason).indexOf(reason.key) > -1} />
                  <ListItemText primary={reason.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

EmergentNotification.propTypes = {
  uuid: PropTypes.string,
  emergentNote: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setter: PropTypes.func.isRequired,
};


export default withStyles(styles)(EmergentNotification);
