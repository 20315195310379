import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import { Grid } from "./PatientDetail";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { MenuProps } from "views/PatientDetail/PatientDetail.jsx";
import { Tooltip } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown, Close } from '@material-ui/icons';
import { useIntl, FormattedMessage } from "react-intl";
import moment from 'moment-timezone';
import { FormControlLabel } from '@material-ui/core';
import Timer from '@material-ui/icons/Timer';
import { useMappedState } from "redux-react-hook";
import { getAgeValue, parseGenderToString, parseDayVersion, genders } from './PatientDetail';
import { useStyles } from './PatientDetailForm';
import { orange } from '@material-ui/core/colors';
import AirDatepickerReact from 'components/AirDatePickerReact/AirDatePickerReact';
import localeEn from 'air-datepicker/locale/en';
import localeZh from 'air-datepicker/locale/zh';

const _ = require('lodash');

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    marginTop: '16',
    marginBottom: '8',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    display: 'flex,'
  },
  gridItem: {
    textAlign: 'center'
  }
});

const fillRange = (start, end) => {
  return Array(end - start + 1).fill().map((item, index) => start + index);
};

function PatientInfo(props) {

  const intl = useIntl();
  const classes = useStyles();
  const { deviceDayVersion, patientInfo, editable, onChange, setter, physicianList, locationList, birthDpRef, setupDateDpRef, expectedDateDpRef } = props;

  const menuRef = useRef(null);
  const [menuRefO, setMenuRefO] = useState(menuRef);

  const [openAgeSelect, setOpenAgeSelect] = useState(false);
  const [anchorElAge, setAnchorElAge] = useState(null);
  const [openPhysicianSelect, setOpenPhysicianSelect] = useState(false);
  const [anchorElPhysician, setAnchorElPhysician] = useState(null);
  const [openAbnormalSelect, setOpenAbnormalSelect] = useState(false);
  const [localPhysicians, setLocalPhysicians] = useState(JSON.parse(localStorage.getItem("physicianList")) || []);
  const [minExpectedDate, setMinExpectedDate] = useState(null);
  const lang = useMappedState(useCallback(state => state.setting.language));

  useEffect(() => {
    if (patientInfo.isUrgent && editable && !minExpectedDate) {
      if (patientInfo.expectedDate && moment(moment().add(1, "hours").format("YYYY-MM-DD HH:00:00")).isAfter(moment.utc(patientInfo.expectedDate, "YYYY-MM-DD HH:mm:ss").local())) {
        setMinExpectedDate(moment.utc(patientInfo.expectedDate, "YYYY-MM-DD HH:mm:ss").local().toDate());
      } else {
        setMinExpectedDate(moment(moment().add(1, "hours").format("YYYY-MM-DD HH:00:00")).toDate());
      }
    }
    
  }, [patientInfo.isUrgent])

  const parseExpectedTimeOfDate = (expectedDate) => {
    if (isNaN(Date.parse(expectedDate))) {
      return null;
    } else {
      return moment(expectedDate).format("YYYY-MM-DD");
    }
  }

  const parseExpectedTimeOfHour = (expectedDate) => {
    if (isNaN(Date.parse(expectedDate))) {
      return null;
    } else {
      return moment(expectedDate).format("HH:00");
    }
  }

  const parseGender = (gender) => {
    console.log('parse gender '+gender)
    return gender === intl.formatMessage({ id: 'patientDetail.info.male' }) ? 0 :
      (gender === intl.formatMessage({ id: 'patientDetail.info.female' }) ? 1 : 
      (gender === intl.formatMessage({ id: 'patientList.others'}) ? 3 : 2));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickAway = () => {
    setOpenAgeSelect(false);
    setOpenPhysicianSelect(false);
    setOpenAbnormalSelect(false);
  }

  const getExpectedTimeOfHourListItems = () => {
    return fillRange(0, 23).map(hour => hour.toString().padStart(2, '0') + ':00')
      .map(hour => (
        <MenuItem onClick={() => {
          console.log("expected time on click: " + hour);
          const date = moment.utc(patientInfo.expectedDate).local().format('YYYY-MM-DD') + ' ' + hour;
          onChange('expectedDate', moment(date).utc().format('YYYY-MM-DD HH:00:00'), setter)
        }}
          disabled={minExpectedDate ? moment(minExpectedDate).isAfter(moment(moment.utc(patientInfo.expectedDate).local().format('YYYY-MM-DD') + ' ' + hour)) : false}
          className={classes.dropdownItem}
          key={hour} value={hour}>
          <ListItemText primary={hour} />
        </MenuItem>
      ));
  }

  const getAgeSelectListItems = () => {
    // var range = fillRange(1, 120);
    // range.push(0);
    let range = [0];
    return range.map(age => (
      <MenuItem onClick={() => {
        setOpenAgeSelect(!openAgeSelect);
        console.log("menuitem on click: " + age);
        onChange('age', age, setter);
      }}
        className={classes.dropdownItem}
        key={age} value={age}>
        <ListItemText primary={age === 0 ? intl.formatMessage({ id: 'patientDetail.info.unknown' }) : age} />
      </MenuItem>
    ));
  };

  return (
    <Grid container direction='column'>
      <Grid container direction='row'>
        <Grid xs={4} item className={classes.gridItem}>
          <Tooltip title={patientInfo.location.Name} enterDelay={500}>
            <FormControl className={classes.formControl} required
              error={patientInfo.location.Name === ""}>
              <InputLabel htmlFor="select-multiple-checkbox-location" shrink>
                <FormattedMessage id='patientDetail.info.location' />
              </InputLabel>
              <Select
                value={patientInfo.location.Name}
                onChange={event => onChange('location', locationList.find(function (l) {
                  return l.Uuid === event.target.value;
                }), setter)
                }
                input={<Input id="select-multiple-checkbox-location" />}
                renderValue={selected => selected}
                // MenuProps={MenuProps}
                readOnly={!editable}
                SelectDisplayProps={{ style: { "userSelect": "text" } }}
              >
                {locationList.sort((a, b) => {
                  return a.Name.toUpperCase().localeCompare(b.Name.toUpperCase(), "zh-hant")
                }).map(location => (
                  <MenuItem key={location.Uuid} value={location.Uuid}>
                    <ListItemText primary={location.Name} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{patientInfo.location.Name === "" ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}</FormHelperText>
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid xs={4} item className={classes.gridItem}>
          <Tooltip title={patientInfo.physicianName ? patientInfo.physicianName.length + '/40' : '0/40'} enterDelay={500}>
            <FormControl className={classes.formControl} >
              <InputLabel htmlFor="select-multiple-checkbox-physician" shrink>
                <FormattedMessage id='patientDetail.info.physician' />
              </InputLabel>
              <Input
                id="select-multiple-checkbox-physician"
                type={'text'}
                readOnly={!editable}
                multiline
                value={patientInfo.physicianName}
                onChange={(e) => {
                  if (e.target.value.length > 40) {
                    e.target.value = e.target.value.slice(0, 40);
                  }
                  onChange('physicianName', e.target.value, setter);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle physician visibility"
                      disabled={!editable || localPhysicians.length === 0}
                      onClick={(e) => {
                        setAnchorElPhysician(e.currentTarget);
                        setOpenPhysicianSelect(!openPhysicianSelect);
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {openPhysicianSelect ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                    <Poppers
                      style={{ zIndex: 2000 }}
                      open={openPhysicianSelect}
                      anchorEl={anchorElPhysician}
                      transition
                      disablePortal
                      className={
                        classNames({ [classes.popperClose]: !openPhysicianSelect })
                      }
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom"
                          }}
                        >
                          <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <MenuList role="menu" variant="selectedMenu">
                                {localPhysicians.map(physician => (
                                  <MenuItem onClick={() => {
                                    setOpenPhysicianSelect(!openPhysicianSelect);
                                    onChange('physicianName', physician, setter);
                                  }}
                                    className={classes.dropdownItem}
                                    key={physician} value={physician}>
                                    <ListItemText primary={physician} />
                                    <IconButton
                                      onClick={(e) => {
                                        const idx = localPhysicians.indexOf(physician);
                                        console.log('index of physician: ', physician, idx);
                                        if (idx !== -1) {
                                          let removed = localPhysicians.filter((value, index, arr) => {
                                            return index !== idx;
                                          });
                                          // removed = removed.filter(idx, 1);
                                          console.log("removed physicians: ", removed);
                                          localStorage.setItem("physicianList", JSON.stringify(removed));
                                          setLocalPhysicians(removed);
                                        }
                                        e.stopPropagation();
                                      }}><Close /></IconButton>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Poppers>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid xs={4} item className={classes.gridItem}>
          <Tooltip title={patientInfo.interpretedDoctor ? patientInfo.interpretedDoctor.length + '/40' : '0/40'} enterDelay={500}>
            <FormControl className={classes.formControl} >
              <InputLabel shrink htmlFor="select-multiple-checkbox-interpretedDoctor">
                <FormattedMessage id='patientDetail.info.interpretedDoctor' />
              </InputLabel>
              <Input
                id="select-multiple-checkbox-interpretedDoctor"
                type={'text'}
                readOnly={!editable}
                multiline
                value={patientInfo.interpretedDoctor}
                onChange={(e) => {
                  if (e.target.value.length > 40) {
                    e.target.value = e.target.value.slice(0, 40);
                  }
                  onChange('interpretedDoctor', e.target.value, setter);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle physician visibility"
                      disabled={!editable || localPhysicians.length === 0}
                      onClick={(e) => {
                        setAnchorElPhysician(e.currentTarget);
                        setOpenPhysicianSelect(!openPhysicianSelect);
                      }}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {openPhysicianSelect ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                    <Poppers
                      style={{ zIndex: 2000 }}
                      open={openPhysicianSelect}
                      anchorEl={anchorElPhysician}
                      transition
                      disablePortal
                      className={
                        classNames({ [classes.popperClose]: !openPhysicianSelect })
                      }
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom"
                          }}
                        >
                          <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <MenuList role="menu" variant="selectedMenu">
                                {localPhysicians.map(physician => (
                                  <MenuItem onClick={() => {
                                    setOpenPhysicianSelect(!openPhysicianSelect);
                                    onChange('interpretedDoctor', physician, setter);
                                  }}
                                    className={classes.dropdownItem}
                                    key={physician} value={physician}>
                                    <ListItemText primary={physician} />
                                    <IconButton
                                      onClick={(e) => {
                                        const idx = localPhysicians.indexOf(physician);
                                        console.log('index of physician: ', physician, idx);
                                        if (idx !== -1) {
                                          let removed = localPhysicians.filter((value, index, arr) => {
                                            return index !== idx;
                                          });
                                          // removed = removed.filter(idx, 1);
                                          console.log("removed physicians: ", removed);
                                          localStorage.setItem("physicianList", JSON.stringify(removed));
                                          setLocalPhysicians(removed);
                                        }
                                        e.stopPropagation();
                                      }}><Close /></IconButton>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Poppers>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container direction='row'>
        <Grid xs={4} item className={classes.gridItem}>
          <Tooltip
            title={patientInfo.checkId ? patientInfo.checkId.length + '/40' : '0/40'} enterDelay={500}>
            <TextField
              id="patient-checkId"
              className={classes.formControl}
              label={intl.formatMessage({ id: 'patientDetail.info.checkId' })}
              value={patientInfo.checkId}
              margin="normal"
              multiline
              inputProps={{ "readOnly": !!!patientInfo.canEditCheckId || !editable }}
              InputLabelProps={{
                shrink: true,
              }}
              onInput={(e) => {
                if (e.target.value.length > 40) {
                  e.target.value = e.target.value.slice(0, 40);
                }
                onChange('checkId', e.target.value, setter);
              }}
            />
          </Tooltip>
        </Grid>
        <Grid xs={4} id='setup-date-form-grid' item className={classes.gridItem}>
          <AirDatepickerReact ref={setupDateDpRef}
            value={!patientInfo.setupDate || isNaN(Date.parse(patientInfo.setupDate)) ? null : moment(patientInfo.setupDate).format("DD/MM/YYYY")}
            onChange={(updatedDate) => {
              if (updatedDate && moment(updatedDate, "DD/MM/YYYY").isValid()) {
                console.log('setupDate is valid', moment(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD')).format('YYYY-MM-DD HH:mm:ss'));
                onChange('setupDate', moment(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD')).format('YYYY-MM-DD HH:mm:ss'), setter);
              } else {
                onChange('setupDate', null, setter);
              }
            }}
            datepickerConfig={{
              autoClose: true,
              position: "bottom left",
              dateFormat: "dd/MM/yyyy",
              buttons: ["clear"],
              container: '#setup-date-form-grid',
              locale: lang === 'en-US' ? localeEn : localeZh,
            }}
            textFieldOptions={{
              required: true,
              error: isNaN(Date.parse(patientInfo.setupDate)),
              label: intl.formatMessage({ id: 'patientDetail.info.setupDate' }) ,
              placeholder:  "DD/MM/YYYY",
              InputLabelProps: { shrink: true},
              inputProps: { readOnly: true},
              helperText: isNaN(Date.parse(patientInfo.setupDate)) ? intl.formatMessage({id: 'patientDetail.info.emptyField' }) : ' '
            }}
            format={"DD/MM/YYYY"}
            readOnly={!editable}
          />
        </Grid>
        <Grid xs={4} item className={classes.gridItem}>
          <FormControl className={classes.formControl} required
            error={patientInfo.dayVersion === 0}>
            <InputLabel htmlFor="select-multiple-dropbox-day-version" shrink style={ patientInfo.dayVersion === 0 ? { color:  "red"} : {}}>
              <FormattedMessage id='patientDetail.info.dayVersion' />
            </InputLabel>
            <Select
              style={{ minWidth: 120 }}
              value={patientInfo.dayVersion}
              onChange={event => onChange('dayVersion', event.target.value, setter)}
              input={<Input id="select-multiple-dropbox-day-version" />}
              renderValue={selected => parseDayVersion(selected)}
              MenuProps={MenuProps}
              readOnly={!editable}
            >
              {[1, 2, 3, 7, 14].map(dayVersion => (
                <MenuItem key={dayVersion} value={dayVersion}>
                  <ListItemText primary={parseDayVersion(dayVersion)} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{patientInfo.dayVersion === 0 ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction='row'>
        <Grid xs={4} item className={classes.gridItem}>
          <Tooltip title={patientInfo.name ? patientInfo.name.length + '/40' : '0/40'} enterDelay={500}>
            <TextField
              id="patient-name"
              className={classes.formControl}
              label={intl.formatMessage({ id: 'patientDetail.info.name' })}
              value={patientInfo.name}
              multiline
              onInput={(e) => {
                if (e.target.value.length > 40) {
                  e.target.value = e.target.value.slice(0, 40);
                }
                onChange('name', e.target.value, setter);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              inputProps={{ "readOnly": !!!patientInfo.canEditName || !editable }}
            />
          </Tooltip>
        </Grid>
        <Grid xs={2} item className={classes.gridItem}>
          <FormControl className={classes.formControl} required
            error={parseGenderToString(intl, patientInfo.gender) === ''}>
            <InputLabel shrink htmlFor="select-multiple-checkbox-sex">
              <FormattedMessage id='patientDetail.info.sex' />
            </InputLabel>
            <Select
              value={parseGenderToString(intl, patientInfo.gender)}
              onChange={event => {
                onChange('gender', parseGender(event.target.value), setter);
                console.log('parse gender log '+parseGender(event.target.value));
              }}
              input={<Input id="select-multiple-checkbox-sex" />}
              renderValue={selected => selected}
              MenuProps={MenuProps}
              SelectDisplayProps={{
                style: {
                  textOverflow: "clip"
                }
              }}
              multiline
              readOnly={!editable}
            >
              {genders().map(gender => (
                <MenuItem key={parseGender(gender)} value={gender}>
                  <ListItemText primary={gender} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{parseGenderToString(intl, patientInfo.gender) === '' ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={2} item className={classes.gridItem}>
          <FormControl className={classes.formControl} required
            error={getAgeValue(patientInfo.age) === ''}>
            <InputLabel shrink htmlFor="select-multiple-checkbox-age">
              <FormattedMessage id='patientDetail.info.age' />
            </InputLabel>
            <Input
              id="select-multiple-checkbox-age"
              type={'text'}
              readOnly={!editable}
              value={getAgeValue(patientInfo.age)}
              onChange={(e) => {
                console.log("target in onChange: " + e.target.value);
                let validAge = e.target.value.replace(/[^0-9]/g, '');
                if (validAge === '') {
                  onChange('age', undefined, setter);
                } else {
                  if (parseInt(validAge, 10) < 1 || parseInt(validAge, 10) > 120)
                    return;
                  onChange('age', parseInt(validAge, 10), setter);
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle age visibility"
                    disabled={!editable}
                    onClick={(e) => {
                      setAnchorElAge(e.currentTarget);
                      setOpenAgeSelect(!openAgeSelect);
                    }}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {openAgeSelect ? <ArrowDropUp /> : <ArrowDropDown />}
                  </IconButton>
                  <Poppers
                    style={{ zIndex: 2000 }}
                    open={openAgeSelect}
                    anchorEl={anchorElAge}
                    transition
                    disablePortal
                    className={
                      classNames({ [classes.popperClose]: !openAgeSelect })
                    }
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{
                          transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom"
                        }}
                      >
                        <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <MenuList role="menu" variant="selectedMenu">
                              {getAgeSelectListItems()}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Poppers>
                </InputAdornment>
              }
            />
            <FormHelperText>{getAgeValue(patientInfo.age) === '' ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={2} id={"birth-form-grid"} item className={classes.gridItem}>
          <AirDatepickerReact ref={birthDpRef} 
            value={!patientInfo.patientBirthday || isNaN(Date.parse(patientInfo.patientBirthday)) ? null : moment(patientInfo.patientBirthday).format("DD/MM/YYYY")}
            onChange={(updatedDate) => {
              console.log(updatedDate)
              if (updatedDate && moment(updatedDate, "DD/MM/YYYY").isValid()) {
                console.log('patient birth is valid', moment(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD')).format('YYYY-MM-DD HH:mm:ss'));
                onChange('patientBirthday', moment(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD')).format('YYYY-MM-DD HH:mm:ss'), setter);
              } else {
                console.log('patient birth is invalid');
                onChange('patientBirthday', null, setter);
              }
            }}
            datepickerConfig={{
              autoClose: true,
              position: "bottom left",
              dateFormat: "dd/MM/yyyy",
              buttons: ["clear"],
              container: '#birth-form-grid',
              locale: lang === 'en-US' ? localeEn : localeZh,
              minDate: moment().subtract(120, "years").toDate()
              }
            }
            textFieldOptions={{
              label: intl.formatMessage({ id: 'patientDetail.info.dateOfBirth' }),
              placeholder: "DD/MM/YYYY",
              inputProps: {
                readOnly: true,
                onKeyDown: (event) => {
                  event.preventDefault();
                } },
              InputLabelProps: { shrink: true }
            }}
            format={"DD/MM/YYYY"}
            readOnly={!editable}
          />
        </Grid>
        <Grid xs={2} item className={classes.gridItem}>
          <Tooltip title={patientInfo.patientId ? patientInfo.patientId.length + '/40' : '0/40'} enterDelay={500}>
            <TextField
              id="patient-id"
              className={classes.formControl}
              label={intl.formatMessage({ id: 'patientDetail.info.patientId' })}
              value={patientInfo.patientId}
              multiline
              onInput={(e) => {
                if (e.target.value.length > 40) {
                  e.target.value = e.target.value.slice(0, 40);
                }
                onChange('patientId', e.target.value, setter);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ "readOnly": !!!patientInfo.canEditPatientId || !editable }}
              margin="normal"
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container direction='row'>
        <Grid item xs={patientInfo.estimatedDate ? 5 : 6}>
          <Grid container>
            <Grid item className={classes.gridItem} xs={3}>
              <Tooltip title={intl.formatMessage({ id: 'patientDetail.info.isUrgent' })} enterDelay={500}>
                <FormControlLabel label={patientInfo.isUrgent ? <Timer style={{ color: orange[500], verticalAlign: "middle" }} />
                  : <Timer color="disabled" style={{ verticalAlign: "middle" }} />}
                  className={classes.formControl}
                  style={{ marginLeft: '0px' }}
                  control={<Checkbox checked={patientInfo.isUrgent} onChange={(e) => {
                    console.log('isUrgent', e.target.checked)
                    onChange('isUrgent', e.target.checked, setter);
                  }} disabled={!editable}
                  />}
                />
              </Tooltip>
            </Grid>
            <Grid item className={classes.gridItem} xs={9}>
              <Tooltip title={patientInfo.urgentDescription ? patientInfo.urgentDescription.length + '/100' : '0/100'} enterDelay={500}>
                <TextField
                  className={classes.formControl}
                  multiline
                  required
                  id="patient-id"
                  label={intl.formatMessage({ id: 'patientDetail.info.urgentDescription' })}
                  value={patientInfo.urgentDescription}
                  onInput={(e) => {
                    if (e.target.value.length > 100) {
                      e.target.value = e.target.value.slice(0, 100);
                    }
                    onChange('urgentDescription', e.target.value, setter);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: !patientInfo.isUrgent || !editable
                  }}
                  error={patientInfo.isUrgent && patientInfo.urgentDescription.length === 0}
                  helperText={patientInfo.isUrgent && patientInfo.urgentDescription.length === 0 ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ''}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <FormHelperText>
            <FormattedMessage id={'patientDetail.info.urgentNote'} />
          </FormHelperText>
        </Grid>
        <Grid xs={patientInfo.estimatedDate ? 4 : 6} container>
          <Grid id='expected-date-form-grid' item xs={8} className={classes.gridItem}>
            <AirDatepickerReact ref={expectedDateDpRef}
              readOnly={!patientInfo.isUrgent || !editable}
              value={!patientInfo.expectedDate || isNaN(Date.parse(patientInfo.expectedDate)) ? null : moment.utc(patientInfo.expectedDate).local().format('DD/MM/YYYY')}
              onChange={(updatedDate) => {
                
                if (isNaN(Date.parse(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD HH:mm:ss')))) {
                  onChange('expectedDate', null, setter);
                } else {
                  if (parseExpectedTimeOfHour(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD HH:mm:ss')) && parseExpectedTimeOfDate(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD HH:mm:ss'))) {
                    
                    if (!patientInfo.expectedDate || isNaN(Date.parse(patientInfo.expectedDate))) {
                      if (moment().isAfter(moment(updatedDate, "DD/MM/YYYY"))) {
                        onChange('expectedDate', moment.utc().add(1, 'hours').format('YYYY-MM-DD HH:00:00'), setter);
                      } else {
                        onChange('expectedDate', moment(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:00:00'), setter);
                      }
                    } else {
                      if (minExpectedDate && moment(minExpectedDate).isAfter(moment(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD') + ' ' + moment.utc(patientInfo.expectedDate).local().format('HH:00')))) {
                        onChange('expectedDate', moment(minExpectedDate).utc().format('YYYY-MM-DD HH:00:00'), setter);
                      } else {
                        onChange('expectedDate', moment(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD') + ' ' + moment.utc(patientInfo.expectedDate).local().format('HH:00')).utc().format('YYYY-MM-DD HH:00:00'), setter);
                      }
                    }
                  } else {
                    console.error('parse failed')
                    console.error(parseExpectedTimeOfHour(moment(updatedDate, "DD/MM/YYYY").format('YYYY-MM-DD HH:mm:ss')), parseExpectedTimeOfDate(moment(updatedDate).format('YYYY-MM-DD HH:mm:ss')))
                  }
                }
              }}
              minDate={minExpectedDate}
              datepickerConfig={{
                autoClose: true,
                position: "bottom left",
                dateFormat: "dd/MM/yyyy",
                buttons: ["clear"],
                container: '#expected-date-form-grid',
                locale: lang === 'en-US' ? localeEn : localeZh,
              }}
              textFieldOptions={{
                required: true,
                error: patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)),
                label: intl.formatMessage({ id: 'patientDetail.info.expectedDate' }),
                placeholder: "DD/MM/YYYY",
                InputLabelProps: { shrink: true },
                inputProps: { readOnly: true, onChange: (e) => console.log(e) },
                helperText: patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ' '
              }}
              format={"DD/MM/YYYY"}
            />
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <FormControl className={classes.formControl}>
              <TextField
                id="patient-expected-hour"
                label={' '}
                select
                className={classes.textField}
                SelectProps={{
                  SelectDisplayProps: patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ?
                    { style: { "color": "red", "padding-top": "0px", "padding-bottom": "0px" } } :
                    { style: { "color": "inherit", "padding-top": "0px", "padding-bottom": "0px" } }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ readOnly: !patientInfo.isUrgent || !editable || !moment(patientInfo.expectedDate).isValid() }}
                error={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate))}
                value={isNaN(Date.parse(patientInfo.expectedDate)) ? '00:00' : moment.utc(patientInfo.expectedDate).local().format('HH:00')}
                renderValue={isNaN(Date.parse(patientInfo.expectedDate)) ? '00:00' : moment.utc(patientInfo.expectedDate).local().format('HH:00')}
                helperText={patientInfo.isUrgent && isNaN(Date.parse(patientInfo.expectedDate)) ? intl.formatMessage({ id: 'patientDetail.info.emptyField' }) : ''}
              >
                {getExpectedTimeOfHourListItems()}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid xs={3} className={classes.gridItem} style={{ display: patientInfo.estimatedDate ? 'block' : 'none' }} item>
          <FormControl className={classes.formControl}>
            <Tooltip title={isNaN(Date.parse(patientInfo.estimatedDate)) ? "" : moment.utc(patientInfo.estimatedDate).local().format('DD/MM/YYYY HH:mm:ss')}>
              <TextField
                id="patient-estimated-date"
                label={intl.formatMessage({ id: 'patientDetail.info.estimatedDate' })}
                type="text"
                value={isNaN(Date.parse(patientInfo.estimatedDate)) ? "" : moment.utc(patientInfo.estimatedDate).local().format('DD/MM/YYYY HH:mm:ss')}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                renderValue={isNaN(Date.parse(patientInfo.estimatedDate)) ? "" : moment.utc(patientInfo.estimatedDate).local().format('DD/MM/YYYY HH:mm:ss')}
                inputProps={{ "readOnly": true, style: { "color": patientInfo.estimatedDate === "" ? "red" : "black" } }}
              />
            </Tooltip>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}

PatientInfo.propTypes = {
  deviceDayVersion: PropTypes.number,
  physicianList: PropTypes.array,
  locationList: PropTypes.array,
  hospitalStaffList: PropTypes.array,
  patientInfo: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setter: PropTypes.func.isRequired,
  birthDpRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  setupDateDpRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  expectedDateDpRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default withStyles(styles)(PatientInfo);
